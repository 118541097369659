/* stylelint-disable */
@font-face {font-family: newicons;
  src: url('/sitevision/fonts/newicons.woff');}

section.box {float: left;
  width: 100%;}

.sv-mandant-info {float: left !important;
  height: 34px !important;
  margin-left: 12px !important;}

.sv-mandant-info span {font-weight: bold !important;
  line-height: 34px !important;}

.sv-widget-agenda,
.sv-widget-content,
.sv-widget-news,
.sv-widget-media,
.sv-widget-photogallery,
.sv-widget-rss,
.sv-widget-contentlist {float: left;
  margin: 0 0 1em;
  width: 100%;}
