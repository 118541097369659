/* stylelint-disable selector-max-id */
/* stylelint-disable selector-class-pattern */
  
@media screen and (min-width: 1280px) {
  #CybotCookiebotDialog.CybotEdge[data-template=bottom] {
    #CybotCookiebotDialogHeader {
      bottom: 0;
      position: absolute;
      right: 0;
    }

    #CybotCookiebotDialogPoweredbyCybot {
      display: none;
    }

    #CybotCookiebotDialogBodyLevelButtonCustomize {
      display: none;
    }

    .CybotCookiebotDialogBodyBottomWrapper {
      margin-top: 0;
    }
  }
}

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton {
  display: flex;
}
