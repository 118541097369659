/* stylelint-disable */
.sprite {background: url('/sitevision/images/csg-4f323dcb48480.png') no-repeat top left;
  display: inline-block;}

.spriteTree {background: url('/sitevision/images/csg-4f323ee1389de.png') no-repeat top left;
  display: inline-block;}

.spriteInpage {background: url('/sitevision/images/csg-4f59c864992a7.png') no-repeat top left;
  display: inline-block;}

.spriteToolbar {background: url('/sitevision/images/sprite_toolbar.png') no-repeat top left;
  display: inline-block;}

span.sprite,
span.spriteInpage,
span.spriteToolbar {cursor: pointer;
  float: left;}

.sv-sprite-I_A_Hb_inpageEdit_aktiv {background-position: 0 0;
  height: 21px;
  width: 23px;}

.sv-sprite-I_A_Hb_inpageEdit_inaktiv {background-position: 0 -71px;
  height: 13px;
  width: 16px;}

.sv-sprite-I_A_Hb_logout {background-position: 0 -134px;
  height: 16px;
  width: 16px;}

.sv-sprite-I_A_Hb_seitenstruktur_inaktiv {background-position: 0 -200px;
  height: 21px;
  width: 23px;}

.sv-sprite-I_A_Hb_seitenstruktur_aktiv {background-position: 0 -271px;
  height: 13px;
  width: 16px;}

.sv-sprite-I_D_4B_lupe {background-position: 0 -334px;
  height: 14px;
  width: 10px;}

.sv-sprite-I_D_4B_plus_NeuerInhalt {background-position: 0 -398px;
  height: 16px;
  width: 15px;}

.sv-sprite-I_D_AM_inhalt_aktiv {background-position: 0 -464px;
  height: 16px;
  width: 20px;}

.sv-sprite-I_D_AM_inhalt_inaktiv {background-position: 0 -530px;
  height: 16px;
  width: 20px;}

.sv-sprite-I_D_AM_seiten_aktiv {background-position: 0 -596px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_AM_seiten_inaktiv {background-position: 0 -662px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_G_hilfe_Fragezeichen {background-position: 0 -728px;
  height: 11px;
  width: 7px;}

.sv-sprite-I_D_G_quittierung_haeggli_weiss {background-position: 0 -789px;
  height: 12px;
  width: 12px;}

.sv-sprite-I_D_IbA_contract {background-position: 0 -851px;
  height: 19px;
  width: 19px;}

.sv-sprite-I_D_IbA_editinSlider_inaktiv {background-position: 0 -920px;
  height: 11px;
  width: 11px;}

.sv-sprite-I_D_IbA_editinSlider_mo {background-position: 0 -981px;
  height: 11px;
  width: 11px;}

.sv-sprite-I_D_IbA_expand {background-position: 0 -1042px;
  height: 19px;
  width: 19px;}

.sv-sprite-I_D_IbA_pfeilPageing_links {background-position: 0 -1111px;
  height: 11px;
  width: 13px;}

.sv-sprite-I_D_IbA_pfeilPageing_rechts {background-position: 0 -1172px;
  height: 11px;
  width: 13px;}

.sv-sprite-I_D_IbA_plus_inaktiv {background-position: 0 -1233px;
  height: 18px;
  width: 18px;}

.sv-sprite-I_D_IbA_plus_mo {background-position: 0 -1301px;
  height: 18px;
  width: 18px;}.sv-admin-menu .sv-sprite-I_D_4B_plus_NeuerInhalt {margin: 0 0 0 10px;}

.sv-sprite-I_D_A_aktivUnsichtbar {background-position: 0 0;
  height: 17px;
  width: 17px;}

.sv-sprite-I_D_A_geschuetzt {background-position: 0 -67px;
  height: 17px;
  width: 17px;}

.sv-sprite-I_D_A_loeschen {background-position: 0 -134px;
  height: 17px;
  width: 17px;}

.sv-sprite-I_D_A_publik {background-position: 0 -201px;
  height: 17px;
  width: 17px;}

.sv-sprite-I_D_A_publizieren {background-position: 0 -268px;
  height: 17px;
  width: 17px;}

.sv-sprite-I_D_A_seiteInaktiv {background-position: 0 -335px;
  height: 17px;
  width: 17px;}

.sv-sprite-I_D_IT_actions_inaktiv {background-position: 0 -402px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_IT_actions_mo {background-position: 0 -468px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_IT_dirtyflag {background-position: 0 -534px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_IT_editieren_inaktiv {background-position: 0 -600px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_IT_editieren_mo {background-position: 0 -666px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_IT_eigenschaften_inaktiv {background-position: 0 -732px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_IT_eigetnschaften_mo {background-position: 0 -798px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_IT_rechte_inaktiv {background-position: 0 -864px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_IT_rechte_mo {background-position: 0 -930px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_IT_verknuepfungen_inaktiv {background-position: 0 -996px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_IT_verknuepfungen_mo {background-position: 0 -1062px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_info_seiteAktiv {background-position: 0 -1128px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_info_seiteAktivUnsichtbar {background-position: 0 -1194px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_info_seiteGeschuetzt {background-position: 0 -1260px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_info_seiteInaktiv {background-position: 0 -1326px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_info_seitePulik {background-position: 0 -1392px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_loeschen_inaktiv {background-position: 0 -1458px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_loeschen_mo {background-position: 0 -1524px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_nurzeitNichtAktiv_imo {background-position: 0 -1590px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_pfeil_tree_offen {background-position: 0 -1656px;
  height: 10px;
  width: 10px;}

.sv-sprite-I_ST_pfeil_tree_zu {background-position: 0 -1716px;
  height: 10px;
  width: 10px;}

.sv-sprite-I_ST_verschieben_inaktiv {background-position: 0 -1776px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_verschieben_mo {background-position: 0 -1842px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_vorschau_inaktiv {background-position: 0 -1908px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_vorschau_mo {background-position: -67px 0;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_zurzeitAktiv_inaktiv {background-position: -67px -66px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_zurzeitAktiv_mo {background-position: -67px -132px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_zurzeitNichtAktiv_inaktiv {background-position: -67px -198px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_IT_externegruppen {background: url(/sitevision/images/icons/Icons_Dashboard_Inhalte_Tree/I_D_IT_externegruppen.png) no-repeat;
  height: 11px;
  width: 23px;}span.sv-sprite-I_A_Hb_inpageEdit_aktiv {margin: 0 5px 0 0;}span.sv-sprite-I_D_AM_seiten_aktiv {margin: 6px 5px 0 0;}span.sv-sprite-I_D_AM_inhalt_aktiv {margin: 9px 5px 0 0;}span.sv-sprite-I_D_AM_seiten_inaktiv {margin: 6px 5px 0 0;}span.sv-sprite-I_D_AM_inhalt_inaktiv {margin: 9px 5px 0 0;}

span.sv-sprite-I_D_IbA_expand {float: right;
  margin: 0 10px 0 0;}

span.sv-sprite-I_D_IbA_contract {float: right;
  margin: 0 10px 0 0;}

span.sv-sprite-I_D_IbA_plus_mo {float: right;
  margin: 0 10px 0 0;}#svDataTable_Elementcontainer a:hover {background-color: transparent;}

.sv-sprite-I_D_IT_actions_inaktiv:hover {background-position: 0 -468px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_IT_editieren_inaktiv:hover {background-position: 0 -666px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_IT_eigenschaften_inaktiv:hover {background-position: 0 -798px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_IT_rechte_inaktiv:hover {background-position: 0 -930px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_IT_verknuepfungen_inaktiv:hover {background-position: 0 -1062px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_loeschen_inaktiv:hover {background-position: 0 -1524px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_verschieben_inaktiv:hover {background-position: 0 -1842px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_vorschau_inaktiv:hover {background-position: -67px 0;
  height: 16px;
  width: 17px;}

.sv-sprite-I_ST_zurzeitAktiv_inaktiv:hover {background-position: -67px -132px;
  height: 16px;
  width: 17px;}

.sv-sprite-I_D_4B_plus_AnderungenVerwerfen {background: url('/sitevision/images/icons/Icons_Dashboard_4Buttons/I_D_4B_anederungverwerfen_grau.png');
  cursor: pointer;
  display: block;
  float: left;
  height: 16px;
  margin: 0 0 0 10px;
  width: 18px;}
