@import './app/app.css';
@import './app/style.css';
@import './app/base.css';
@import './app/chosen.css';
@import './app/colorbox.css';
@import './app/deprecated/shared.css';
@import './app/deprecated/sprite.css';
@import './app/copiedfromcore/lib/jqueryui/css/jquery-ui.css';
@import './app/copiedfromcore/lib/zoomy/zoomy.css';
@import './app/copiedfromcore/lib/tooltip/jquery.cluetip.css';
@import './app/copiedfromcore/lib/colorbox/theme1/colorbox.css';
@import './platform/widgets/view/pdfviewer/scss/base';

@import './components/cookiebot';
