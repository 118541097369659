$tiefenbach-primary-color: #ffce1f;
$tiefenbach-secondary-color: #e6e6e6;
$breakpoint-lg: 992px;

/* stylelint-disable selector-class-pattern */
.sv-widget-pdfviewer-contentBox-pdfviewer {
  .canvas-container {
    text-align: center;
  }

  .sv-widget-pdfviewer-canvas {
    max-width: 100%;
  }

  .controls {
    display: flex;
    justify-content: center;
  }

  button {
    border: 0;
    color: #000;
    margin: 0 10px;
  }

  .pdf-previous,
  .pdf-next {
    background: $tiefenbach-primary-color;
    border-radius: 6px;
    font-family: inherit;
    font-size: 0.83333rem;
    height: 40px;
  }

  .pdf-previous:disabled,
  .pdf-next:disabled {
    background: $tiefenbach-secondary-color;
  }

  .pdf-fullscreen {
    align-self: center;
    background-color: #fff;
    background-image: url('/design/images/icons/fullscreen.png');
    background-repeat: no-repeat;
    background-size: cover;

    height: 25px;
    transition: width 0.1s ease-in-out, height 0.1s ease-in-out;
    width: 25px;

    &.pdf-fullscreen:hover {
      height: 28px;
      width: 28px;
    }

    &.pdf-fullscreen.hide-fullscreen {
      display: none;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    .pdf-fullscreen {
      display: none;
    }
  }
}
