/* stylelint-disable */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

a {
  color: #00e;
}

a:visited {
  color: #551a8b;
}

a:hover {
  color: #06e;
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: 700;
}

blockquote {
  margin: 1em 40px;
}

dfn {
  font-style: italic;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
}

ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: 700;
}

pre,
code,
kbd,
samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none;
}

q::before,
q::after {
  content: '';
  content: none;
}

small {
  font-size: 85%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

ul,
ol {
  margin: 1em 0;
  padding: 0;
}

dd {
  margin: 0 0 0 40px;
}

nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

img {
  border: 0;
  interpolation-mode: bicubic;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

label {
  cursor: pointer;
}

legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
  white-space: normal;
}

button,
input {
  line-height: normal;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  appearance: button;
  cursor: pointer;
  *overflow: visible;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  *height: 13px;
  padding: 0;
  *width: 13px;
}

input[type='search'] {
  appearance: textfield;
  box-sizing: content-box;
  box-sizing: content-box;
  box-sizing: content-box;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button {
  appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top;
}

input:invalid,
textarea:invalid { /* ge�ndert auf wunsch ME durch SLT */
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: inset 0 0 4px #eee;
  box-shadow: inset 0 0 4px #eee;
  box-shadow: inset 0 0 4px #eee;
  padding: 0.2em 0.3em;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  vertical-align: top;
}

.chromeframe {
  background: #ccc;
  color: #000;
  margin: 0.2em 0;
  padding: 0.2em 0;
}

.ir {
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  direction: ltr;
  display: block;
  *line-height: 0;
  overflow: hidden;
  text-align: left;
  text-indent: -999em;
}

.ir br {
  display: none;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix::before,
.clearfix::after {
  content: '';
  display: table;
}

.clearfix::after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

@media print {
  * {
    background: transparent;
    box-shadow: none !important;
    color: #000;
    filter: none !important;
    filter: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: '(' attr(title) ')';
  }

  .ir a::after,
  a[href^='javascript:']::after,
  a[href^='#']::after {
    content: '';
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }



  /*******************************************************************************/
  /* News -Widget */
  /*******************************************************************************/


  .sv-homepage-contentBox-news {float: left;
    width: 100%;}

  /* Darstellung Short */
  .sv-widget-news-short {float: left;
    width: 100%;}

  /* Darstellung Standard */
  .sv-widget-news-base {float: left;
    width: 100%;}





  /* Darstellung Compact */
  .sv-widget-news-compact {float: left;
    width: 100%;}



}

.sv_widget_debugbar {
  color: #333;
  left: 0;
  position: absolute;
  top: 0;
}

.sv_widget_debugbar_title {
  background-color: #ccf8ff;
  border-radius: 3px;
  border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
  height: 15px;
  line-height: 15px;
  margin: 3px;
  overflow: hidden;
  padding: 5px;
  position: absolute;
  text-align: center;
  width: 15px;
  z-index: 100;

}

.sv_widget_debugbar_content {
  display: none;
}


.sv_debugbar_expanded .sv_widget_debugbar_content {
  background-color: #ccf8ff;
  display: block;
  height: 600px !important;
  margin-top: 20px;
  overflow: auto;
  padding: 10px;
  position: absolute;
  width: 1200px !important;
  z-index: 101;
}

.sv_widget_debugbar_title_slow {
  background-color: #fff78c !important;
}

.sv_widget_debugbar_title_toslow {
  background-color: #ff3831 !important;
}

#sv_debugbar {
  background-color: #ccf8ff;
  border-radius: 3px 0 0 3px;
  border-radius: 0 0 0 3px;
  border-radius: 3px 0 0 3px;
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  padding: 5px 15px;
  position: fixed;
  right: -125px;
  top: 100px;
  width: 110px;
  z-index: 100000000;
}

#sv_debugbar.sv_debugbar_expanded {
  height: 400px !important;
  overflow: auto;
  width: 1200px !important;

}

.sv_debug_row {
  border-top: 1px solid #eee;
  padding-bottom: 10px;
  width: 1000px !important;
}

.sv-jquery-tabs {
  border: 1px solid #efefef;
  padding: 20px;
}
