/* stylelint-disable */
@font-face {
    font-family: 'Open Sans Light';
    font-style: normal;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v10/DXI1ORHCpsQm3Vp6mXoaTXhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(https://themes.googleusercontent.com/static/fonts/opensans/v8/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff) format('woff');
}

@font-face {
    font-family: 'Open Sans Bold';
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://themes.googleusercontent.com/static/fonts/opensans/v8/k3k702ZOKiLJc3WVjuplzHhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}

meta.foundation-version {
    font-family: '/5.4.6/';
}

meta.foundation-mq-small {
    font-family: '/only screen/';
    width: 0;
}

meta.foundation-mq-medium {
    font-family: '/only screen and (min-width:52.063em)/';
    width: 52.063em;
}

meta.foundation-mq-large {
    font-family: '/only screen and (min-width:60.063em)/';
    width: 60.063em;
}

meta.foundation-mq-xlarge {
    font-family: '/only screen and (min-width:90.063em)/';
    width: 90.063em;
}

meta.foundation-mq-xxlarge {
    font-family: '/only screen and (min-width:120.063em)/';
    width: 120.063em;
}

meta.foundation-data-attribute-namespace {
    font-family: false;
}

html,
body {
    height: 100%;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    box-sizing: border-box;
    box-sizing: border-box;
}

html,
body {
    font-size: 18px;
}

body {
    background: #f0f0f0;
    color: #272727;
    cursor: auto;
    font-family: 'Open Sans', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    position: relative;
}

a:hover {
    cursor: pointer;
}

img {
    height: auto;
    max-width: 100%;
}

img {
    interpolation-mode: bicubic;
}

#map_canvas img,
#map_canvas embed,
#map_canvas object,
.map_canvas img,
.map_canvas embed,
.map_canvas object {
    max-width: none !important;
}

.left {
    float: left !important;
}

.right,
.ym-grid .ym-gr > .ym-gbox {
    float: right !important;
}

.clearfix::before,
.clearfix::after {
    content: ' ';
    display: table;
}

.clearfix::after {
    clear: both;
}

.hide {
    display: none;
}

.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    display: inline-block;
    vertical-align: middle;
}

textarea {
    height: auto;
    min-height: 50px;
}

select {
    width: 100%;
}

.row {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    width: 100%;
}

.row::before,
.row::after {
    content: ' ';
    display: table;
}

.row::after {
    clear: both;
}

.row.collapse > .column,
.ym-grid .row.collapse > .ym-gl,
.ym-grid .row.collapse > .ym-gr,
.row.collapse > .columns {
    padding-left: 0;
    padding-right: 0;
}

.row.collapse .row {
    margin-left: 0;
    margin-right: 0;
}

.row .row {
    margin-bottom: 0;
    margin-left: -0.55556rem;
    margin-right: -0.55556rem;
    margin-top: 0;
    max-width: none;
    width: auto;
}

.row .row::before,
.row .row::after {
    content: ' ';
    display: table;
}

.row .row::after {
    clear: both;
}

.row .row.collapse {
    margin: 0;
    max-width: none;
    width: auto;
}

.row .row.collapse::before,
.row .row.collapse::after {
    content: ' ';
    display: table;
}

.row .row.collapse::after {
    clear: both;
}

.column,
.ym-grid .ym-gl,
.ym-grid .ym-gr,
.columns {
    float: left;
    padding-left: 0.55556rem;
    padding-right: 0.55556rem;
    width: 100%;
}

[class*='column'] + [class*='column']:last-child {
    float: right;
}

[class*='column'] + [class*='column'].end {
    float: left;
}

@media only screen {
    .small-push-0 {
        left: 0%;
        position: relative;
        right: auto;
    }

    .small-pull-0 {
        left: auto;
        position: relative;
        right: 0%;
    }

    .small-push-1 {
        left: 8.33333%;
        position: relative;
        right: auto;
    }

    .small-pull-1 {
        left: auto;
        position: relative;
        right: 8.33333%;
    }

    .small-push-2 {
        left: 16.66667%;
        position: relative;
        right: auto;
    }

    .small-pull-2 {
        left: auto;
        position: relative;
        right: 16.66667%;
    }

    .small-push-3 {
        left: 25%;
        position: relative;
        right: auto;
    }

    .small-pull-3 {
        left: auto;
        position: relative;
        right: 25%;
    }

    .small-push-4 {
        left: 33.33333%;
        position: relative;
        right: auto;
    }

    .small-pull-4 {
        left: auto;
        position: relative;
        right: 33.33333%;
    }

    .small-push-5 {
        left: 41.66667%;
        position: relative;
        right: auto;
    }

    .small-pull-5 {
        left: auto;
        position: relative;
        right: 41.66667%;
    }

    .small-push-6 {
        left: 50%;
        position: relative;
        right: auto;
    }

    .small-pull-6 {
        left: auto;
        position: relative;
        right: 50%;
    }

    .small-push-7 {
        left: 58.33333%;
        position: relative;
        right: auto;
    }

    .small-pull-7 {
        left: auto;
        position: relative;
        right: 58.33333%;
    }

    .small-push-8 {
        left: 66.66667%;
        position: relative;
        right: auto;
    }

    .small-pull-8 {
        left: auto;
        position: relative;
        right: 66.66667%;
    }

    .small-push-9 {
        left: 75%;
        position: relative;
        right: auto;
    }

    .small-pull-9 {
        left: auto;
        position: relative;
        right: 75%;
    }

    .small-push-10 {
        left: 83.33333%;
        position: relative;
        right: auto;
    }

    .small-pull-10 {
        left: auto;
        position: relative;
        right: 83.33333%;
    }

    .small-push-11 {
        left: 91.66667%;
        position: relative;
        right: auto;
    }

    .small-pull-11 {
        left: auto;
        position: relative;
        right: 91.66667%;
    }

    .column,
    .ym-grid .ym-gl,
    .ym-grid .ym-gr,
    .columns {
        float: left;
        padding-left: 0.55556rem;
        padding-right: 0.55556rem;
        position: relative;
    }

    .small-1 {
        width: 8.33333%;
    }

    .small-2 {
        width: 16.66667%;
    }

    .small-3 {
        width: 25%;
    }

    .small-4 {
        width: 33.33333%;
    }

    .small-5 {
        width: 41.66667%;
    }

    .small-6 {
        width: 50%;
    }

    .small-7 {
        width: 58.33333%;
    }

    .small-8 {
        width: 66.66667%;
    }

    .small-9 {
        width: 75%;
    }

    .small-10 {
        width: 83.33333%;
    }

    .small-11 {
        width: 91.66667%;
    }

    .small-12 {
        width: 100%;
    }

    .small-offset-0 {
        margin-left: 0% !important;
    }

    .small-offset-1 {
        margin-left: 8.33333% !important;
    }

    .small-offset-2 {
        margin-left: 16.66667% !important;
    }

    .small-offset-3 {
        margin-left: 25% !important;
    }

    .small-offset-4 {
        margin-left: 33.33333% !important;
    }

    .small-offset-5 {
        margin-left: 41.66667% !important;
    }

    .small-offset-6 {
        margin-left: 50% !important;
    }

    .small-offset-7 {
        margin-left: 58.33333% !important;
    }

    .small-offset-8 {
        margin-left: 66.66667% !important;
    }

    .small-offset-9 {
        margin-left: 75% !important;
    }

    .small-offset-10 {
        margin-left: 83.33333% !important;
    }

    .small-offset-11 {
        margin-left: 91.66667% !important;
    }

    .small-reset-order {
        float: left;
        left: auto;
        margin-left: 0;
        margin-right: 0;
        right: auto;
    }

    .column.small-centered,
    .ym-grid .small-centered.ym-gl,
    .ym-grid .small-centered.ym-gr,
    .columns.small-centered {
        float: none;
        margin-left: auto;
        margin-right: auto;
    }

    .column.small-uncentered,
    .ym-grid .small-uncentered.ym-gl,
    .ym-grid .small-uncentered.ym-gr,
    .columns.small-uncentered {
        float: left;
        margin-left: 0;
        margin-right: 0;
    }

    .column.small-centered:last-child,
    .ym-grid .small-centered.ym-gl:last-child,
    .ym-grid .small-centered.ym-gr:last-child,
    .columns.small-centered:last-child {
        float: none;
    }

    .column.small-uncentered:last-child,
    .ym-grid .small-uncentered.ym-gl:last-child,
    .ym-grid .small-uncentered.ym-gr:last-child,
    .columns.small-uncentered:last-child {
        float: left;
    }

    .column.small-uncentered.opposite,
    .ym-grid .small-uncentered.opposite.ym-gl,
    .ym-grid .small-uncentered.opposite.ym-gr,
    .columns.small-uncentered.opposite {
        float: right;
    }
}

@media only screen and (min-width: 52.063em) {
    .medium-push-0 {
        left: 0%;
        position: relative;
        right: auto;
    }

    .medium-pull-0 {
        left: auto;
        position: relative;
        right: 0%;
    }

    .medium-push-1 {
        left: 8.33333%;
        position: relative;
        right: auto;
    }

    .medium-pull-1 {
        left: auto;
        position: relative;
        right: 8.33333%;
    }

    .medium-push-2 {
        left: 16.66667%;
        position: relative;
        right: auto;
    }

    .medium-pull-2 {
        left: auto;
        position: relative;
        right: 16.66667%;
    }

    .medium-push-3 {
        left: 25%;
        position: relative;
        right: auto;
    }

    .medium-pull-3 {
        left: auto;
        position: relative;
        right: 25%;
    }

    .medium-push-4 {
        left: 33.33333%;
        position: relative;
        right: auto;
    }

    .medium-pull-4 {
        left: auto;
        position: relative;
        right: 33.33333%;
    }

    .medium-push-5 {
        left: 41.66667%;
        position: relative;
        right: auto;
    }

    .medium-pull-5 {
        left: auto;
        position: relative;
        right: 41.66667%;
    }

    .medium-push-6 {
        left: 50%;
        position: relative;
        right: auto;
    }

    .medium-pull-6 {
        left: auto;
        position: relative;
        right: 50%;
    }

    .medium-push-7 {
        left: 58.33333%;
        position: relative;
        right: auto;
    }

    .medium-pull-7 {
        left: auto;
        position: relative;
        right: 58.33333%;
    }

    .medium-push-8 {
        left: 66.66667%;
        position: relative;
        right: auto;
    }

    .medium-pull-8 {
        left: auto;
        position: relative;
        right: 66.66667%;
    }

    .medium-push-9 {
        left: 75%;
        position: relative;
        right: auto;
    }

    .medium-pull-9 {
        left: auto;
        position: relative;
        right: 75%;
    }

    .medium-push-10 {
        left: 83.33333%;
        position: relative;
        right: auto;
    }

    .medium-pull-10 {
        left: auto;
        position: relative;
        right: 83.33333%;
    }

    .medium-push-11 {
        left: 91.66667%;
        position: relative;
        right: auto;
    }

    .medium-pull-11 {
        left: auto;
        position: relative;
        right: 91.66667%;
    }

    .column,
    .ym-grid .ym-gl,
    .ym-grid .ym-gr,
    .columns {
        float: left;
        padding-left: 0.55556rem;
        padding-right: 0.55556rem;
        position: relative;
    }

    .medium-1 {
        width: 8.33333%;
    }

    .medium-2 {
        width: 16.66667%;
    }

    .medium-3 {
        width: 25%;
    }

    .medium-4 {
        width: 33.33333%;
    }

    .medium-5 {
        width: 41.66667%;
    }

    .medium-6 {
        width: 50%;
    }

    .medium-7 {
        width: 58.33333%;
    }

    .medium-8 {
        width: 66.66667%;
    }

    .medium-9 {
        width: 75%;
    }

    .medium-10 {
        width: 83.33333%;
    }

    .medium-11 {
        width: 91.66667%;
    }

    .medium-12 {
        width: 100%;
    }

    .medium-offset-0 {
        margin-left: 0% !important;
    }

    .medium-offset-1 {
        margin-left: 8.33333% !important;
    }

    .medium-offset-2 {
        margin-left: 16.66667% !important;
    }

    .medium-offset-3 {
        margin-left: 25% !important;
    }

    .medium-offset-4 {
        margin-left: 33.33333% !important;
    }

    .medium-offset-5 {
        margin-left: 41.66667% !important;
    }

    .medium-offset-6 {
        margin-left: 50% !important;
    }

    .medium-offset-7 {
        margin-left: 58.33333% !important;
    }

    .medium-offset-8 {
        margin-left: 66.66667% !important;
    }

    .medium-offset-9 {
        margin-left: 75% !important;
    }

    .medium-offset-10 {
        margin-left: 83.33333% !important;
    }

    .medium-offset-11 {
        margin-left: 91.66667% !important;
    }

    .medium-reset-order {
        float: left;
        left: auto;
        margin-left: 0;
        margin-right: 0;
        right: auto;
    }

    .column.medium-centered,
    .ym-grid .medium-centered.ym-gl,
    .ym-grid .medium-centered.ym-gr,
    .columns.medium-centered {
        float: none;
        margin-left: auto;
        margin-right: auto;
    }

    .column.medium-uncentered,
    .ym-grid .medium-uncentered.ym-gl,
    .ym-grid .medium-uncentered.ym-gr,
    .columns.medium-uncentered {
        float: left;
        margin-left: 0;
        margin-right: 0;
    }

    .column.medium-centered:last-child,
    .ym-grid .medium-centered.ym-gl:last-child,
    .ym-grid .medium-centered.ym-gr:last-child,
    .columns.medium-centered:last-child {
        float: none;
    }

    .column.medium-uncentered:last-child,
    .ym-grid .medium-uncentered.ym-gl:last-child,
    .ym-grid .medium-uncentered.ym-gr:last-child,
    .columns.medium-uncentered:last-child {
        float: left;
    }

    .column.medium-uncentered.opposite,
    .ym-grid .medium-uncentered.opposite.ym-gl,
    .ym-grid .medium-uncentered.opposite.ym-gr,
    .columns.medium-uncentered.opposite {
        float: right;
    }

    .push-0 {
        left: 0%;
        position: relative;
        right: auto;
    }

    .pull-0 {
        left: auto;
        position: relative;
        right: 0%;
    }

    .push-1 {
        left: 8.33333%;
        position: relative;
        right: auto;
    }

    .pull-1 {
        left: auto;
        position: relative;
        right: 8.33333%;
    }

    .push-2 {
        left: 16.66667%;
        position: relative;
        right: auto;
    }

    .pull-2 {
        left: auto;
        position: relative;
        right: 16.66667%;
    }

    .push-3 {
        left: 25%;
        position: relative;
        right: auto;
    }

    .pull-3 {
        left: auto;
        position: relative;
        right: 25%;
    }

    .push-4 {
        left: 33.33333%;
        position: relative;
        right: auto;
    }

    .pull-4 {
        left: auto;
        position: relative;
        right: 33.33333%;
    }

    .push-5 {
        left: 41.66667%;
        position: relative;
        right: auto;
    }

    .pull-5 {
        left: auto;
        position: relative;
        right: 41.66667%;
    }

    .push-6 {
        left: 50%;
        position: relative;
        right: auto;
    }

    .pull-6 {
        left: auto;
        position: relative;
        right: 50%;
    }

    .push-7 {
        left: 58.33333%;
        position: relative;
        right: auto;
    }

    .pull-7 {
        left: auto;
        position: relative;
        right: 58.33333%;
    }

    .push-8 {
        left: 66.66667%;
        position: relative;
        right: auto;
    }

    .pull-8 {
        left: auto;
        position: relative;
        right: 66.66667%;
    }

    .push-9 {
        left: 75%;
        position: relative;
        right: auto;
    }

    .pull-9 {
        left: auto;
        position: relative;
        right: 75%;
    }

    .push-10 {
        left: 83.33333%;
        position: relative;
        right: auto;
    }

    .pull-10 {
        left: auto;
        position: relative;
        right: 83.33333%;
    }

    .push-11 {
        left: 91.66667%;
        position: relative;
        right: auto;
    }

    .pull-11 {
        left: auto;
        position: relative;
        right: 91.66667%;
    }
}

@media only screen and (min-width: 60.063em) {
    .large-push-0 {
        left: 0%;
        position: relative;
        right: auto;
    }

    .large-pull-0 {
        left: auto;
        position: relative;
        right: 0%;
    }

    .large-push-1 {
        left: 8.33333%;
        position: relative;
        right: auto;
    }

    .large-pull-1 {
        left: auto;
        position: relative;
        right: 8.33333%;
    }

    .large-push-2 {
        left: 16.66667%;
        position: relative;
        right: auto;
    }

    .large-pull-2 {
        left: auto;
        position: relative;
        right: 16.66667%;
    }

    .large-push-3 {
        left: 25%;
        position: relative;
        right: auto;
    }

    .large-pull-3 {
        left: auto;
        position: relative;
        right: 25%;
    }

    .large-push-4 {
        left: 33.33333%;
        position: relative;
        right: auto;
    }

    .large-pull-4 {
        left: auto;
        position: relative;
        right: 33.33333%;
    }

    .large-push-5 {
        left: 41.66667%;
        position: relative;
        right: auto;
    }

    .large-pull-5 {
        left: auto;
        position: relative;
        right: 41.66667%;
    }

    .large-push-6 {
        left: 50%;
        position: relative;
        right: auto;
    }

    .large-pull-6 {
        left: auto;
        position: relative;
        right: 50%;
    }

    .large-push-7 {
        left: 58.33333%;
        position: relative;
        right: auto;
    }

    .large-pull-7 {
        left: auto;
        position: relative;
        right: 58.33333%;
    }

    .large-push-8 {
        left: 66.66667%;
        position: relative;
        right: auto;
    }

    .large-pull-8 {
        left: auto;
        position: relative;
        right: 66.66667%;
    }

    .large-push-9 {
        left: 75%;
        position: relative;
        right: auto;
    }

    .large-pull-9 {
        left: auto;
        position: relative;
        right: 75%;
    }

    .large-push-10 {
        left: 83.33333%;
        position: relative;
        right: auto;
    }

    .large-pull-10 {
        left: auto;
        position: relative;
        right: 83.33333%;
    }

    .large-push-11 {
        left: 91.66667%;
        position: relative;
        right: auto;
    }

    .large-pull-11 {
        left: auto;
        position: relative;
        right: 91.66667%;
    }

    .column,
    .ym-grid .ym-gl,
    .ym-grid .ym-gr,
    .columns {
        float: left;
        padding-left: 0.55556rem;
        padding-right: 0.55556rem;
        position: relative;
    }

    .large-1 {
        width: 8.33333%;
    }

    .large-2 {
        width: 16.66667%;
    }

    .large-3 {
        width: 25%;
    }

    .large-4 {
        width: 33.33333%;
    }

    .large-5 {
        width: 41.66667%;
    }

    .large-6 {
        width: 50%;
    }

    .large-7 {
        width: 58.33333%;
    }

    .large-8 {
        width: 66.66667%;
    }

    .large-9 {
        width: 75%;
    }

    .large-10 {
        width: 83.33333%;
    }

    .large-11 {
        width: 91.66667%;
    }

    .large-12 {
        width: 100%;
    }

    .large-offset-0 {
        margin-left: 0% !important;
    }

    .large-offset-1 {
        margin-left: 8.33333% !important;
    }

    .large-offset-2 {
        margin-left: 16.66667% !important;
    }

    .large-offset-3 {
        margin-left: 25% !important;
    }

    .large-offset-4 {
        margin-left: 33.33333% !important;
    }

    .large-offset-5 {
        margin-left: 41.66667% !important;
    }

    .large-offset-6 {
        margin-left: 50% !important;
    }

    .large-offset-7 {
        margin-left: 58.33333% !important;
    }

    .large-offset-8 {
        margin-left: 66.66667% !important;
    }

    .large-offset-9 {
        margin-left: 75% !important;
    }

    .large-offset-10 {
        margin-left: 83.33333% !important;
    }

    .large-offset-11 {
        margin-left: 91.66667% !important;
    }

    .large-reset-order {
        float: left;
        left: auto;
        margin-left: 0;
        margin-right: 0;
        right: auto;
    }

    .column.large-centered,
    .ym-grid .large-centered.ym-gl,
    .ym-grid .large-centered.ym-gr,
    .columns.large-centered {
        float: none;
        margin-left: auto;
        margin-right: auto;
    }

    .column.large-uncentered,
    .ym-grid .large-uncentered.ym-gl,
    .ym-grid .large-uncentered.ym-gr,
    .columns.large-uncentered {
        float: left;
        margin-left: 0;
        margin-right: 0;
    }

    .column.large-centered:last-child,
    .ym-grid .large-centered.ym-gl:last-child,
    .ym-grid .large-centered.ym-gr:last-child,
    .columns.large-centered:last-child {
        float: none;
    }

    .column.large-uncentered:last-child,
    .ym-grid .large-uncentered.ym-gl:last-child,
    .ym-grid .large-uncentered.ym-gr:last-child,
    .columns.large-uncentered:last-child {
        float: left;
    }

    .column.large-uncentered.opposite,
    .ym-grid .large-uncentered.opposite.ym-gl,
    .ym-grid .large-uncentered.opposite.ym-gr,
    .columns.large-uncentered.opposite {
        float: right;
    }

    .push-0 {
        left: 0%;
        position: relative;
        right: auto;
    }

    .pull-0 {
        left: auto;
        position: relative;
        right: 0%;
    }

    .push-1 {
        left: 8.33333%;
        position: relative;
        right: auto;
    }

    .pull-1 {
        left: auto;
        position: relative;
        right: 8.33333%;
    }

    .push-2 {
        left: 16.66667%;
        position: relative;
        right: auto;
    }

    .pull-2 {
        left: auto;
        position: relative;
        right: 16.66667%;
    }

    .push-3 {
        left: 25%;
        position: relative;
        right: auto;
    }

    .pull-3 {
        left: auto;
        position: relative;
        right: 25%;
    }

    .push-4 {
        left: 33.33333%;
        position: relative;
        right: auto;
    }

    .pull-4 {
        left: auto;
        position: relative;
        right: 33.33333%;
    }

    .push-5 {
        left: 41.66667%;
        position: relative;
        right: auto;
    }

    .pull-5 {
        left: auto;
        position: relative;
        right: 41.66667%;
    }

    .push-6 {
        left: 50%;
        position: relative;
        right: auto;
    }

    .pull-6 {
        left: auto;
        position: relative;
        right: 50%;
    }

    .push-7 {
        left: 58.33333%;
        position: relative;
        right: auto;
    }

    .pull-7 {
        left: auto;
        position: relative;
        right: 58.33333%;
    }

    .push-8 {
        left: 66.66667%;
        position: relative;
        right: auto;
    }

    .pull-8 {
        left: auto;
        position: relative;
        right: 66.66667%;
    }

    .push-9 {
        left: 75%;
        position: relative;
        right: auto;
    }

    .pull-9 {
        left: auto;
        position: relative;
        right: 75%;
    }

    .push-10 {
        left: 83.33333%;
        position: relative;
        right: auto;
    }

    .pull-10 {
        left: auto;
        position: relative;
        right: 83.33333%;
    }

    .push-11 {
        left: 91.66667%;
        position: relative;
        right: auto;
    }

    .pull-11 {
        left: auto;
        position: relative;
        right: 91.66667%;
    }
}

.inline-list {
    list-style: none;
    margin: 0 auto;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    padding: 0;
}

.inline-list > li {
    display: block;
    float: left;
    list-style: none;
    margin-left: 1.22222rem;
}

.inline-list > li > * {
    display: inline;
}

form {
    margin: 0 0 0.44444rem;
}

form .row .row {
    margin: 0 -0.22222rem;
}

form .row .row .column,
form .row .row .ym-grid .ym-gl,
.ym-grid form .row .row .ym-gl,
form .row .row .ym-grid .ym-gr,
.ym-grid form .row .row .ym-gr,
form .row .row .columns {
    padding: 0 0.22222rem;
}

form .row .row.collapse {
    margin: 0;
}

form .row .row.collapse .column,
form .row .row.collapse .ym-grid .ym-gl,
.ym-grid form .row .row.collapse .ym-gl,
form .row .row.collapse .ym-grid .ym-gr,
.ym-grid form .row .row.collapse .ym-gr,
form .row .row.collapse .columns {
    padding: 0;
}

form .row .row.collapse input {
    border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-top-right-radius: 0;
}

form .row input.column,
form .row .ym-grid input.ym-gl,
.ym-grid form .row input.ym-gl,
form .row .ym-grid input.ym-gr,
.ym-grid form .row input.ym-gr,
form .row input.columns,
form .row textarea.column,
form .row .ym-grid textarea.ym-gl,
.ym-grid form .row textarea.ym-gl,
form .row .ym-grid textarea.ym-gr,
.ym-grid form .row textarea.ym-gr,
form .row textarea.columns {
    padding-left: 0.22222rem;
}

label {
    color: #272727;
    cursor: pointer;
    display: block;
    font-size: 0.88889rem;
    font-weight: normal;
    line-height: 1.5;
    margin-bottom: 0;
}

label.right,
.ym-grid .ym-gr > label.ym-gbox {
    float: none !important;
    text-align: right;
}

label.inline {
    margin: 0 0 0.44444rem;
    padding: 0.27778rem 0;
}

label small {
    color: #474747;
    text-transform: capitalize;
}

select::-ms-expand {
    display: none;
}

.prefix,
.postfix {
    border-style: solid;
    border-width: 1px;
    display: block;
    font-size: 0.88889rem;
    height: 1.5rem;
    line-height: 1.5rem;
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 2;
}

.postfix.button {
    border: none;
    line-height: 1.88889rem;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: center;
}

.prefix.button {
    border: none;
    line-height: 1.88889rem;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: center;
}

.prefix.button.radius {
    border-radius: 0;
    border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    border-top-left-radius: 3px;
}

.postfix.button.radius {
    border-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    border-top-right-radius: 3px;
}

.prefix.button.round {
    border-radius: 0;
    border-bottom-left-radius: 1000px;
    border-bottom-left-radius: 1000px;
    border-top-left-radius: 1000px;
    border-top-left-radius: 1000px;
}

.postfix.button.round {
    border-radius: 0;
    border-bottom-right-radius: 1000px;
    border-bottom-right-radius: 1000px;
    border-top-right-radius: 1000px;
    border-top-right-radius: 1000px;
}

span.prefix,
label.prefix {
    background: #f2f2f2;
    border-color: #ccc;
    border-right: none;
    color: #333;
}

span.postfix,
label.postfix {
    background: #f2f2f2;
    border-color: #ccc;
    border-left: none;
    color: #333;
}

input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='color'],
textarea {
    appearance: none;
    background-color: #fff;
    border-color: #ccc;
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-sizing: border-box;
    box-sizing: border-box;
    color: #272727;
    display: block;
    font-family: inherit;
    font-size: 0.88889rem;
    height: 1.5rem;
    margin: 0 0 0.44444rem;
    padding: 0.22222rem;
    transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
    width: 100%;
}

input[type='text']:focus,
input[type='password']:focus,
input[type='date']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='month']:focus,
input[type='week']:focus,
input[type='email']:focus,
input[type='number']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='time']:focus,
input[type='url']:focus,
input[type='color']:focus,
textarea:focus {
    border-color: #999;
    box-shadow: 0 0 5px #999;
}

input[type='text']:focus,
input[type='password']:focus,
input[type='date']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='month']:focus,
input[type='week']:focus,
input[type='email']:focus,
input[type='number']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='time']:focus,
input[type='url']:focus,
input[type='color']:focus,
textarea:focus {
    background: #fafafa;
    border-color: #999;
    outline: none;
}

input[type='text']:disabled,
input[type='password']:disabled,
input[type='date']:disabled,
input[type='datetime']:disabled,
input[type='datetime-local']:disabled,
input[type='month']:disabled,
input[type='week']:disabled,
input[type='email']:disabled,
input[type='number']:disabled,
input[type='search']:disabled,
input[type='tel']:disabled,
input[type='time']:disabled,
input[type='url']:disabled,
input[type='color']:disabled,
textarea:disabled {
    background-color: #ddd;
    cursor: default;
}

input[type='text'][disabled],
input[type='text'][readonly],
fieldset[disabled] input[type='text'],
input[type='password'][disabled],
input[type='password'][readonly],
fieldset[disabled] input[type='password'],
input[type='date'][disabled],
input[type='date'][readonly],
fieldset[disabled] input[type='date'],
input[type='datetime'][disabled],
input[type='datetime'][readonly],
fieldset[disabled] input[type='datetime'],
input[type='datetime-local'][disabled],
input[type='datetime-local'][readonly],
fieldset[disabled] input[type='datetime-local'],
input[type='month'][disabled],
input[type='month'][readonly],
fieldset[disabled] input[type='month'],
input[type='week'][disabled],
input[type='week'][readonly],
fieldset[disabled] input[type='week'],
input[type='email'][disabled],
input[type='email'][readonly],
fieldset[disabled] input[type='email'],
input[type='number'][disabled],
input[type='number'][readonly],
fieldset[disabled] input[type='number'],
input[type='search'][disabled],
input[type='search'][readonly],
fieldset[disabled] input[type='search'],
input[type='tel'][disabled],
input[type='tel'][readonly],
fieldset[disabled] input[type='tel'],
input[type='time'][disabled],
input[type='time'][readonly],
fieldset[disabled] input[type='time'],
input[type='url'][disabled],
input[type='url'][readonly],
fieldset[disabled] input[type='url'],
input[type='color'][disabled],
input[type='color'][readonly],
fieldset[disabled] input[type='color'],
textarea[disabled],
textarea[readonly],
fieldset[disabled] textarea {
    background-color: #ddd;
    cursor: default;
}

input[type='text'].radius,
input[type='password'].radius,
input[type='date'].radius,
input[type='datetime'].radius,
input[type='datetime-local'].radius,
input[type='month'].radius,
input[type='week'].radius,
input[type='email'].radius,
input[type='number'].radius,
input[type='search'].radius,
input[type='tel'].radius,
input[type='time'].radius,
input[type='url'].radius,
input[type='color'].radius,
textarea.radius {
    border-radius: 3px;
}

form .row .prefix-radius.row.collapse input,
form .row .prefix-radius.row.collapse textarea,
form .row .prefix-radius.row.collapse select {
    border-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    border-top-right-radius: 3px;
}

form .row .prefix-radius.row.collapse .prefix {
    border-radius: 0;
    border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    border-top-left-radius: 3px;
}

form .row .postfix-radius.row.collapse input,
form .row .postfix-radius.row.collapse textarea,
form .row .postfix-radius.row.collapse select {
    border-radius: 0;
    border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    border-top-left-radius: 3px;
}

form .row .postfix-radius.row.collapse .postfix {
    border-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    border-top-right-radius: 3px;
}

form .row .prefix-round.row.collapse input,
form .row .prefix-round.row.collapse textarea,
form .row .prefix-round.row.collapse select {
    border-radius: 0;
    border-bottom-right-radius: 1000px;
    border-bottom-right-radius: 1000px;
    border-top-right-radius: 1000px;
    border-top-right-radius: 1000px;
}

form .row .prefix-round.row.collapse .prefix {
    border-radius: 0;
    border-bottom-left-radius: 1000px;
    border-bottom-left-radius: 1000px;
    border-top-left-radius: 1000px;
    border-top-left-radius: 1000px;
}

form .row .postfix-round.row.collapse input,
form .row .postfix-round.row.collapse textarea,
form .row .postfix-round.row.collapse select {
    border-radius: 0;
    border-bottom-left-radius: 1000px;
    border-bottom-left-radius: 1000px;
    border-top-left-radius: 1000px;
    border-top-left-radius: 1000px;
}

form .row .postfix-round.row.collapse .postfix {
    border-radius: 0;
    border-bottom-right-radius: 1000px;
    border-bottom-right-radius: 1000px;
    border-top-right-radius: 1000px;
    border-top-right-radius: 1000px;
}

input[type='submit'] {
    appearance: none;
    border-radius: 0;
}

textarea[rows] {
    height: auto;
}

textarea {
    max-width: 100%;
}

select {
    appearance: none !important;
    background-color: #fafafa;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+);
    background-position: 100% center;
    background-repeat: no-repeat;
    border-color: #ccc;
    border-radius: 0;
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    color: #272727;
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 0.88889rem;
    height: 1.5rem;
    line-height: normal;
    padding: 0.22222rem;
}

select.radius {
    border-radius: 3px;
}

select:hover {
    background-color: #f3f3f3;
    border-color: #999;
}

select:disabled {
    background-color: #ddd;
    cursor: default;
}

input[type='file'],
input[type='checkbox'],
input[type='radio'],
select {
    margin: 0 0 0.44444rem;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
    display: inline-block;
    margin-bottom: 0;
    margin-left: 0.22222rem;
    margin-right: 0.44444rem;
    vertical-align: baseline;
}

input[type='file'] {
    width: 100%;
}

fieldset {
    border: 1px solid #ddd;
    margin: 1rem 0;
    padding: 1.11111rem;
}

fieldset legend {
    background: #fff;
    font-weight: bold;
    margin: 0;
    margin-left: -0.16667rem;
    padding: 0 0.16667rem;
}

[data-abide] .error small.error,
[data-abide] .error span.error,
[data-abide] span.error,
[data-abide] small.error {
    background: #f04124;
    color: #fff;
    display: block;
    font-size: 0.66667rem;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 0.44444rem;
    margin-top: -1px;
    padding: 0.33333rem 0.5rem 0.5rem;
}

[data-abide] span.error,
[data-abide] small.error {
    display: none;
}

span.error,
small.error {
    background: #f04124;
    color: #fff;
    display: block;
    font-size: 0.66667rem;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 0.44444rem;
    margin-top: -1px;
    padding: 0.33333rem 0.5rem 0.5rem;
}

.error input,
.error textarea,
.error select {
    margin-bottom: 0;
}

.error input[type='checkbox'],
.error input[type='radio'] {
    margin-bottom: 0.44444rem;
}

.error label,
.error label.error {
    color: #f04124;
}

.error small.error {
    background: #f04124;
    color: #fff;
    display: block;
    font-size: 0.66667rem;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 0.44444rem;
    margin-top: -1px;
    padding: 0.33333rem 0.5rem 0.5rem;
}

.error > label > small {
    background: transparent;
    color: #474747;
    display: inline;
    font-size: 60%;
    font-style: normal;
    margin: 0;
    padding: 0;
    text-transform: capitalize;
}

.error span.error-message {
    display: block;
}

input.error,
textarea.error,
select.error {
    margin-bottom: 0;
}

label.error {
    color: #f04124;
}

meta.foundation-mq-topbar {
    font-family: '/only screen and (min-width:52.063em)/';
    width: 52.063em;
}

.contain-to-grid {
    background: rgba(255, 255, 255, 0);
    width: 100%;
}

.contain-to-grid .top-bar {
    margin-bottom: 0;
}

.fixed {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
}

.fixed.expanded:not(.top-bar) {
    height: auto;
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
}

.fixed.expanded:not(.top-bar) .title-area {
    position: fixed;
    width: 100%;
    z-index: 99;
}

.fixed.expanded:not(.top-bar) .top-bar-section {
    margin-top: 45px;
    z-index: 98;
}

.top-bar {
    background: rgba(255, 255, 255, 0);
    height: 45px;
    line-height: 45px;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
}

.top-bar ul {
    list-style: none;
    margin-bottom: 0;
}

.top-bar .row {
    max-width: none;
}

.top-bar form,
.top-bar input {
    margin-bottom: 0;
}

.top-bar input {
    font-size: 0.75rem;
    height: 1.8rem;
    padding-bottom: 0.35rem;
    padding-top: 0.35rem;
}

.top-bar .button,
.top-bar button {
    font-size: 0.75rem;
    margin-bottom: 0;
    padding-bottom: 0.40556rem;
    padding-top: 0.40556rem;
}

@media only screen and (max-width: 52em) {
    .top-bar .button,
    .top-bar button {
        position: relative;
        top: -1px;
    }
}

.top-bar .title-area {
    margin: 0;
    position: relative;
}

.top-bar .name {
    font-size: 18px;
    height: 45px;
    margin: 0;
}

.top-bar .name h1 {
    font-size: 0.77778rem;
    line-height: 45px;
    margin: 0;
}

.top-bar .name h1 a {
    color: #272727;
    display: block;
    font-weight: normal;
    padding: 0 15px;
    width: 75%;
}

.top-bar .toggle-topbar {
    position: absolute;
    right: 0;
    top: 0;
}

.top-bar .toggle-topbar a {
    color: #272727;
    display: block;
    font-size: 0.72222rem;
    font-weight: bold;
    height: 45px;
    line-height: 45px;
    padding: 0 15px;
    position: relative;
    text-transform: uppercase;
}

.top-bar .toggle-topbar.menu-icon {
    margin-top: -16px;
    top: 50%;
}

.top-bar .toggle-topbar.menu-icon a {
    color: #000;
    height: 34px;
    line-height: 33px;
    padding: 0 40px 0 15px;
    position: relative;
}

.top-bar .toggle-topbar.menu-icon a span::after {
    box-shadow: 0 0 0 1px #000, 0 7px 0 1px #000, 0 14px 0 1px #000;
    content: '';
    display: block;
    height: 0;
    margin-top: -8px;
    position: absolute;
    right: 15px;
    top: 50%;
    width: 16px;
}

.top-bar .toggle-topbar.menu-icon a span:hover::after {
    box-shadow: 0 0 0 1px '', 0 7px 0 1px '', 0 14px 0 1px '';
}

.top-bar.expanded {
    background: transparent;
    height: auto;
}

.top-bar.expanded .title-area {
    background: rgba(255, 255, 255, 0);
}

.top-bar.expanded .toggle-topbar a {
    color: #000;
}

.top-bar.expanded .toggle-topbar a span::after {
    box-shadow: 0 0 0 1px #000, 0 7px 0 1px #000, 0 14px 0 1px #000;
}

.top-bar-section {
    left: 0;
    position: relative;
    transition: left 300ms ease-out;
    width: auto;
}

.top-bar-section ul {
    display: block;
    font-size: 18px;
    height: auto;
    margin: 0;
    padding: 0;
    width: 100%;
}

.top-bar-section .divider,
.top-bar-section [role='separator'] {
    border-top: solid 1px rgba(128, 128, 128, 0);
    clear: both;
    height: 1px;
    width: 100%;
}

.top-bar-section ul li {
    background: rgba(255, 255, 255, 0);
}

.top-bar-section ul li > a {
    color: #272727;
    display: block;
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 0.88889rem;
    font-weight: normal;
    padding: 12px 0;
    padding-left: 15px;
    text-transform: none;
    width: 100%;
}

.top-bar-section ul li > a.button {
    background-color: #000;
    border-color: #000;
    color: #272727;
    font-size: 0.88889rem;
    padding-left: 15px;
    padding-right: 15px;
}

.top-bar-section ul li > a.button:hover,
.top-bar-section ul li > a.button:focus {
    background-color: #000;
}

.top-bar-section ul li > a.button:hover,
.top-bar-section ul li > a.button:focus {
    color: #272727;
}

.top-bar-section ul li > a.button.secondary {
    background-color: #e7e7e7;
    border-color: #b9b9b9;
    color: #333;
}

.top-bar-section ul li > a.button.secondary:hover,
.top-bar-section ul li > a.button.secondary:focus {
    background-color: #b9b9b9;
}

.top-bar-section ul li > a.button.secondary:hover,
.top-bar-section ul li > a.button.secondary:focus {
    color: #333;
}

.top-bar-section ul li > a.button.success {
    background-color: #43ac6a;
    border-color: #368a55;
    color: #272727;
}

.top-bar-section ul li > a.button.success:hover,
.top-bar-section ul li > a.button.success:focus {
    background-color: #368a55;
}

.top-bar-section ul li > a.button.success:hover,
.top-bar-section ul li > a.button.success:focus {
    color: #272727;
}

.top-bar-section ul li > a.button.alert {
    background-color: #f04124;
    border-color: #cf2a0e;
    color: #272727;
}

.top-bar-section ul li > a.button.alert:hover,
.top-bar-section ul li > a.button.alert:focus {
    background-color: #cf2a0e;
}

.top-bar-section ul li > a.button.alert:hover,
.top-bar-section ul li > a.button.alert:focus {
    color: #272727;
}

.top-bar-section ul li > a.button.warning {
    background-color: #f08a24;
    border-color: #cf6e0e;
    color: #272727;
}

.top-bar-section ul li > a.button.warning:hover,
.top-bar-section ul li > a.button.warning:focus {
    background-color: #cf6e0e;
}

.top-bar-section ul li > a.button.warning:hover,
.top-bar-section ul li > a.button.warning:focus {
    color: #272727;
}

.top-bar-section ul li > button {
    background-color: #000;
    border-color: #000;
    color: #272727;
    font-size: 0.88889rem;
    padding-left: 15px;
    padding-right: 15px;
}

.top-bar-section ul li > button:hover,
.top-bar-section ul li > button:focus {
    background-color: #000;
}

.top-bar-section ul li > button:hover,
.top-bar-section ul li > button:focus {
    color: #272727;
}

.top-bar-section ul li > button.secondary {
    background-color: #e7e7e7;
    border-color: #b9b9b9;
    color: #333;
}

.top-bar-section ul li > button.secondary:hover,
.top-bar-section ul li > button.secondary:focus {
    background-color: #b9b9b9;
}

.top-bar-section ul li > button.secondary:hover,
.top-bar-section ul li > button.secondary:focus {
    color: #333;
}

.top-bar-section ul li > button.success {
    background-color: #43ac6a;
    border-color: #368a55;
    color: #272727;
}

.top-bar-section ul li > button.success:hover,
.top-bar-section ul li > button.success:focus {
    background-color: #368a55;
}

.top-bar-section ul li > button.success:hover,
.top-bar-section ul li > button.success:focus {
    color: #272727;
}

.top-bar-section ul li > button.alert {
    background-color: #f04124;
    border-color: #cf2a0e;
    color: #272727;
}

.top-bar-section ul li > button.alert:hover,
.top-bar-section ul li > button.alert:focus {
    background-color: #cf2a0e;
}

.top-bar-section ul li > button.alert:hover,
.top-bar-section ul li > button.alert:focus {
    color: #272727;
}

.top-bar-section ul li > button.warning {
    background-color: #f08a24;
    border-color: #cf6e0e;
    color: #272727;
}

.top-bar-section ul li > button.warning:hover,
.top-bar-section ul li > button.warning:focus {
    background-color: #cf6e0e;
}

.top-bar-section ul li > button.warning:hover,
.top-bar-section ul li > button.warning:focus {
    color: #272727;
}

.top-bar-section ul li:hover:not(.has-form) > a {
    background: rgba(255, 255, 255, 0);
    /*background-color: #555;*/
    color: #000;
}

.top-bar-section ul li.active > a {
    background: rgba(255, 255, 255, 0);
    color: #000;
}

.top-bar-section ul li.active > a:hover {
    background: rgba(255, 255, 255, 0);
    color: #000;
}

.top-bar-section .has-form {
    padding: 15px;
}

.top-bar-section .has-dropdown {
    position: relative;
}

.top-bar-section .has-dropdown > a::after {
    margin-right: 15px;
    margin-top: -4.5px;
    position: absolute;
    right: 0;
    top: 50%;
}

.top-bar-section .has-dropdown.moved {
    position: static;
}

.top-bar-section .has-dropdown.moved > .dropdown {
    clip: auto;
    display: block;
    height: auto;
    overflow: visible;
    position: static !important;
    position: absolute !important;
    width: auto;
    width: 100%;
}

.top-bar-section .has-dropdown.moved > a::after {
    display: none;
}

.top-bar-section .dropdown {
    clip: rect(1px, 1px, 1px, 1px);
    display: block;
    height: 1px;
    left: 100%;
    overflow: hidden;
    padding: 0;
    position: absolute;
    position: absolute !important;
    top: 0;
    width: 1px;
    z-index: 99;
}

.top-bar-section .dropdown li {
    height: auto;
    width: 100%;
}

.top-bar-section .dropdown li a {
    font-weight: normal;
    padding: 8px 15px;
}

.top-bar-section .dropdown li a.parent-link {
    font-weight: normal;
}

.top-bar-section .dropdown li.title h5,
.top-bar-section .dropdown li.parent-link {
    font-size: 1rem;
    margin-bottom: 0;
    margin-top: 0;
}

.top-bar-section .dropdown li.title h5 a,
.top-bar-section .dropdown li.parent-link a {
    color: #272727;
    display: block;
}

.top-bar-section .dropdown li.title h5 a:hover,
.top-bar-section .dropdown li.parent-link a:hover {
    background: none;
}

.top-bar-section .dropdown li.has-form {
    padding: 8px 15px;
}

.top-bar-section .dropdown li .button,
.top-bar-section .dropdown li button {
    top: auto;
}

.top-bar-section .dropdown label {
    color: #777;
    font-size: 0.55556rem;
    font-weight: bold;
    margin-bottom: 0;
    padding: 8px 15px 2px;
    text-transform: uppercase;
}

.js-generated {
    display: block;
}

@media only screen and (min-width: 52.063em) {
    .top-bar {
        background: rgba(255, 255, 255, 0);
        overflow: visible;
    }

    .top-bar::before,
    .top-bar::after {
        content: ' ';
        display: table;
    }

    .top-bar::after {
        clear: both;
    }

    .top-bar .toggle-topbar {
        display: none;
    }

    .top-bar .title-area {
        float: left;
    }

    .top-bar .name h1 a {
        width: auto;
    }

    .top-bar input,
    .top-bar .button,
    .top-bar button {
        font-size: 0.77778rem;
        position: relative;
        top: 7px;
    }

    .top-bar.expanded {
        background: rgba(255, 255, 255, 0);
    }

    .contain-to-grid .top-bar {
        margin: 0 auto;
        margin-bottom: 0;
        max-width: 53.33333rem;
    }

    .top-bar-section {
        left: 0 !important;
        transition: none 0 0;
    }

    .top-bar-section ul {
        display: inline;
        height: auto !important;
        width: auto;
    }

    .top-bar-section ul li {
        float: left;
    }

    .top-bar-section ul li .js-generated {
        display: none;
    }

    .top-bar-section li.hover > a:not(.button) {
        background: rgba(255, 255, 255, 0);
        background-color: #555;
        color: #000;
    }

    .top-bar-section li:not(.has-form) a:not(.button) {
        background: rgba(255, 255, 255, 0);
        line-height: 45px;
        padding: 0 15px;
    }

    /*.top-bar-section li:not(.has-form) a:not(.button):hover {*/
    /*    background: rgba(255, 255, 255, 0);*/
    /*    background-color: #555;*/
    /*}*/

    .top-bar-section li.active:not(.has-form) a:not(.button) {
        background: rgba(255, 255, 255, 0);
        color: #000;
        line-height: 45px;
        padding: 0 15px;
    }

    .top-bar-section li.active:not(.has-form) a:not(.button):hover {
        background: rgba(255, 255, 255, 0);
        color: #000;
    }

    .top-bar-section .has-dropdown.moved {
        position: relative;
    }

    .top-bar-section .has-dropdown.moved > .dropdown {
        clip: rect(1px, 1px, 1px, 1px);
        display: block;
        height: 1px;
        overflow: hidden;
        position: absolute !important;
        width: 1px;
    }

    .top-bar-section .has-dropdown.hover > .dropdown,
    .top-bar-section .has-dropdown.not-click:hover > .dropdown {
        clip: auto;
        display: block;
        height: auto;
        overflow: visible;
        position: static !important;
        position: absolute !important;
        width: auto;
    }

    .top-bar-section .has-dropdown > a:focus + .dropdown {
        clip: auto;
        display: block;
        height: auto;
        overflow: visible;
        position: static !important;
        position: absolute !important;
        width: auto;
    }

    .top-bar-section .has-dropdown .dropdown li.has-dropdown > a::after {
        border: none;
        content: '\00bb';
        line-height: 1.2;
        margin-top: -1px;
        right: 5px;
        top: 1rem;
    }

    .top-bar-section .dropdown {
        background: transparent;
        left: 0;
        min-width: 100%;
        top: auto;
    }

    .top-bar-section .dropdown li a {
        background: rgba(255, 255, 255, 0);
        color: #272727;
        line-height: 45px;
        padding: 12px 15px;
        white-space: nowrap;
    }

    .top-bar-section .dropdown li:not(.has-form):not(.active) > a:not(.button) {
        background: rgba(255, 255, 255, 0);
        color: #272727;
    }

    .top-bar-section .dropdown li:not(.has-form):not(.active):hover > a:not(.button) {
        background: rgba(255, 255, 255, 0);
        background-color: #555;
        color: #000;
    }

    .top-bar-section .dropdown li label {
        background: #333;
        white-space: nowrap;
    }

    .top-bar-section .dropdown li .dropdown {
        left: 100%;
        top: 0;
    }

    .top-bar-section > ul > .divider,
    .top-bar-section > ul > [role='separator'] {
        border-bottom: none;
        border-right: solid 1px rgba(255, 255, 255, 0);
        border-top: none;
        clear: none;
        height: 45px;
        width: 0;
    }

    .top-bar-section .has-form {
        background: rgba(255, 255, 255, 0);
        height: 45px;
        padding: 0 15px;
    }

    .top-bar-section .right li .dropdown,
    .top-bar-section .ym-grid .ym-gr > .ym-gbox li .dropdown,
    .ym-grid .top-bar-section .ym-gr > .ym-gbox li .dropdown {
        left: auto;
        right: 0;
    }

    .top-bar-section .right li .dropdown li .dropdown,
    .top-bar-section .ym-grid .ym-gr > .ym-gbox li .dropdown li .dropdown,
    .ym-grid .top-bar-section .ym-gr > .ym-gbox li .dropdown li .dropdown {
        right: 100%;
    }

    .top-bar-section .left li .dropdown {
        left: 0;
        right: auto;
    }

    .top-bar-section .left li .dropdown li .dropdown {
        left: 100%;
    }

    .no-js .top-bar-section ul li:hover > a {
        background: rgba(255, 255, 255, 0);
        /*background-color: #555;*/
        color: #000;
    }

    .no-js .top-bar-section ul li:active > a {
        background: rgba(255, 255, 255, 0);
        color: #000;
    }

    .no-js .top-bar-section .has-dropdown:hover > .dropdown {
        clip: auto;
        display: block;
        height: auto;
        overflow: visible;
        position: static !important;
        position: absolute !important;
        width: auto;
    }

    .no-js .top-bar-section .has-dropdown > a:focus + .dropdown {
        clip: auto;
        display: block;
        height: auto;
        overflow: visible;
        position: static !important;
        position: absolute !important;
        width: auto;
    }
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-justify {
    text-align: justify !important;
}

@media only screen and (max-width: 52em) {
    .small-only-text-left {
        text-align: left !important;
    }

    .small-only-text-right {
        text-align: right !important;
    }

    .small-only-text-center {
        text-align: center !important;
    }

    .small-only-text-justify {
        text-align: justify !important;
    }
}

@media only screen {
    .small-text-left {
        text-align: left !important;
    }

    .small-text-right {
        text-align: right !important;
    }

    .small-text-center {
        text-align: center !important;
    }

    .small-text-justify {
        text-align: justify !important;
    }
}

@media only screen and (min-width: 52.063em) and (max-width: 60em) {
    .medium-only-text-left {
        text-align: left !important;
    }

    .medium-only-text-right {
        text-align: right !important;
    }

    .medium-only-text-center {
        text-align: center !important;
    }

    .medium-only-text-justify {
        text-align: justify !important;
    }
}

@media only screen and (min-width: 52.063em) {
    .medium-text-left {
        text-align: left !important;
    }

    .medium-text-right {
        text-align: right !important;
    }

    .medium-text-center {
        text-align: center !important;
    }

    .medium-text-justify {
        text-align: justify !important;
    }
}

@media only screen and (min-width: 60.063em) and (max-width: 90em) {
    .large-only-text-left {
        text-align: left !important;
    }

    .large-only-text-right {
        text-align: right !important;
    }

    .large-only-text-center {
        text-align: center !important;
    }

    .large-only-text-justify {
        text-align: justify !important;
    }
}

@media only screen and (min-width: 60.063em) {
    .large-text-left {
        text-align: left !important;
    }

    .large-text-right {
        text-align: right !important;
    }

    .large-text-center {
        text-align: center !important;
    }

    .large-text-justify {
        text-align: justify !important;
    }
}

@media only screen and (min-width: 90.063em) and (max-width: 120em) {
    .xlarge-only-text-left {
        text-align: left !important;
    }

    .xlarge-only-text-right {
        text-align: right !important;
    }

    .xlarge-only-text-center {
        text-align: center !important;
    }

    .xlarge-only-text-justify {
        text-align: justify !important;
    }
}

@media only screen and (min-width: 90.063em) {
    .xlarge-text-left {
        text-align: left !important;
    }

    .xlarge-text-right {
        text-align: right !important;
    }

    .xlarge-text-center {
        text-align: center !important;
    }

    .xlarge-text-justify {
        text-align: justify !important;
    }
}

@media only screen and (min-width: 120.063em) and (max-width: 99999999em) {
    .xxlarge-only-text-left {
        text-align: left !important;
    }

    .xxlarge-only-text-right {
        text-align: right !important;
    }

    .xxlarge-only-text-center {
        text-align: center !important;
    }

    .xxlarge-only-text-justify {
        text-align: justify !important;
    }
}

@media only screen and (min-width: 120.063em) {
    .xxlarge-text-left {
        text-align: left !important;
    }

    .xxlarge-text-right {
        text-align: right !important;
    }

    .xxlarge-text-center {
        text-align: center !important;
    }

    .xxlarge-text-justify {
        text-align: justify !important;
    }
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
}

a {
    color: #000;
    line-height: inherit;
    text-decoration: none;
}

a:hover,
a:focus {
    color: #000;
}

a img {
    border: none;
}

p {
    font-family: inherit;
    font-size: 0.88889rem;
    font-weight: normal;
    line-height: 1.6;
    margin-bottom: 1.11111rem;
    text-rendering: optimizeLegibility;
}

p.lead {
    font-size: 1.08333rem;
    line-height: 1.6;
}

p aside {
    font-size: 0.77778rem;
    font-style: italic;
    line-height: 1.35;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #000;
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    margin-top: 0.2rem;
    text-rendering: optimizeLegibility;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    color: #595959;
    font-size: 60%;
    line-height: 0;
}

h1 {
    font-size: 1.66667rem;
}

h2 {
    font-size: 0.88889rem;
}

h3 {
    font-size: 0.88889rem;
}

h4 {
    font-size: 1rem;
}

h5 {
    font-size: 1rem;
}

h6 {
    font-size: 1rem;
}

.subheader {
    color: #595959;
    font-weight: normal;
    line-height: 1.4;
    margin-bottom: 0.5rem;
    margin-top: 0.2rem;
}

hr {
    border: solid #ddd;
    border-width: 1px 0 0;
    clear: both;
    height: 0;
    margin: 1.11111rem 0 1.05556rem;
}

em,
i {
    font-style: italic;
    line-height: inherit;
}

strong,
b {
    font-weight: bold;
    line-height: inherit;
}

small {
    font-size: 60%;
    line-height: inherit;
}

code {
    background-color: #f8f8f8;
    border-color: #dfdfdf;
    border-style: solid;
    border-width: 1px;
    color: #333;
    font-family: Consolas, 'Liberation Mono', Courier, monospace;
    font-weight: normal;
    padding: 0.11111rem 0.27778rem 0.05556rem;
}

ul,
ol,
dl {
    font-family: inherit;
    font-size: 0.88889rem;
    line-height: 1.6;
    list-style-position: outside;
    margin-bottom: 1.11111rem;
}

ul {
    margin-left: 1.1rem;
}

ul.no-bullet {
    margin-left: 0;
}

ul.no-bullet li ul,
ul.no-bullet li ol {
    list-style: none;
    margin-bottom: 0;
    margin-left: 1.11111rem;
}

ul li ul,
ul li ol {
    margin-bottom: 0;
    margin-left: 1.11111rem;
}

ul.square li ul,
ul.circle li ul,
ul.disc li ul {
    list-style: inherit;
}

ul.square {
    list-style-type: square;
    margin-left: 1.1rem;
}

ul.circle {
    list-style-type: circle;
    margin-left: 1.1rem;
}

ul.disc {
    list-style-type: disc;
    margin-left: 1.1rem;
}

ul.no-bullet {
    list-style: none;
}

ol {
    margin-left: 1.3rem;
}

ol li ul,
ol li ol {
    margin-bottom: 0;
    margin-left: 1.11111rem;
}

dl dt {
    font-weight: bold;
    margin-bottom: 0.3rem;
}

dl dd {
    margin-bottom: 0.66667rem;
}

abbr,
acronym {
    color: #272727;
    cursor: help;
    font-size: 90%;
    text-transform: uppercase;
}

abbr {
    text-transform: none;
}

abbr[title] {
    border-bottom: 1px dotted #ddd;
}

blockquote {
    border-left: 1px solid #ddd;
    margin: 0 0 1.11111rem;
    padding: 0.5rem 1.11111rem 0 1.05556rem;
}

blockquote cite {
    color: #3b3b3b;
    display: block;
    font-size: 0.72222rem;
}

blockquote cite::before {
    content: '\2014 \0020';
}

blockquote cite a,
blockquote cite a:visited {
    color: #3b3b3b;
}

blockquote,
blockquote p {
    color: #595959;
    line-height: 1.6;
}

.vcard {
    border: 1px solid #ddd;
    display: inline-block;
    margin: 0 0 1.11111rem;
    padding: 0.55556rem 0.66667rem;
}

.vcard li {
    display: block;
    margin: 0;
}

.vcard .fn {
    font-size: 0.83333rem;
    font-weight: bold;
}

.vevent .summary {
    font-weight: bold;
}

.vevent abbr {
    border: none;
    cursor: default;
    font-weight: bold;
    padding: 0 0.05556rem;
    text-decoration: none;
}

@media only screen and (min-width: 52.063em) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.2;
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 0.88889rem;
    }

    h3 {
        font-size: 0.88889rem;
    }

    h4 {
        font-size: 1.27778rem;
    }

    h5 {
        font-size: 1rem;
    }

    h6 {
        font-size: 1rem;
    }
}

.print-only {
    display: none !important;
}

@media print {
    * {
        background: transparent !important;
        box-shadow: none !important;
        color: #000 !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]::after {
        content: ' (' attr(href) ')';
    }

    abbr[title]::after {
        content: ' (' attr(title) ')';
    }

    .ir a::after,
    a[href^='javascript:']::after,
    a[href^='#']::after {
        content: '';
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 0.5cm;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    .hide-on-print {
        display: none !important;
    }

    .print-only {
        display: block !important;
    }

    .hide-for-print {
        display: none !important;
    }

    .show-for-print {
        display: inherit !important;
    }
}

@media only screen {
    .show-for-small-only,
    .show-for-small-up,
    .show-for-small,
    .show-for-small-down,
    .hide-for-medium-only,
    .hide-for-medium-up,
    .hide-for-medium,
    .show-for-medium-down,
    .hide-for-large-only,
    .hide-for-large-up,
    .hide-for-large,
    .show-for-large-down,
    .hide-for-xlarge-only,
    .hide-for-xlarge-up,
    .hide-for-xxlarge-only,
    .hide-for-xxlarge-up {
        display: inherit !important;
    }

    .hide-for-small-only,
    .hide-for-small-up,
    .hide-for-small,
    .hide-for-small-down,
    .show-for-medium-only,
    .show-for-medium-up,
    .show-for-medium,
    .hide-for-medium-down,
    .show-for-large-only,
    .show-for-large-up,
    .show-for-large,
    .hide-for-large-down,
    .show-for-xlarge-only,
    .show-for-xlarge-up,
    .show-for-xxlarge-only,
    .show-for-xxlarge-up {
        display: none !important;
    }

    .visible-for-small-only,
    .visible-for-small-up,
    .visible-for-small,
    .visible-for-small-down,
    .hidden-for-medium-only,
    .hidden-for-medium-up,
    .hidden-for-medium,
    .visible-for-medium-down,
    .hidden-for-large-only,
    .hidden-for-large-up,
    .hidden-for-large,
    .visible-for-large-down,
    .hidden-for-xlarge-only,
    .hidden-for-xlarge-up,
    .hidden-for-xxlarge-only,
    .hidden-for-xxlarge-up {
        clip: auto;
        height: auto;
        overflow: visible;
        position: static !important;
        width: auto;
    }

    .hidden-for-small-only,
    .hidden-for-small-up,
    .hidden-for-small,
    .hidden-for-small-down,
    .visible-for-medium-only,
    .visible-for-medium-up,
    .visible-for-medium,
    .hidden-for-medium-down,
    .visible-for-large-only,
    .visible-for-large-up,
    .visible-for-large,
    .hidden-for-large-down,
    .visible-for-xlarge-only,
    .visible-for-xlarge-up,
    .visible-for-xxlarge-only,
    .visible-for-xxlarge-up {
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        overflow: hidden;
        position: absolute !important;
        width: 1px;
    }

    table.show-for-small-only,
    table.show-for-small-up,
    table.show-for-small,
    table.show-for-small-down,
    table.hide-for-medium-only,
    table.hide-for-medium-up,
    table.hide-for-medium,
    table.show-for-medium-down,
    table.hide-for-large-only,
    table.hide-for-large-up,
    table.hide-for-large,
    table.show-for-large-down,
    table.hide-for-xlarge-only,
    table.hide-for-xlarge-up,
    table.hide-for-xxlarge-only,
    table.hide-for-xxlarge-up {
        display: table !important;
    }

    thead.show-for-small-only,
    thead.show-for-small-up,
    thead.show-for-small,
    thead.show-for-small-down,
    thead.hide-for-medium-only,
    thead.hide-for-medium-up,
    thead.hide-for-medium,
    thead.show-for-medium-down,
    thead.hide-for-large-only,
    thead.hide-for-large-up,
    thead.hide-for-large,
    thead.show-for-large-down,
    thead.hide-for-xlarge-only,
    thead.hide-for-xlarge-up,
    thead.hide-for-xxlarge-only,
    thead.hide-for-xxlarge-up {
        display: table-header-group !important;
    }

    tbody.show-for-small-only,
    tbody.show-for-small-up,
    tbody.show-for-small,
    tbody.show-for-small-down,
    tbody.hide-for-medium-only,
    tbody.hide-for-medium-up,
    tbody.hide-for-medium,
    tbody.show-for-medium-down,
    tbody.hide-for-large-only,
    tbody.hide-for-large-up,
    tbody.hide-for-large,
    tbody.show-for-large-down,
    tbody.hide-for-xlarge-only,
    tbody.hide-for-xlarge-up,
    tbody.hide-for-xxlarge-only,
    tbody.hide-for-xxlarge-up {
        display: table-row-group !important;
    }

    tr.show-for-small-only,
    tr.show-for-small-up,
    tr.show-for-small,
    tr.show-for-small-down,
    tr.hide-for-medium-only,
    tr.hide-for-medium-up,
    tr.hide-for-medium,
    tr.show-for-medium-down,
    tr.hide-for-large-only,
    tr.hide-for-large-up,
    tr.hide-for-large,
    tr.show-for-large-down,
    tr.hide-for-xlarge-only,
    tr.hide-for-xlarge-up,
    tr.hide-for-xxlarge-only,
    tr.hide-for-xxlarge-up {
        display: table-row !important;
    }

    th.show-for-small-only,
    td.show-for-small-only,
    th.show-for-small-up,
    td.show-for-small-up,
    th.show-for-small,
    td.show-for-small,
    th.show-for-small-down,
    td.show-for-small-down,
    th.hide-for-medium-only,
    td.hide-for-medium-only,
    th.hide-for-medium-up,
    td.hide-for-medium-up,
    th.hide-for-medium,
    td.hide-for-medium,
    th.show-for-medium-down,
    td.show-for-medium-down,
    th.hide-for-large-only,
    td.hide-for-large-only,
    th.hide-for-large-up,
    td.hide-for-large-up,
    th.hide-for-large,
    td.hide-for-large,
    th.show-for-large-down,
    td.show-for-large-down,
    th.hide-for-xlarge-only,
    td.hide-for-xlarge-only,
    th.hide-for-xlarge-up,
    td.hide-for-xlarge-up,
    th.hide-for-xxlarge-only,
    td.hide-for-xxlarge-only,
    th.hide-for-xxlarge-up,
    td.hide-for-xxlarge-up {
        display: table-cell !important;
    }
}

@media only screen and (min-width: 52.063em) {
    .hide-for-small-only,
    .show-for-small-up,
    .hide-for-small,
    .hide-for-small-down,
    .show-for-medium-only,
    .show-for-medium-up,
    .show-for-medium,
    .show-for-medium-down,
    .hide-for-large-only,
    .hide-for-large-up,
    .hide-for-large,
    .show-for-large-down,
    .hide-for-xlarge-only,
    .hide-for-xlarge-up,
    .hide-for-xxlarge-only,
    .hide-for-xxlarge-up {
        display: inherit !important;
    }

    .show-for-small-only,
    .hide-for-small-up,
    .show-for-small,
    .show-for-small-down,
    .hide-for-medium-only,
    .hide-for-medium-up,
    .hide-for-medium,
    .hide-for-medium-down,
    .show-for-large-only,
    .show-for-large-up,
    .show-for-large,
    .hide-for-large-down,
    .show-for-xlarge-only,
    .show-for-xlarge-up,
    .show-for-xxlarge-only,
    .show-for-xxlarge-up {
        display: none !important;
    }

    .hidden-for-small-only,
    .visible-for-small-up,
    .hidden-for-small,
    .hidden-for-small-down,
    .visible-for-medium-only,
    .visible-for-medium-up,
    .visible-for-medium,
    .visible-for-medium-down,
    .hidden-for-large-only,
    .hidden-for-large-up,
    .hidden-for-large,
    .visible-for-large-down,
    .hidden-for-xlarge-only,
    .hidden-for-xlarge-up,
    .hidden-for-xxlarge-only,
    .hidden-for-xxlarge-up {
        clip: auto;
        height: auto;
        overflow: visible;
        position: static !important;
        width: auto;
    }

    .visible-for-small-only,
    .hidden-for-small-up,
    .visible-for-small,
    .visible-for-small-down,
    .hidden-for-medium-only,
    .hidden-for-medium-up,
    .hidden-for-medium,
    .hidden-for-medium-down,
    .visible-for-large-only,
    .visible-for-large-up,
    .visible-for-large,
    .hidden-for-large-down,
    .visible-for-xlarge-only,
    .visible-for-xlarge-up,
    .visible-for-xxlarge-only,
    .visible-for-xxlarge-up {
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        overflow: hidden;
        position: absolute !important;
        width: 1px;
    }

    table.hide-for-small-only,
    table.show-for-small-up,
    table.hide-for-small,
    table.hide-for-small-down,
    table.show-for-medium-only,
    table.show-for-medium-up,
    table.show-for-medium,
    table.show-for-medium-down,
    table.hide-for-large-only,
    table.hide-for-large-up,
    table.hide-for-large,
    table.show-for-large-down,
    table.hide-for-xlarge-only,
    table.hide-for-xlarge-up,
    table.hide-for-xxlarge-only,
    table.hide-for-xxlarge-up {
        display: table !important;
    }

    thead.hide-for-small-only,
    thead.show-for-small-up,
    thead.hide-for-small,
    thead.hide-for-small-down,
    thead.show-for-medium-only,
    thead.show-for-medium-up,
    thead.show-for-medium,
    thead.show-for-medium-down,
    thead.hide-for-large-only,
    thead.hide-for-large-up,
    thead.hide-for-large,
    thead.show-for-large-down,
    thead.hide-for-xlarge-only,
    thead.hide-for-xlarge-up,
    thead.hide-for-xxlarge-only,
    thead.hide-for-xxlarge-up {
        display: table-header-group !important;
    }

    tbody.hide-for-small-only,
    tbody.show-for-small-up,
    tbody.hide-for-small,
    tbody.hide-for-small-down,
    tbody.show-for-medium-only,
    tbody.show-for-medium-up,
    tbody.show-for-medium,
    tbody.show-for-medium-down,
    tbody.hide-for-large-only,
    tbody.hide-for-large-up,
    tbody.hide-for-large,
    tbody.show-for-large-down,
    tbody.hide-for-xlarge-only,
    tbody.hide-for-xlarge-up,
    tbody.hide-for-xxlarge-only,
    tbody.hide-for-xxlarge-up {
        display: table-row-group !important;
    }

    tr.hide-for-small-only,
    tr.show-for-small-up,
    tr.hide-for-small,
    tr.hide-for-small-down,
    tr.show-for-medium-only,
    tr.show-for-medium-up,
    tr.show-for-medium,
    tr.show-for-medium-down,
    tr.hide-for-large-only,
    tr.hide-for-large-up,
    tr.hide-for-large,
    tr.show-for-large-down,
    tr.hide-for-xlarge-only,
    tr.hide-for-xlarge-up,
    tr.hide-for-xxlarge-only,
    tr.hide-for-xxlarge-up {
        display: table-row !important;
    }

    th.hide-for-small-only,
    td.hide-for-small-only,
    th.show-for-small-up,
    td.show-for-small-up,
    th.hide-for-small,
    td.hide-for-small,
    th.hide-for-small-down,
    td.hide-for-small-down,
    th.show-for-medium-only,
    td.show-for-medium-only,
    th.show-for-medium-up,
    td.show-for-medium-up,
    th.show-for-medium,
    td.show-for-medium,
    th.show-for-medium-down,
    td.show-for-medium-down,
    th.hide-for-large-only,
    td.hide-for-large-only,
    th.hide-for-large-up,
    td.hide-for-large-up,
    th.hide-for-large,
    td.hide-for-large,
    th.show-for-large-down,
    td.show-for-large-down,
    th.hide-for-xlarge-only,
    td.hide-for-xlarge-only,
    th.hide-for-xlarge-up,
    td.hide-for-xlarge-up,
    th.hide-for-xxlarge-only,
    td.hide-for-xxlarge-only,
    th.hide-for-xxlarge-up,
    td.hide-for-xxlarge-up {
        display: table-cell !important;
    }
}

@media only screen and (min-width: 60.063em) {
    .hide-for-small-only,
    .show-for-small-up,
    .hide-for-small,
    .hide-for-small-down,
    .hide-for-medium-only,
    .show-for-medium-up,
    .hide-for-medium,
    .hide-for-medium-down,
    .show-for-large-only,
    .show-for-large-up,
    .show-for-large,
    .show-for-large-down,
    .hide-for-xlarge-only,
    .hide-for-xlarge-up,
    .hide-for-xxlarge-only,
    .hide-for-xxlarge-up {
        display: inherit !important;
    }

    .show-for-small-only,
    .hide-for-small-up,
    .show-for-small,
    .show-for-small-down,
    .show-for-medium-only,
    .hide-for-medium-up,
    .show-for-medium,
    .show-for-medium-down,
    .hide-for-large-only,
    .hide-for-large-up,
    .hide-for-large,
    .hide-for-large-down,
    .show-for-xlarge-only,
    .show-for-xlarge-up,
    .show-for-xxlarge-only,
    .show-for-xxlarge-up {
        display: none !important;
    }

    .hidden-for-small-only,
    .visible-for-small-up,
    .hidden-for-small,
    .hidden-for-small-down,
    .hidden-for-medium-only,
    .visible-for-medium-up,
    .hidden-for-medium,
    .hidden-for-medium-down,
    .visible-for-large-only,
    .visible-for-large-up,
    .visible-for-large,
    .visible-for-large-down,
    .hidden-for-xlarge-only,
    .hidden-for-xlarge-up,
    .hidden-for-xxlarge-only,
    .hidden-for-xxlarge-up {
        clip: auto;
        height: auto;
        overflow: visible;
        position: static !important;
        width: auto;
    }

    .visible-for-small-only,
    .hidden-for-small-up,
    .visible-for-small,
    .visible-for-small-down,
    .visible-for-medium-only,
    .hidden-for-medium-up,
    .visible-for-medium,
    .visible-for-medium-down,
    .hidden-for-large-only,
    .hidden-for-large-up,
    .hidden-for-large,
    .hidden-for-large-down,
    .visible-for-xlarge-only,
    .visible-for-xlarge-up,
    .visible-for-xxlarge-only,
    .visible-for-xxlarge-up {
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        overflow: hidden;
        position: absolute !important;
        width: 1px;
    }

    table.hide-for-small-only,
    table.show-for-small-up,
    table.hide-for-small,
    table.hide-for-small-down,
    table.hide-for-medium-only,
    table.show-for-medium-up,
    table.hide-for-medium,
    table.hide-for-medium-down,
    table.show-for-large-only,
    table.show-for-large-up,
    table.show-for-large,
    table.show-for-large-down,
    table.hide-for-xlarge-only,
    table.hide-for-xlarge-up,
    table.hide-for-xxlarge-only,
    table.hide-for-xxlarge-up {
        display: table !important;
    }

    thead.hide-for-small-only,
    thead.show-for-small-up,
    thead.hide-for-small,
    thead.hide-for-small-down,
    thead.hide-for-medium-only,
    thead.show-for-medium-up,
    thead.hide-for-medium,
    thead.hide-for-medium-down,
    thead.show-for-large-only,
    thead.show-for-large-up,
    thead.show-for-large,
    thead.show-for-large-down,
    thead.hide-for-xlarge-only,
    thead.hide-for-xlarge-up,
    thead.hide-for-xxlarge-only,
    thead.hide-for-xxlarge-up {
        display: table-header-group !important;
    }

    tbody.hide-for-small-only,
    tbody.show-for-small-up,
    tbody.hide-for-small,
    tbody.hide-for-small-down,
    tbody.hide-for-medium-only,
    tbody.show-for-medium-up,
    tbody.hide-for-medium,
    tbody.hide-for-medium-down,
    tbody.show-for-large-only,
    tbody.show-for-large-up,
    tbody.show-for-large,
    tbody.show-for-large-down,
    tbody.hide-for-xlarge-only,
    tbody.hide-for-xlarge-up,
    tbody.hide-for-xxlarge-only,
    tbody.hide-for-xxlarge-up {
        display: table-row-group !important;
    }

    tr.hide-for-small-only,
    tr.show-for-small-up,
    tr.hide-for-small,
    tr.hide-for-small-down,
    tr.hide-for-medium-only,
    tr.show-for-medium-up,
    tr.hide-for-medium,
    tr.hide-for-medium-down,
    tr.show-for-large-only,
    tr.show-for-large-up,
    tr.show-for-large,
    tr.show-for-large-down,
    tr.hide-for-xlarge-only,
    tr.hide-for-xlarge-up,
    tr.hide-for-xxlarge-only,
    tr.hide-for-xxlarge-up {
        display: table-row !important;
    }

    th.hide-for-small-only,
    td.hide-for-small-only,
    th.show-for-small-up,
    td.show-for-small-up,
    th.hide-for-small,
    td.hide-for-small,
    th.hide-for-small-down,
    td.hide-for-small-down,
    th.hide-for-medium-only,
    td.hide-for-medium-only,
    th.show-for-medium-up,
    td.show-for-medium-up,
    th.hide-for-medium,
    td.hide-for-medium,
    th.hide-for-medium-down,
    td.hide-for-medium-down,
    th.show-for-large-only,
    td.show-for-large-only,
    th.show-for-large-up,
    td.show-for-large-up,
    th.show-for-large,
    td.show-for-large,
    th.show-for-large-down,
    td.show-for-large-down,
    th.hide-for-xlarge-only,
    td.hide-for-xlarge-only,
    th.hide-for-xlarge-up,
    td.hide-for-xlarge-up,
    th.hide-for-xxlarge-only,
    td.hide-for-xxlarge-only,
    th.hide-for-xxlarge-up,
    td.hide-for-xxlarge-up {
        display: table-cell !important;
    }
}

@media only screen and (min-width: 90.063em) {
    .hide-for-small-only,
    .show-for-small-up,
    .hide-for-small,
    .hide-for-small-down,
    .hide-for-medium-only,
    .show-for-medium-up,
    .hide-for-medium,
    .hide-for-medium-down,
    .hide-for-large-only,
    .show-for-large-up,
    .hide-for-large,
    .hide-for-large-down,
    .show-for-xlarge-only,
    .show-for-xlarge-up,
    .hide-for-xxlarge-only,
    .hide-for-xxlarge-up {
        display: inherit !important;
    }

    .show-for-small-only,
    .hide-for-small-up,
    .show-for-small,
    .show-for-small-down,
    .show-for-medium-only,
    .hide-for-medium-up,
    .show-for-medium,
    .show-for-medium-down,
    .show-for-large-only,
    .hide-for-large-up,
    .show-for-large,
    .show-for-large-down,
    .hide-for-xlarge-only,
    .hide-for-xlarge-up,
    .show-for-xxlarge-only,
    .show-for-xxlarge-up {
        display: none !important;
    }

    .hidden-for-small-only,
    .visible-for-small-up,
    .hidden-for-small,
    .hidden-for-small-down,
    .hidden-for-medium-only,
    .visible-for-medium-up,
    .hidden-for-medium,
    .hidden-for-medium-down,
    .hidden-for-large-only,
    .visible-for-large-up,
    .hidden-for-large,
    .hidden-for-large-down,
    .visible-for-xlarge-only,
    .visible-for-xlarge-up,
    .hidden-for-xxlarge-only,
    .hidden-for-xxlarge-up {
        clip: auto;
        height: auto;
        overflow: visible;
        position: static !important;
        width: auto;
    }

    .visible-for-small-only,
    .hidden-for-small-up,
    .visible-for-small,
    .visible-for-small-down,
    .visible-for-medium-only,
    .hidden-for-medium-up,
    .visible-for-medium,
    .visible-for-medium-down,
    .visible-for-large-only,
    .hidden-for-large-up,
    .visible-for-large,
    .visible-for-large-down,
    .hidden-for-xlarge-only,
    .hidden-for-xlarge-up,
    .visible-for-xxlarge-only,
    .visible-for-xxlarge-up {
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        overflow: hidden;
        position: absolute !important;
        width: 1px;
    }

    table.hide-for-small-only,
    table.show-for-small-up,
    table.hide-for-small,
    table.hide-for-small-down,
    table.hide-for-medium-only,
    table.show-for-medium-up,
    table.hide-for-medium,
    table.hide-for-medium-down,
    table.hide-for-large-only,
    table.show-for-large-up,
    table.hide-for-large,
    table.hide-for-large-down,
    table.show-for-xlarge-only,
    table.show-for-xlarge-up,
    table.hide-for-xxlarge-only,
    table.hide-for-xxlarge-up {
        display: table !important;
    }

    thead.hide-for-small-only,
    thead.show-for-small-up,
    thead.hide-for-small,
    thead.hide-for-small-down,
    thead.hide-for-medium-only,
    thead.show-for-medium-up,
    thead.hide-for-medium,
    thead.hide-for-medium-down,
    thead.hide-for-large-only,
    thead.show-for-large-up,
    thead.hide-for-large,
    thead.hide-for-large-down,
    thead.show-for-xlarge-only,
    thead.show-for-xlarge-up,
    thead.hide-for-xxlarge-only,
    thead.hide-for-xxlarge-up {
        display: table-header-group !important;
    }

    tbody.hide-for-small-only,
    tbody.show-for-small-up,
    tbody.hide-for-small,
    tbody.hide-for-small-down,
    tbody.hide-for-medium-only,
    tbody.show-for-medium-up,
    tbody.hide-for-medium,
    tbody.hide-for-medium-down,
    tbody.hide-for-large-only,
    tbody.show-for-large-up,
    tbody.hide-for-large,
    tbody.hide-for-large-down,
    tbody.show-for-xlarge-only,
    tbody.show-for-xlarge-up,
    tbody.hide-for-xxlarge-only,
    tbody.hide-for-xxlarge-up {
        display: table-row-group !important;
    }

    tr.hide-for-small-only,
    tr.show-for-small-up,
    tr.hide-for-small,
    tr.hide-for-small-down,
    tr.hide-for-medium-only,
    tr.show-for-medium-up,
    tr.hide-for-medium,
    tr.hide-for-medium-down,
    tr.hide-for-large-only,
    tr.show-for-large-up,
    tr.hide-for-large,
    tr.hide-for-large-down,
    tr.show-for-xlarge-only,
    tr.show-for-xlarge-up,
    tr.hide-for-xxlarge-only,
    tr.hide-for-xxlarge-up {
        display: table-row !important;
    }

    th.hide-for-small-only,
    td.hide-for-small-only,
    th.show-for-small-up,
    td.show-for-small-up,
    th.hide-for-small,
    td.hide-for-small,
    th.hide-for-small-down,
    td.hide-for-small-down,
    th.hide-for-medium-only,
    td.hide-for-medium-only,
    th.show-for-medium-up,
    td.show-for-medium-up,
    th.hide-for-medium,
    td.hide-for-medium,
    th.hide-for-medium-down,
    td.hide-for-medium-down,
    th.hide-for-large-only,
    td.hide-for-large-only,
    th.show-for-large-up,
    td.show-for-large-up,
    th.hide-for-large,
    td.hide-for-large,
    th.hide-for-large-down,
    td.hide-for-large-down,
    th.show-for-xlarge-only,
    td.show-for-xlarge-only,
    th.show-for-xlarge-up,
    td.show-for-xlarge-up,
    th.hide-for-xxlarge-only,
    td.hide-for-xxlarge-only,
    th.hide-for-xxlarge-up,
    td.hide-for-xxlarge-up {
        display: table-cell !important;
    }
}

@media only screen and (min-width: 120.063em) {
    .hide-for-small-only,
    .show-for-small-up,
    .hide-for-small,
    .hide-for-small-down,
    .hide-for-medium-only,
    .show-for-medium-up,
    .hide-for-medium,
    .hide-for-medium-down,
    .hide-for-large-only,
    .show-for-large-up,
    .hide-for-large,
    .hide-for-large-down,
    .hide-for-xlarge-only,
    .show-for-xlarge-up,
    .show-for-xxlarge-only,
    .show-for-xxlarge-up {
        display: inherit !important;
    }

    .show-for-small-only,
    .hide-for-small-up,
    .show-for-small,
    .show-for-small-down,
    .show-for-medium-only,
    .hide-for-medium-up,
    .show-for-medium,
    .show-for-medium-down,
    .show-for-large-only,
    .hide-for-large-up,
    .show-for-large,
    .show-for-large-down,
    .show-for-xlarge-only,
    .hide-for-xlarge-up,
    .hide-for-xxlarge-only,
    .hide-for-xxlarge-up {
        display: none !important;
    }

    .hidden-for-small-only,
    .visible-for-small-up,
    .hidden-for-small,
    .hidden-for-small-down,
    .hidden-for-medium-only,
    .visible-for-medium-up,
    .hidden-for-medium,
    .hidden-for-medium-down,
    .hidden-for-large-only,
    .visible-for-large-up,
    .hidden-for-large,
    .hidden-for-large-down,
    .hidden-for-xlarge-only,
    .visible-for-xlarge-up,
    .visible-for-xxlarge-only,
    .visible-for-xxlarge-up {
        clip: auto;
        height: auto;
        overflow: visible;
        position: static !important;
        width: auto;
    }

    .visible-for-small-only,
    .hidden-for-small-up,
    .visible-for-small,
    .visible-for-small-down,
    .visible-for-medium-only,
    .hidden-for-medium-up,
    .visible-for-medium,
    .visible-for-medium-down,
    .visible-for-large-only,
    .hidden-for-large-up,
    .visible-for-large,
    .visible-for-large-down,
    .visible-for-xlarge-only,
    .hidden-for-xlarge-up,
    .hidden-for-xxlarge-only,
    .hidden-for-xxlarge-up {
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        overflow: hidden;
        position: absolute !important;
        width: 1px;
    }

    table.hide-for-small-only,
    table.show-for-small-up,
    table.hide-for-small,
    table.hide-for-small-down,
    table.hide-for-medium-only,
    table.show-for-medium-up,
    table.hide-for-medium,
    table.hide-for-medium-down,
    table.hide-for-large-only,
    table.show-for-large-up,
    table.hide-for-large,
    table.hide-for-large-down,
    table.hide-for-xlarge-only,
    table.show-for-xlarge-up,
    table.show-for-xxlarge-only,
    table.show-for-xxlarge-up {
        display: table !important;
    }

    thead.hide-for-small-only,
    thead.show-for-small-up,
    thead.hide-for-small,
    thead.hide-for-small-down,
    thead.hide-for-medium-only,
    thead.show-for-medium-up,
    thead.hide-for-medium,
    thead.hide-for-medium-down,
    thead.hide-for-large-only,
    thead.show-for-large-up,
    thead.hide-for-large,
    thead.hide-for-large-down,
    thead.hide-for-xlarge-only,
    thead.show-for-xlarge-up,
    thead.show-for-xxlarge-only,
    thead.show-for-xxlarge-up {
        display: table-header-group !important;
    }

    tbody.hide-for-small-only,
    tbody.show-for-small-up,
    tbody.hide-for-small,
    tbody.hide-for-small-down,
    tbody.hide-for-medium-only,
    tbody.show-for-medium-up,
    tbody.hide-for-medium,
    tbody.hide-for-medium-down,
    tbody.hide-for-large-only,
    tbody.show-for-large-up,
    tbody.hide-for-large,
    tbody.hide-for-large-down,
    tbody.hide-for-xlarge-only,
    tbody.show-for-xlarge-up,
    tbody.show-for-xxlarge-only,
    tbody.show-for-xxlarge-up {
        display: table-row-group !important;
    }

    tr.hide-for-small-only,
    tr.show-for-small-up,
    tr.hide-for-small,
    tr.hide-for-small-down,
    tr.hide-for-medium-only,
    tr.show-for-medium-up,
    tr.hide-for-medium,
    tr.hide-for-medium-down,
    tr.hide-for-large-only,
    tr.show-for-large-up,
    tr.hide-for-large,
    tr.hide-for-large-down,
    tr.hide-for-xlarge-only,
    tr.show-for-xlarge-up,
    tr.show-for-xxlarge-only,
    tr.show-for-xxlarge-up {
        display: table-row !important;
    }

    th.hide-for-small-only,
    td.hide-for-small-only,
    th.show-for-small-up,
    td.show-for-small-up,
    th.hide-for-small,
    td.hide-for-small,
    th.hide-for-small-down,
    td.hide-for-small-down,
    th.hide-for-medium-only,
    td.hide-for-medium-only,
    th.show-for-medium-up,
    td.show-for-medium-up,
    th.hide-for-medium,
    td.hide-for-medium,
    th.hide-for-medium-down,
    td.hide-for-medium-down,
    th.hide-for-large-only,
    td.hide-for-large-only,
    th.show-for-large-up,
    td.show-for-large-up,
    th.hide-for-large,
    td.hide-for-large,
    th.hide-for-large-down,
    td.hide-for-large-down,
    th.hide-for-xlarge-only,
    td.hide-for-xlarge-only,
    th.show-for-xlarge-up,
    td.show-for-xlarge-up,
    th.show-for-xxlarge-only,
    td.show-for-xxlarge-only,
    th.show-for-xxlarge-up,
    td.show-for-xxlarge-up {
        display: table-cell !important;
    }
}

.show-for-landscape,
.hide-for-portrait {
    display: inherit !important;
}

.hide-for-landscape,
.show-for-portrait {
    display: none !important;
}

table.hide-for-landscape,
table.show-for-portrait {
    display: table !important;
}

thead.hide-for-landscape,
thead.show-for-portrait {
    display: table-header-group !important;
}

tbody.hide-for-landscape,
tbody.show-for-portrait {
    display: table-row-group !important;
}

tr.hide-for-landscape,
tr.show-for-portrait {
    display: table-row !important;
}

td.hide-for-landscape,
td.show-for-portrait,
th.hide-for-landscape,
th.show-for-portrait {
    display: table-cell !important;
}

@media only screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
        display: inherit !important;
    }

    .hide-for-landscape,
    .show-for-portrait {
        display: none !important;
    }

    table.show-for-landscape,
    table.hide-for-portrait {
        display: table !important;
    }

    thead.show-for-landscape,
    thead.hide-for-portrait {
        display: table-header-group !important;
    }

    tbody.show-for-landscape,
    tbody.hide-for-portrait {
        display: table-row-group !important;
    }

    tr.show-for-landscape,
    tr.hide-for-portrait {
        display: table-row !important;
    }

    td.show-for-landscape,
    td.hide-for-portrait,
    th.show-for-landscape,
    th.hide-for-portrait {
        display: table-cell !important;
    }
}

@media only screen and (orientation: portrait) {
    .show-for-portrait,
    .hide-for-landscape {
        display: inherit !important;
    }

    .hide-for-portrait,
    .show-for-landscape {
        display: none !important;
    }

    table.show-for-portrait,
    table.hide-for-landscape {
        display: table !important;
    }

    thead.show-for-portrait,
    thead.hide-for-landscape {
        display: table-header-group !important;
    }

    tbody.show-for-portrait,
    tbody.hide-for-landscape {
        display: table-row-group !important;
    }

    tr.show-for-portrait,
    tr.hide-for-landscape {
        display: table-row !important;
    }

    td.show-for-portrait,
    td.hide-for-landscape,
    th.show-for-portrait,
    th.hide-for-landscape {
        display: table-cell !important;
    }
}

.show-for-touch {
    display: none !important;
}

.hide-for-touch {
    display: inherit !important;
}

.touch .show-for-touch {
    display: inherit !important;
}

.touch .hide-for-touch {
    display: none !important;
}

table.hide-for-touch {
    display: table !important;
}

.touch table.show-for-touch {
    display: table !important;
}

thead.hide-for-touch {
    display: table-header-group !important;
}

.touch thead.show-for-touch {
    display: table-header-group !important;
}

tbody.hide-for-touch {
    display: table-row-group !important;
}

.touch tbody.show-for-touch {
    display: table-row-group !important;
}

tr.hide-for-touch {
    display: table-row !important;
}

.touch tr.show-for-touch {
    display: table-row !important;
}

td.hide-for-touch {
    display: table-cell !important;
}

.touch td.show-for-touch {
    display: table-cell !important;
}

th.hide-for-touch {
    display: table-cell !important;
}

.touch th.show-for-touch {
    display: table-cell !important;
}

@media print {
    .show-for-print {
        display: block;
    }

    .hide-for-print {
        display: none;
    }

    table.show-for-print {
        display: table !important;
    }

    thead.show-for-print {
        display: table-header-group !important;
    }

    tbody.show-for-print {
        display: table-row-group !important;
    }

    tr.show-for-print {
        display: table-row !important;
    }

    td.show-for-print {
        display: table-cell !important;
    }

    th.show-for-print {
        display: table-cell !important;
    }
}

.side-nav {
    display: block;
    font-family: 'Open Sans', Arial, sans-serif;
    list-style-position: outside;
    list-style-type: none;
    margin: 0;
    padding: 0.77778rem 0;
}

.side-nav li {
    font-size: 0.88889rem;
    font-weight: normal;
    margin: 0 0 0.38889rem;
}

.side-nav li a:not(.button) {
    color: #272727;
    display: block;
    margin: 0;
    padding: 0.38889rem 0.77778rem;
}

.side-nav li a:not(.button):hover,
.side-nav li a:not(.button):focus {
    background: rgba(0, 0, 0, 0.025);
    color: #686868;
}

.side-nav li.active > a:first-child:not(.button) {
    color: #ffce1f;
    font-family: 'Open Sans', Arial, sans-serif;
    font-weight: normal;
}

.side-nav li.divider {
    border-top: 1px solid;
    border-top-color: #fff;
    height: 0;
    list-style: none;
    padding: 0;
}

.side-nav li.heading {
    color: #272727;
    font-size: 0.88889rem;
    font-weight: bold;
    text-transform: uppercase;
}

.panel {
    background: #f2f2f2;
    border-color: #d8d8d8;
    border-style: solid;
    border-width: 1px;
    color: #333;
    margin-bottom: 1.11111rem;
    padding: 1.11111rem;
}

.panel > :first-child {
    margin-top: 0;
}

.panel > :last-child {
    margin-bottom: 0;
}

.panel h1,
.panel h2,
.panel h3,
.panel h4,
.panel h5,
.panel h6,
.panel p,
.panel li,
.panel dl {
    color: #333;
}

.panel h1,
.panel h2,
.panel h3,
.panel h4,
.panel h5,
.panel h6 {
    line-height: 1;
    margin-bottom: 0.55556rem;
}

.panel h1.subheader,
.panel h2.subheader,
.panel h3.subheader,
.panel h4.subheader,
.panel h5.subheader,
.panel h6.subheader {
    line-height: 1.4;
}

.panel.callout {
    background: #f0f0f0;
    border-color: #d5d5d5;
    border-style: solid;
    border-width: 1px;
    color: #333;
    margin-bottom: 1.11111rem;
    padding: 1.11111rem;
}

.panel.callout > :first-child {
    margin-top: 0;
}

.panel.callout > :last-child {
    margin-bottom: 0;
}

.panel.callout h1,
.panel.callout h2,
.panel.callout h3,
.panel.callout h4,
.panel.callout h5,
.panel.callout h6,
.panel.callout p,
.panel.callout li,
.panel.callout dl {
    color: #333;
}

.panel.callout h1,
.panel.callout h2,
.panel.callout h3,
.panel.callout h4,
.panel.callout h5,
.panel.callout h6 {
    line-height: 1;
    margin-bottom: 0.55556rem;
}

.panel.callout h1.subheader,
.panel.callout h2.subheader,
.panel.callout h3.subheader,
.panel.callout h4.subheader,
.panel.callout h5.subheader,
.panel.callout h6.subheader {
    line-height: 1.4;
}

.panel.callout a:not(.button) {
    color: #000;
}

.panel.radius {
    border-radius: 3px;
}

[class*='block-grid-'] {
    display: block;
    margin: 0 -0.27778rem;
    padding: 0;
}

[class*='block-grid-']::before,
[class*='block-grid-']::after {
    content: ' ';
    display: table;
}

[class*='block-grid-']::after {
    clear: both;
}

[class*='block-grid-'] > li {
    display: block;
    float: left;
    height: auto;
    padding: 0 0.27778rem 0.55556rem;
}

@media only screen {
    .small-block-grid-1 > li {
        list-style: none;
        width: 100%;
    }

    .small-block-grid-1 > li:nth-of-type(1n) {
        clear: none;
    }

    .small-block-grid-1 > li:nth-of-type(1n+1) {
        clear: both;
    }

    .small-block-grid-2 > li {
        list-style: none;
        width: 50%;
    }

    .small-block-grid-2 > li:nth-of-type(1n) {
        clear: none;
    }

    .small-block-grid-2 > li:nth-of-type(2n+1) {
        clear: both;
    }

    .small-block-grid-3 > li {
        list-style: none;
        width: 33.33333%;
    }

    .small-block-grid-3 > li:nth-of-type(1n) {
        clear: none;
    }

    .small-block-grid-3 > li:nth-of-type(3n+1) {
        clear: both;
    }

    .small-block-grid-4 > li {
        list-style: none;
        width: 25%;
    }

    .small-block-grid-4 > li:nth-of-type(1n) {
        clear: none;
    }

    .small-block-grid-4 > li:nth-of-type(4n+1) {
        clear: both;
    }

    .small-block-grid-5 > li {
        list-style: none;
        width: 20%;
    }

    .small-block-grid-5 > li:nth-of-type(1n) {
        clear: none;
    }

    .small-block-grid-5 > li:nth-of-type(5n+1) {
        clear: both;
    }

    .small-block-grid-6 > li {
        list-style: none;
        width: 16.66667%;
    }

    .small-block-grid-6 > li:nth-of-type(1n) {
        clear: none;
    }

    .small-block-grid-6 > li:nth-of-type(6n+1) {
        clear: both;
    }

    .small-block-grid-7 > li {
        list-style: none;
        width: 14.28571%;
    }

    .small-block-grid-7 > li:nth-of-type(1n) {
        clear: none;
    }

    .small-block-grid-7 > li:nth-of-type(7n+1) {
        clear: both;
    }

    .small-block-grid-8 > li {
        list-style: none;
        width: 12.5%;
    }

    .small-block-grid-8 > li:nth-of-type(1n) {
        clear: none;
    }

    .small-block-grid-8 > li:nth-of-type(8n+1) {
        clear: both;
    }

    .small-block-grid-9 > li {
        list-style: none;
        width: 11.11111%;
    }

    .small-block-grid-9 > li:nth-of-type(1n) {
        clear: none;
    }

    .small-block-grid-9 > li:nth-of-type(9n+1) {
        clear: both;
    }

    .small-block-grid-10 > li {
        list-style: none;
        width: 10%;
    }

    .small-block-grid-10 > li:nth-of-type(1n) {
        clear: none;
    }

    .small-block-grid-10 > li:nth-of-type(10n+1) {
        clear: both;
    }

    .small-block-grid-11 > li {
        list-style: none;
        width: 9.09091%;
    }

    .small-block-grid-11 > li:nth-of-type(1n) {
        clear: none;
    }

    .small-block-grid-11 > li:nth-of-type(11n+1) {
        clear: both;
    }

    .small-block-grid-12 > li {
        list-style: none;
        width: 8.33333%;
    }

    .small-block-grid-12 > li:nth-of-type(1n) {
        clear: none;
    }

    .small-block-grid-12 > li:nth-of-type(12n+1) {
        clear: both;
    }
}

@media only screen and (min-width: 52.063em) {
    .medium-block-grid-1 > li {
        list-style: none;
        width: 100%;
    }

    .medium-block-grid-1 > li:nth-of-type(1n) {
        clear: none;
    }

    .medium-block-grid-1 > li:nth-of-type(1n+1) {
        clear: both;
    }

    .medium-block-grid-2 > li {
        list-style: none;
        width: 50%;
    }

    .medium-block-grid-2 > li:nth-of-type(1n) {
        clear: none;
    }

    .medium-block-grid-2 > li:nth-of-type(2n+1) {
        clear: both;
    }

    .medium-block-grid-3 > li {
        list-style: none;
        width: 33.33333%;
    }

    .medium-block-grid-3 > li:nth-of-type(1n) {
        clear: none;
    }

    .medium-block-grid-3 > li:nth-of-type(3n+1) {
        clear: both;
    }

    .medium-block-grid-4 > li {
        list-style: none;
        width: 25%;
    }

    .medium-block-grid-4 > li:nth-of-type(1n) {
        clear: none;
    }

    .medium-block-grid-4 > li:nth-of-type(4n+1) {
        clear: both;
    }

    .medium-block-grid-5 > li {
        list-style: none;
        width: 20%;
    }

    .medium-block-grid-5 > li:nth-of-type(1n) {
        clear: none;
    }

    .medium-block-grid-5 > li:nth-of-type(5n+1) {
        clear: both;
    }

    .medium-block-grid-6 > li {
        list-style: none;
        width: 16.66667%;
    }

    .medium-block-grid-6 > li:nth-of-type(1n) {
        clear: none;
    }

    .medium-block-grid-6 > li:nth-of-type(6n+1) {
        clear: both;
    }

    .medium-block-grid-7 > li {
        list-style: none;
        width: 14.28571%;
    }

    .medium-block-grid-7 > li:nth-of-type(1n) {
        clear: none;
    }

    .medium-block-grid-7 > li:nth-of-type(7n+1) {
        clear: both;
    }

    .medium-block-grid-8 > li {
        list-style: none;
        width: 12.5%;
    }

    .medium-block-grid-8 > li:nth-of-type(1n) {
        clear: none;
    }

    .medium-block-grid-8 > li:nth-of-type(8n+1) {
        clear: both;
    }

    .medium-block-grid-9 > li {
        list-style: none;
        width: 11.11111%;
    }

    .medium-block-grid-9 > li:nth-of-type(1n) {
        clear: none;
    }

    .medium-block-grid-9 > li:nth-of-type(9n+1) {
        clear: both;
    }

    .medium-block-grid-10 > li {
        list-style: none;
        width: 10%;
    }

    .medium-block-grid-10 > li:nth-of-type(1n) {
        clear: none;
    }

    .medium-block-grid-10 > li:nth-of-type(10n+1) {
        clear: both;
    }

    .medium-block-grid-11 > li {
        list-style: none;
        width: 9.09091%;
    }

    .medium-block-grid-11 > li:nth-of-type(1n) {
        clear: none;
    }

    .medium-block-grid-11 > li:nth-of-type(11n+1) {
        clear: both;
    }

    .medium-block-grid-12 > li {
        list-style: none;
        width: 8.33333%;
    }

    .medium-block-grid-12 > li:nth-of-type(1n) {
        clear: none;
    }

    .medium-block-grid-12 > li:nth-of-type(12n+1) {
        clear: both;
    }
}

@media only screen and (min-width: 60.063em) {
    .large-block-grid-1 > li {
        list-style: none;
        width: 100%;
    }

    .large-block-grid-1 > li:nth-of-type(1n) {
        clear: none;
    }

    .large-block-grid-1 > li:nth-of-type(1n+1) {
        clear: both;
    }

    .large-block-grid-2 > li {
        list-style: none;
        width: 50%;
    }

    .large-block-grid-2 > li:nth-of-type(1n) {
        clear: none;
    }

    .large-block-grid-2 > li:nth-of-type(2n+1) {
        clear: both;
    }

    .large-block-grid-3 > li {
        list-style: none;
        width: 33.33333%;
    }

    .large-block-grid-3 > li:nth-of-type(1n) {
        clear: none;
    }

    .large-block-grid-3 > li:nth-of-type(3n+1) {
        clear: both;
    }

    .large-block-grid-4 > li {
        list-style: none;
        width: 25%;
    }

    .large-block-grid-4 > li:nth-of-type(1n) {
        clear: none;
    }

    .large-block-grid-4 > li:nth-of-type(4n+1) {
        clear: both;
    }

    .large-block-grid-5 > li {
        list-style: none;
        width: 20%;
    }

    .large-block-grid-5 > li:nth-of-type(1n) {
        clear: none;
    }

    .large-block-grid-5 > li:nth-of-type(5n+1) {
        clear: both;
    }

    .large-block-grid-6 > li {
        list-style: none;
        width: 16.66667%;
    }

    .large-block-grid-6 > li:nth-of-type(1n) {
        clear: none;
    }

    .large-block-grid-6 > li:nth-of-type(6n+1) {
        clear: both;
    }

    .large-block-grid-7 > li {
        list-style: none;
        width: 14.28571%;
    }

    .large-block-grid-7 > li:nth-of-type(1n) {
        clear: none;
    }

    .large-block-grid-7 > li:nth-of-type(7n+1) {
        clear: both;
    }

    .large-block-grid-8 > li {
        list-style: none;
        width: 12.5%;
    }

    .large-block-grid-8 > li:nth-of-type(1n) {
        clear: none;
    }

    .large-block-grid-8 > li:nth-of-type(8n+1) {
        clear: both;
    }

    .large-block-grid-9 > li {
        list-style: none;
        width: 11.11111%;
    }

    .large-block-grid-9 > li:nth-of-type(1n) {
        clear: none;
    }

    .large-block-grid-9 > li:nth-of-type(9n+1) {
        clear: both;
    }

    .large-block-grid-10 > li {
        list-style: none;
        width: 10%;
    }

    .large-block-grid-10 > li:nth-of-type(1n) {
        clear: none;
    }

    .large-block-grid-10 > li:nth-of-type(10n+1) {
        clear: both;
    }

    .large-block-grid-11 > li {
        list-style: none;
        width: 9.09091%;
    }

    .large-block-grid-11 > li:nth-of-type(1n) {
        clear: none;
    }

    .large-block-grid-11 > li:nth-of-type(11n+1) {
        clear: both;
    }

    .large-block-grid-12 > li {
        list-style: none;
        width: 8.33333%;
    }

    .large-block-grid-12 > li:nth-of-type(1n) {
        clear: none;
    }

    .large-block-grid-12 > li:nth-of-type(12n+1) {
        clear: both;
    }
}

.top-bar-section ul li > a {
    text-transform: uppercase;
}

.top-bar-section ul li.active > a {
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-weight: normal;
}

@media only screen and (max-width: 52em) {
    nav.top-bar .name h1 {
        font-size: 0.72222rem;
    }

    nav.top-bar .name h1 a {
        color: white;
    }

    nav.top-bar,
    nav.top-bar.expanded {
        background: #fff;
    }

    nav.top-bar section.top-bar-section > ul {
        border-top: 1px solid #dadada;
    }

    nav.top-bar section.top-bar-section > ul li {
        border-bottom: 1px solid #dadada;
    }

    nav.top-bar section.top-bar-section > ul li > a {
        background: #fff;
        font-size: 0.88889rem;
    }

    nav.top-bar section.top-bar-section > ul li > a.sv-menu:last-child {
        border-bottom: none;
    }

    nav.top-bar section.top-bar-section > ul ul {
        border-top: 1px solid #dadada;
    }

    nav.top-bar section.top-bar-section > ul li.has-dropdown {
        background-color: #fff;
        padding-right: 10px;
    }

    nav.top-bar section.top-bar-section > ul li.has-dropdown > a {
        background-image: url(/design/images/icons/arrows/black-right.png);
        background-position: right center;
        background-repeat: no-repeat;
    }

    nav.top-bar section.top-bar-section .dropdown li:not(.has-form) {
        background-color: #fff;
        padding-right: 20px;
    }

    nav.top-bar section.top-bar-section .dropdown li:not(.has-form) a:not(.button) {
        background: #fff;
        font-size: 0.88889rem;
    }

    nav.top-bar section.top-bar-section .dropdown li:not(.has-form) a:not(.button).parent-link {
        background-image: url(/design/images/icons/arrows/black-down.png);
        background-position: right center;
        background-repeat: no-repeat;
        font-family: 'Open Sans Bold', Arial, sans-serif;
        font-weight: normal;
    }

    nav.top-bar section.top-bar-section .dropdown li:not(.has-form).back {
        padding-left: 15px;
    }

    nav.top-bar section.top-bar-section .dropdown li:not(.has-form).back a {
        background-image: url(/design/images/icons/arrows/black-left.png);
        background-position: right center;
        background-position: left;
        background-repeat: no-repeat;
    }

    nav.top-bar section.top-bar-section .dropdown li:not(.has-form).back h5 {
        margin: 0;
    }
}

.ui-dialog {
    z-index: 10000;
}

.ui-dialog h1,
.ui-dialog h2,
.ui-dialog h3,
.ui-dialog h4,
.ui-dialog h5,
.ui-dialog h6 {
    margin-bottom: 1.5rem;
}

.ui-dialog p,
.ui-dialog ul {
    margin-bottom: 1.11111rem;
}

.ui-dialog.ui-widget,
.ui-dialog.ui-widget button {
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 0.88889rem;
}

.ui-dialog div.ym-form {
    background: none;
    border: none;
    box-shadow: none;
}

.ui-dialog .jquery_tabs.sv-jquery-tabs {
    border: none;
}

.ui-dialog .jquery_tabs .content {
    padding: 15px 0;
}

.ui-dialog .jquery_tabs ul.tabs-list li a {
    padding: 0.25em;
}

@media all {
    .ui-dialog * {
        margin: 0;
        padding: 0;
    }

    .ui-dialog option {
        padding-left: 0.4em;
    }

    .ui-dialog select {
        padding: 1px;
    }

    .ui-dialog * html body * {
        overflow: visible;
    }

    .ui-dialog body {
        background: #fff;
        color: #000;
        font-size: 100%;
        text-align: left;
    }

    .ui-dialog div:target {
        outline: 0 none;
    }

    .ui-dialog article,
    .ui-dialog aside,
    .ui-dialog details,
    .ui-dialog figcaption,
    .ui-dialog figure,
    .ui-dialog footer,
    .ui-dialog header,
    .ui-dialog hgroup,
    .ui-dialog nav,
    .ui-dialog section {
        display: block;
    }

    .ui-dialog audio,
    .ui-dialog canvas,
    .ui-dialog video {
        display: inline-block;
    }

    .ui-dialog audio:not([controls]) {
        display: none;
    }

    .ui-dialog [hidden] {
        display: none;
    }

    .ui-dialog input[type='search'] {
        appearance: textfield;
    }

    .ui-dialog input[type='search']::-webkit-search-decoration {
        appearance: none;
    }

    .ui-dialog fieldset,
    .ui-dialog img {
        border: 0 solid;
    }

    .ui-dialog ul,
    .ui-dialog ol,
    .ui-dialog dl {
        margin: 0 0 1em 1em;
    }

    .ui-dialog li {
        line-height: 1.5em;
        margin-left: 0.8em;
    }

    .ui-dialog dt {
        font-weight: bold;
    }

    .ui-dialog dd {
        margin: 0 0 1em 0.8em;
    }

    .ui-dialog blockquote {
        margin: 0 0 1em 0.8em;
    }

    .ui-dialog q {
        quotes: none;
    }

    .ui-dialog blockquote::before,
    .ui-dialog blockquote::after,
    .ui-dialog q::before,
    .ui-dialog q::after {
        content: '';
        content: none;
    }

    .ui-dialog table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    .ui-dialog .ym-clearfix::before {
        content: '';
        display: table;
    }

    .ui-dialog .ym-clearfix::after {
        clear: both;
        content: '.';
        display: block;
        font-size: 0;
        height: 0;
        visibility: hidden;
    }

    .ui-dialog .ym-contain-dt {
        display: table;
        overflow: visible;
        width: 100%;
    }

    .ui-dialog .ym-contain-oh {
        display: block;
        overflow: hidden;
        width: 100%;
    }

    .ui-dialog .ym-contain-fl {
        float: left;
        width: 100%;
    }

    .ui-dialog .ym-skip,
    .ui-dialog .ym-hideme,
    .ui-dialog .ym-print {
        left: -32768px;
        position: absolute;
        top: -32768px;
    }

    .ui-dialog .ym-skip:focus,
    .ui-dialog .ym-skip:active {
        left: 0;
        position: static;
        top: 0;
    }

    .ui-dialog .ym-skiplinks {
        left: -32768px;
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1000;
    }

    .ui-dialog .ym-skiplinks .ym-skip:focus,
    .ui-dialog .ym-skiplinks .ym-skip:active {
        left: 32768px;
        outline: 0 none;
        position: absolute;
        width: 100%;
    }
}

@media screen, projection {
    .ui-dialog .ym-column {
        display: table;
        width: 100%;
    }

    .ui-dialog .ym-col1 {
        float: left;
        width: 20%;
    }

    .ui-dialog .ym-col2 {
        float: right;
        width: 20%;
    }

    .ui-dialog .ym-col3 {
        margin: 0 20%;
        width: auto;
    }

    .ui-dialog .ym-cbox .ym-cbox-left,
    .ui-dialog .ym-cbox-right {
        position: relative;
    }

    .ui-dialog .ym-cbox {
        padding: 0 10px;
    }

    .ui-dialog .ym-cbox-left {
        padding: 0 10px 0 0;
    }

    .ui-dialog .ym-cbox-right {
        padding: 0 0 0 10px;
    }

    .ui-dialog .ym-ie-clearing {
        display: none;
    }

    .ui-dialog .ym-grid {
        display: table;
        list-style-type: none;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        table-layout: fixed;
        width: 100%;
    }

    .ui-dialog .ym-gl {
        float: left;
        margin: 0;
    }

    .ui-dialog .ym-gr {
        float: right;
        margin: 0 0 0 -5px;
    }

    .ui-dialog .ym-g20 {
        width: 20%;
    }

    .ui-dialog .ym-g40 {
        width: 40%;
    }

    .ui-dialog .ym-g60 {
        width: 60%;
    }

    .ui-dialog .ym-g80 {
        width: 80%;
    }

    .ui-dialog .ym-g25 {
        width: 25%;
    }

    .ui-dialog .ym-g33 {
        width: 33.333%;
    }

    .ui-dialog .ym-g50 {
        width: 50%;
    }

    .ui-dialog .ym-g66 {
        width: 66.666%;
    }

    .ui-dialog .ym-g75 {
        width: 75%;
    }

    .ui-dialog .ym-g38 {
        width: 38.2%;
    }

    .ui-dialog .ym-g62 {
        width: 61.8%;
    }

    .ui-dialog .ym-gbox {
        padding: 0 10px;
    }

    .ui-dialog .ym-gbox-left {
        padding: 0 10px 0 0;
    }

    .ui-dialog .ym-gbox-right {
        padding: 0 0 0 10px;
    }

    .ui-dialog .ym-equalize {
        overflow: hidden;
    }

    .ui-dialog .ym-equalize > .ym-gl,
    .ui-dialog .ym-equalize > .ym-gr {
        display: table-cell;
        float: none;
        margin: 0;
        vertical-align: top;
    }

    .ui-dialog .ym-equalize > * > .ym-gbox,
    .ui-dialog .ym-equalize > * > .ym-gbox-left,
    .ui-dialog .ym-equalize > * > .ym-gbox-right {
        margin-bottom: -10000px;
        padding-bottom: 10000px;
    }
}

@media all {
    .ui-dialog .ym-form,
    .ui-dialog .ym-form fieldset {
        overflow: hidden;
    }

    .ui-dialog .ym-form div .ym-form label,
    .ui-dialog .ym-form .ym-message {
        display: block;
        position: relative;
    }

    .ui-dialog .ym-form .ym-fbox-check label {
        display: inline;
    }

    .ui-dialog .ym-form input,
    .ui-dialog .ym-form textarea {
        cursor: text;
    }

    .ui-dialog .ym-form input[type='checkbox'],
    .ui-dialog .ym-form input[type='radio'],
    .ui-dialog .ym-form select,
    .ui-dialog .ym-form label {
        cursor: pointer;
    }

    .ui-dialog .ym-form textarea {
        overflow: auto;
    }

    .ui-dialog .ym-form input[type=hidden] {
        display: none !important;
    }

    .ui-dialog .ym-form .ym-fbox-text::before,
    .ui-dialog .ym-form .ym-fbox-select::before,
    .ui-dialog .ym-form .ym-fbox-check::before,
    .ui-dialog .ym-form .ym-fbox-button::before {
        content: '';
        display: table;
    }

    .ui-dialog .ym-form .ym-fbox-text::after,
    .ui-dialog .ym-form .ym-fbox-select::after,
    .ui-dialog .ym-form .ym-fbox-check::after,
    .ui-dialog .ym-form .ym-fbox-button::after {
        clear: both;
        content: '.';
        display: block;
        font-size: 0;
        height: 0;
        visibility: hidden;
    }

    .ui-dialog .ym-form select,
    .ui-dialog .ym-form input,
    .ui-dialog .ym-form textarea {
        display: block;
        position: relative;
        width: 58.5%;
    }

    .ui-dialog .ym-form .ym-fbox-check input {
        display: inline;
        width: auto;
    }

    .ui-dialog .ym-form .ym-fbox-button input {
        display: inline;
        overflow: visible;
        width: auto;
    }

    .ui-dialog .ym-form .ym-fbox-check input:focus,
    .ui-dialog .ym-form .ym-fbox-check input:hover,
    .ui-dialog .ym-form .ym-fbox-check input:active {
        border: 0 none;
    }

    .ui-dialog .ym-full .ym-fbox-select select,
    .ui-dialog .ym-full .ym-fbox-text input,
    .ui-dialog .ym-full .ym-fbox-text textarea {
        margin-right: -3px;
        width: 94.2%;
    }

    .ui-dialog .ym-columnar .ym-fbox-text label,
    .ui-dialog .ym-columnar .ym-fbox-select label {
        display: inline;
        float: left;
        width: 30%;
    }

    .ui-dialog .ym-columnar .ym-fbox-check {
        position: relative;
    }

    .ui-dialog .ym-label {
        display: block;
    }

    .ui-dialog .ym-columnar .ym-fbox-check .ym-label {
        position: absolute;
        top: 0;
    }

    .ui-dialog .ym-columnar .ym-fbox-check input,
    .ui-dialog .ym-columnar .ym-error .ym-message {
        margin-left: 30%;
    }

    .ui-dialog .ym-columnar fieldset .ym-fbox-button,
    .ui-dialog fieldset.ym-columnar .ym-fbox-button {
        padding-left: 30%;
    }

    .ui-dialog .ym-columnar .ym-fbox-select select,
    .ui-dialog .ym-columnar .ym-fbox-text input,
    .ui-dialog .ym-columnar .ym-fbox-text textarea {
        float: left;
        margin-right: -3px;
        width: 67.2%;
    }

    .ui-dialog .ym-fbox-select select {
        width: 60%;
    }

    .ui-dialog .ym-full .ym-fbox-select select {
        width: 94.8%;
    }

    .ui-dialog .ym-columnar .ym-fbox-select select {
        width: 68.8%;
    }
}

@media print {
    .ui-dialog .ym-grid > .ym-gl,
    .ui-dialog .ym-grid > .ym-gr {
        display: table;
        overflow: visible;
    }

    .ui-dialog .ym-print {
        left: 0;
        position: static;
    }

    .ui-dialog .ym-noprint {
        display: none !important;
    }
}

@media screen and (min-width: 0) {
    .ui-dialog .ym-form input,
    .ui-dialog .ym-form input,
    .ui-dialog .ym-form textarea,
    .ui-dialog .ym-form select {
        box-sizing: border-box;
        box-sizing: border-box;
        box-sizing: border-box;
        box-sizing: border-box;
        width: 60%;
    }

    .ui-dialog .ym-columnar label {
        box-sizing: border-box;
        box-sizing: border-box;
        box-sizing: border-box;
        box-sizing: border-box;
    }

    .ui-dialog .ym-columnar .ym-fbox-select select,
    .ui-dialog .ym-columnar .ym-fbox-text input,
    .ui-dialog .ym-columnar .ym-fbox-text textarea {
        width: 70%;
    }

    .ui-dialog .ym-full .ym-fbox-select select,
    .ui-dialog .ym-full .ym-fbox-text input,
    .ui-dialog .ym-full .ym-fbox-text textarea {
        width: 100%;
    }
}

@media screen, projection {
    .ui-dialog .ym-form {
        background: #f4f4f4;
        border: 2px #fff solid;
        box-shadow: 0 0 4px #ddd;
        box-shadow: 0 0 4px #ddd;
        box-shadow: 0 0 4px #ddd;
        margin: 1.5em 0 0;
        padding: 0;
    }

    .ui-dialog .ym-form fieldset {
        background: transparent;
        margin: 0.75em 0;
        padding: 0 0.5em;
        position: static;
    }

    .ui-dialog .ym-form legend {
        background: transparent;
        color: #000;
        font-size: 1.2em;
        font-weight: 700;
        line-height: 1.25em;
        padding: 0 0.5em;
    }

    .ui-dialog .ym-form label {
        color: #666;
        line-height: 1.5em;
    }

    .ui-dialog .ym-label {
        color: #666;
        padding-top: 0.25em;
    }

    .ui-dialog .ym-form .ym-form div.ym-fbox-check:focus + label {
        color: #000;
    }

    .ui-dialog .ym-form div.ym-fbox-text,
    .ui-dialog .ym-form div.ym-fbox-select,
    .ui-dialog .ym-form div.ym-fbox-check,
    .ui-dialog .ym-form div.ym-fbox-button {
        margin-bottom: 0.5em;
        padding: 0.3em 0;
    }

    .ui-dialog .ym-form div.ym-fbox-button {
        background: #ececec;
        border-top: 1px #e0e0e0 solid;
        margin: 0;
        padding: 0 1em 1.5em;
    }

    .ui-dialog .ym-form .ym-gbox {
        margin-right: 0;
        padding: 0 0.5em;
    }

    .ui-dialog .ym-form h6 {
        color: #000;
        margin: 1em 0 0 1em;
    }

    .ui-dialog .ym-form .ym-fbox-text input,
    .ui-dialog .ym-form .ym-fbox-text textarea {
        line-height: 1em;
        margin-top: -2px;
        padding: 0.25em 0.3em;
    }

    .ui-dialog .ym-form .ym-fbox-select select {
        line-height: 1em;
        margin-top: -2px;
        padding: 0.25em 2px 0.25em 1px;
    }

    .ui-dialog .ym-form input,
    .ui-dialog .ym-form textarea,
    .ui-dialog .ym-form select,
    .ui-dialog .ym-form optgroup {
        box-shadow: inset 0 0 4px #eee;
        box-shadow: inset 0 0 4px #eee;
        box-shadow: inset 0 0 4px #eee;
        font-family: Arial, Helvetica, sans-serif;
    }

    .ui-dialog .ym-form optgroup {
        font-style: normal;
        font-weight: 700;
    }

    .ui-dialog .ym-form .ym-fbox-text input,
    .ui-dialog .ym-form .ym-fbox-text textarea,
    .ui-dialog .ym-form .ym-fbox-select select {
        border: 1px solid #ddd;
    }

    .ui-dialog .ym-form .ym-message {
        color: #666;
        margin-bottom: 0.5em;
    }

    .ui-dialog .ym-form sup {
        color: #800;
        font-weight: 700;
    }

    .ui-dialog .ym-form input:valid,
    .ui-dialog .ym-form textarea:valid {
        background-color: #fff;
    }

    .ui-dialog .ym-form input:invalid,
    .ui-dialog .ym-form textarea:invalid {
        background-color: #fdd;
    }

    .ui-dialog .ym-form .ym-error label {
        color: #800;
        font-weight: 400;
    }

    .ui-dialog .ym-form .ym-error input,
    .ui-dialog .ym-form .ym-error select,
    .ui-dialog .ym-form .ym-error textarea {
        border: 1px #800 solid;
    }

    .ui-dialog .ym-form .ym-error input:hover,
    .ui-dialog .ym-form .ym-error input:focus,
    .ui-dialog .ym-form .ym-error select:hover,
    .ui-dialog .ym-form .ym-error select:focus,
    .ui-dialog .ym-form .ym-error textarea:hover,
    .ui-dialog .ym-form .ym-error textarea:focus {
        border: 1px #800 solid !important;
    }

    .ui-dialog .ym-form .ym-error .ym-message {
        color: #800;
    }

    .ui-dialog .ym-form div.ym-fbox-text input:focus,
    .ui-dialog .ym-form div select:focus,
    .ui-dialog .ym-form div textarea:focus,
    .ui-dialog .ym-form div.ym-fbox-text input:hover,
    .ui-dialog .ym-form div select:hover,
    .ui-dialog .ym-form div textarea:hover,
    .ui-dialog .ym-form div.ym-fbox-text input:active,
    .ui-dialog .ym-form div select:active,
    .ui-dialog .ym-form div textarea:active {
        background: #fff;
        border: 1px #888 solid;
    }

    .ui-dialog .ym-button,
    .ui-dialog .ym-form button,
    .ui-dialog .ym-form input[type='button'],
    .ui-dialog .ym-form input[type='reset'],
    .ui-dialog .ym-form input[type='submit'] {
        background-color: #ccc;
        background-image: linear-gradient(top, #eee, #ccc);
        background-image: linear-gradient(top, #eee, #ccc);
        background-image: linear-gradient(top, #eee, #ccc);
        background-image: linear-gradient(top, #eee, #ccc);
        background-image: linear-gradient(top, #eee, #ccc);
        border: 1px solid #777;
        border-radius: 0.2em;
        border-radius: 0.2em;
        border-radius: 0.2em;
        box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.8) inset, 0 1px 0 rgba(0, 0, 0, 0.15);
        box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.8) inset, 0 1px 0 rgba(0, 0, 0, 0.15);
        box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.8) inset, 0 1px 0 rgba(0, 0, 0, 0.15);
        color: #333 !important;
        cursor: pointer;
        display: inline-block;
        filter: progid:dximagetransform.Microsoft.gradient(startcolorstr='#eeeeee', endcolorstr='#cccccc');
        font: 1em/2em Arial, Helvetica;
        margin: 1.5em 0.75em 0 0;
        overflow: visible;
        padding: 0 1.5em;
        text-decoration: none !important;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
        white-space: nowrap;
    }

    .ui-dialog .ym-form button:hover,
    .ui-dialog .ym-form input[type='button']:hover,
    .ui-dialog .ym-form input[type='reset']:hover,
    .ui-dialog .ym-form input[type='submit']:hover,
    .ui-dialog .ym-button:hover {
        background-color: #ddd;
        background-image: linear-gradient(top, #fafafa, #ddd);
        background-image: linear-gradient(top, #fafafa, #ddd);
        background-image: linear-gradient(top, #fafafa, #ddd);
        background-image: linear-gradient(top, #fafafa, #ddd);
        background-image: linear-gradient(top, #fafafa, #ddd);
        filter: progid:dximagetransform.Microsoft.gradient(startcolorstr='#fafafa', endcolorstr='#dddddd');
    }

    .ui-dialog .ym-form button:active,
    .ui-dialog .ym-form input[type='button']:active,
    .ui-dialog .ym-form input[type='reset']:active,
    .ui-dialog .ym-form input[type='submit']:active,
    .ui-dialog .ym-button:active {
        background-color: #bbb;
        background-image: linear-gradient(top, #ccc, #bbb);
        background-image: linear-gradient(top, #ccc, #bbb);
        background-image: linear-gradient(top, #ccc, #bbb);
        background-image: linear-gradient(top, #ccc, #bbb);
        background-image: linear-gradient(top, #ccc, #bbb);
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2) inset;
        filter: progid:dximagetransform.Microsoft.gradient(startcolorstr='#cccccc', endcolorstr='#bbbbbb');
        position: relative;
        top: 1px;
    }

    .ui-dialog .ym-form button:focus,
    .ui-dialog .ym-form input[type='button']:focus,
    .ui-dialog .ym-form input[type='reset']:focus,
    .ui-dialog .ym-form input[type='submit']:focus,
    .ui-dialog .ym-button:focus {
        background: #fafafa;
        outline: 0;
    }

    .ui-dialog .ym-form button::before,
    .ui-dialog .ym-button::before {
        background: #ccc;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 0.15em 0 0 0.15em;
        border-radius: 0.15em 0 0 0.15em;
        border-radius: 0.15em 0 0 0.15em;
        box-shadow: 1px 0 0 rgba(0, 0, 0, 0.5), 2px 0 0 rgba(255, 255, 255, 0.5);
        box-shadow: 1px 0 0 rgba(0, 0, 0, 0.5), 2px 0 0 rgba(255, 255, 255, 0.5);
        box-shadow: 1px 0 0 rgba(0, 0, 0, 0.5), 2px 0 0 rgba(255, 255, 255, 0.5);
        float: left;
        font-size: 1.5em;
        margin: 0 1em 0 -1em;
        padding: 0 0.2em;
        pointer-events: none;
        text-align: center;
        width: 1em;
    }

    .ui-dialog .ym-add::before {
        content: '\271A';
    }

    .ui-dialog .ym-delete::before {
        content: '\2718';
    }

    .ui-dialog .ym-edit::before {
        content: '\270E';
    }

    .ui-dialog .ym-email::before {
        content: '\2709';
    }

    .ui-dialog .ym-like::before {
        content: '\2764';
    }

    .ui-dialog .ym-next::before {
        content: '\279C';
    }

    .ui-dialog .ym-play::before {
        content: '\25B6';
    }

    .ui-dialog .ym-save::before {
        content: '\2714';
    }

    .ui-dialog .ym-spark::before {
        content: '\2737';
    }

    .ui-dialog .ym-star::before {
        content: '\2605';
    }

    .ui-dialog .ym-form button::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    .ui-dialog .ym-form input[type=button]::-moz-focus-inner,
    .ui-dialog .ym-form input[type=reset]::-moz-focus-inner,
    .ui-dialog .ym-form input[type=submit]::-moz-focus-inner {
        padding: 0.4em;
    }

    .ui-dialog .ym-form button[disabled],
    .ui-dialog .ym-form button[disabled]:hover,
    .ui-dialog .ym-form input[type=button][disabled],
    .ui-dialog .ym-form input[type=button][disabled]:hover,
    .ui-dialog .ym-form input[type=reset][disabled],
    .ui-dialog .ym-form input[type=reset][disabled]:hover,
    .ui-dialog .ym-form input[type=submit][disabled],
    .ui-dialog .ym-form input[type=submit][disabled]:hover,
    .ui-dialog .ym-button.ym-disabled,
    .ui-dialog .ym-button.ym-disabled:hover {
        background: #eee;
        border-color: #aaa;
        box-shadow: none;
        box-shadow: none;
        box-shadow: none;
        color: #aaa !important;
        cursor: default;
        position: static;
        text-shadow: none;
    }

    .ui-dialog * html .ym-button,
    .ui-dialog * html .ym-form button {
        background-color: #ccc;
        border: 1px solid #777;
        color: #333 !important;
        cursor: pointer;
        display: inline-block;
        filter: progid:dximagetransform.Microsoft.gradient(startcolorstr='#eeeeee', endcolorstr='#cccccc');
        font: 1em/2em Arial, Helvetica;
        margin: 1.5em 0.75em 0 0;
        overflow: visible;
        padding: 0 1.5em;
        text-decoration: none !important;
        white-space: nowrap;
    }

    .ui-dialog * html .ym-form button:focus,
    .ui-dialog * html .ym-button:focus {
        background: #fafafa;
        outline: 0;
    }

    .ui-dialog * html .ym-form button:hover,
    .ui-dialog * html .ym-button:hover {
        background-color: #ddd;
        filter: progid:dximagetransform.Microsoft.gradient(startcolorstr='#fafafa', endcolorstr='#dddddd');
    }

    .ui-dialog * html .ym-form button:active,
    .ui-dialog * html .ym-button:active {
        background-color: #bbb;
        filter: progid:dximagetransform.Microsoft.gradient(startcolorstr='#cccccc', endcolorstr='#bbbbbb');
        position: relative;
        top: 1px;
    }

    .ui-dialog * html .ym-button.ym-disabled,
    .ui-dialog * html .ym-button.ym-disabled:hover {
        background: #eee;
        border-color: #aaa;
        color: #aaa !important;
        cursor: default;
        position: static;
        text-shadow: none;
    }
}

.sv-widget-back-link,
.paging_sitevision {
    font-size: 0.88889rem;
}

.ym-grid {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    width: 100%;
}

.ym-grid::before,
.ym-grid::after {
    content: ' ';
    display: table;
}

.ym-grid::after {
    clear: both;
}

.ym-grid .ym-g50 {
    float: left;
    left: 0%;
    left: auto;
    margin-left: 0% !important;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    right: auto;
    right: 0%;
    width: 50%;
}

.ym-grid .ym-g33 {
    float: left;
    left: 0%;
    left: auto;
    margin-left: 0% !important;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    right: auto;
    right: 0%;
    width: 33.33333%;
}

div.sv-widget .paging_sitevision input[type='text'].pageinate_input_box {
    display: inline-block;
    margin: 0 3px;
    text-align: center;
    width: 30px;
}

div.sv-widget .paging_sitevision .sv-wrapperPaging span {
    padding: 0 3px;
}

.svPaging {
    font-size: 0.88889rem;
    line-height: 1.6;
}

.sk-spinner-wave.sk-spinner {
    font-size: 10px;
    height: 30px;
    margin: 150px auto;
    text-align: center;
    width: 50px;
}

.sk-spinner-wave div {
    animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
    animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
    background-color: #333;
    display: inline-block;
    height: 100%;
    width: 6px;
}

.sk-spinner-wave .sk-rect2 {
    animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.sk-spinner-wave .sk-rect3 {
    animation-delay: -1s;
    animation-delay: -1s;
}

.sk-spinner-wave .sk-rect4 {
    animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.sk-spinner-wave .sk-rect5 {
    animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@keyframes sk-waveStretchDelay {
    0%,
    40%,
    100% {
        transform: scaleY(0.4);
        transform: scaleY(0.4);
    }

    20% {
        transform: scaleY(1);
        transform: scaleY(1);
    }
}

@keyframes sk-waveStretchDelay {
    0%,
    40%,
    100% {
        transform: scaleY(0.4);
        transform: scaleY(0.4);
    }

    20% {
        transform: scaleY(1);
        transform: scaleY(1);
    }
}

.sv-body-wrapper {
    top: 111px;
}

.sv-view .sv-body-wrapper {
    top: auto;
}

.sv-view .sv-widget {
    float: none;
}

.sv-view .ui-state-error {
    margin: 0.44444rem 0 0;
}

.sv-widget {
    margin-bottom: 2.77778rem;
}

.sv-widget a {
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-weight: normal;
    text-decoration: underline;
}

.sv-widget a,
.sv-widget a:visited {
    color: #000;
}

.sv-widget a:hover {
    color: #ffce1f;
    text-decoration: underline;
}

.sv-widget h1 {
    font-family: 'Open Sans Light', Arial, sans-serif;
}

.sv-widget h2 {
    margin-bottom: 0;
}

.sv-view .sv-widget h1 {
    text-transform: uppercase;
}

.sv-widget h2:not(:first-of-type) {
    margin-top: 2rem;
}

.sv-widget > .sv-widget-content-content h2:first-child {
    margin-top: 0.2rem;
}

.sv-widget .content-1-1 {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    width: 100%;
}

.sv-widget .content-1-1::before,
.sv-widget .content-1-1::after {
    content: ' ';
    display: table;
}

.sv-widget .content-1-1::after {
    clear: both;
}

.sv-widget .content-1-1 > div {
    float: left;
    left: 0%;
    left: auto;
    margin-bottom: 1.11111rem;
    margin-left: 0% !important;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    right: auto;
    right: 0%;
    width: 100%;
}

.sv-widget .content-1-1 > div:last-child {
    margin-bottom: 0;
}

.sv-widget .content-1-1 > div p:last-child {
    margin-bottom: 0;
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget .content-1-1 > div {
        margin-bottom: 0;
    }

    .sv-widget .content-1-1 > div:first-child {
        padding-left: 0;
    }

    .sv-widget .content-1-1 > div:last-child {
        padding-right: 0;
    }
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget .content-1-1 > div {
        float: left;
        left: 0%;
        left: auto;
        margin-left: 0% !important;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        right: auto;
        right: 0%;
        width: 50%;
    }

    .sv-widget .content-1-1 > div:first-child {
        padding-right: 0.55556rem;
    }

    .sv-widget .content-1-1 > div:last-child {
        padding-left: 0.55556rem;
    }
}

.sv-widget .content-1-1.mobile-too-1-1 {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    width: 100%;
}

.sv-widget .content-1-1.mobile-too-1-1::before,
.sv-widget .content-1-1.mobile-too-1-1::after {
    content: ' ';
    display: table;
}

.sv-widget .content-1-1.mobile-too-1-1::after {
    clear: both;
}

.sv-widget .content-1-1.mobile-too-1-1 > div {
    float: left;
    left: 0%;
    left: auto;
    margin-bottom: 1.11111rem;
    margin-left: 0% !important;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    right: auto;
    right: 0%;
    width: 100%;
}

.sv-widget .content-1-1.mobile-too-1-1 > div:last-child {
    margin-bottom: 0;
}

.sv-widget .content-1-1.mobile-too-1-1 > div p:last-child {
    margin-bottom: 0;
}

@media all {
    .sv-widget .content-1-1.mobile-too-1-1 > div {
        margin-bottom: 0;
    }

    .sv-widget .content-1-1.mobile-too-1-1 > div:first-child {
        padding-left: 0;
    }

    .sv-widget .content-1-1.mobile-too-1-1 > div:last-child {
        padding-right: 0;
    }
}

@media all {
    .sv-widget .content-1-1.mobile-too-1-1 > div {
        float: left;
        left: 0%;
        left: auto;
        margin-left: 0% !important;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        right: auto;
        right: 0%;
        width: 50%;
    }

    .sv-widget .content-1-1.mobile-too-1-1 > div:first-child {
        padding-right: 0.55556rem;
    }

    .sv-widget .content-1-1.mobile-too-1-1 > div:last-child {
        padding-left: 0.55556rem;
    }
}

.sv-widget .content-1-1.right-align > div:last-child {
    text-align: right;
}

.sv-widget .content-1-1.right-align > div:last-child p {
    float: right;
}

@media only screen and (max-width: 52em) {
    .sv-widget .content-1-1.button-bar p {
        display: table;
        width: 100%;
    }
}

.sv-widget .content-1-1-1 {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    width: 100%;
}

.sv-widget .content-1-1-1::before,
.sv-widget .content-1-1-1::after {
    content: ' ';
    display: table;
}

.sv-widget .content-1-1-1::after {
    clear: both;
}

.sv-widget .content-1-1-1 > div {
    float: left;
    left: 0%;
    left: auto;
    margin-bottom: 1.11111rem;
    margin-left: 0% !important;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    right: auto;
    right: 0%;
    width: 100%;
}

.sv-widget .content-1-1-1 > div:last-child {
    margin-bottom: 0;
}

.sv-widget .content-1-1-1 > div p:last-child {
    margin-bottom: 0;
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget .content-1-1-1 > div {
        margin-bottom: 0;
    }

    .sv-widget .content-1-1-1 > div:first-child {
        padding-left: 0;
    }

    .sv-widget .content-1-1-1 > div:last-child {
        padding-right: 0;
    }
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget .content-1-1-1 > div {
        float: left;
        padding-left: 0.55556rem;
        padding-right: 0.55556rem;
        width: 33.33333%;
    }
}

.sv-widget .content-1-1-1-1 {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    width: 100%;
}

.sv-widget .content-1-1-1-1::before,
.sv-widget .content-1-1-1-1::after {
    content: ' ';
    display: table;
}

.sv-widget .content-1-1-1-1::after {
    clear: both;
}

.sv-widget .content-1-1-1-1 > div {
    float: left;
    left: 0%;
    left: auto;
    margin-bottom: 1.11111rem;
    margin-left: 0% !important;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    right: auto;
    right: 0%;
    width: 100%;
}

.sv-widget .content-1-1-1-1 > div:last-child {
    margin-bottom: 0;
}

.sv-widget .content-1-1-1-1 > div p:last-child {
    margin-bottom: 0;
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget .content-1-1-1-1 > div {
        margin-bottom: 0;
    }

    .sv-widget .content-1-1-1-1 > div:first-child {
        padding-left: 0;
    }

    .sv-widget .content-1-1-1-1 > div:last-child {
        padding-right: 0;
    }
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget .content-1-1-1-1 > div {
        float: left;
        padding-left: 0.55556rem;
        padding-right: 0.55556rem;
        width: 25%;
    }
}

.sv-widget .content-2-1 {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    width: 100%;
}

.sv-widget .content-2-1::before,
.sv-widget .content-2-1::after {
    content: ' ';
    display: table;
}

.sv-widget .content-2-1::after {
    clear: both;
}

.sv-widget .content-2-1 > div {
    float: left;
    left: 0%;
    left: auto;
    margin-bottom: 1.11111rem;
    margin-left: 0% !important;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    right: auto;
    right: 0%;
    width: 100%;
}

.sv-widget .content-2-1 > div:last-child {
    margin-bottom: 0;
}

.sv-widget .content-2-1 > div p:last-child {
    margin-bottom: 0;
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget .content-2-1 > div {
        margin-bottom: 0;
    }

    .sv-widget .content-2-1 > div:first-child {
        padding-left: 0;
    }

    .sv-widget .content-2-1 > div:last-child {
        padding-right: 0;
    }
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget .content-2-1 > div:first-child {
        float: left;
        left: 0%;
        left: auto;
        margin-left: 0% !important;
        padding-left: 0;
        padding-right: 0;
        padding-right: 0.55556rem;
        position: relative;
        right: auto;
        right: 0%;
        width: 66.66667%;
    }

    .sv-widget .content-2-1 > div:last-child {
        float: left;
        left: 0%;
        left: auto;
        margin-left: 0% !important;
        padding-left: 0;
        padding-left: 0.55556rem;
        padding-right: 0;
        position: relative;
        right: auto;
        right: 0%;
        width: 33.33333%;
    }
}

.sv-widget .content-1-2 {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    width: 100%;
}

.sv-widget .content-1-2::before,
.sv-widget .content-1-2::after {
    content: ' ';
    display: table;
}

.sv-widget .content-1-2::after {
    clear: both;
}

.sv-widget .content-1-2 > div {
    float: left;
    left: 0%;
    left: auto;
    margin-bottom: 1.11111rem;
    margin-left: 0% !important;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    right: auto;
    right: 0%;
    width: 100%;
}

.sv-widget .content-1-2 > div:last-child {
    margin-bottom: 0;
}

.sv-widget .content-1-2 > div p:last-child {
    margin-bottom: 0;
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget .content-1-2 > div {
        margin-bottom: 0;
    }

    .sv-widget .content-1-2 > div:first-child {
        padding-left: 0;
    }

    .sv-widget .content-1-2 > div:last-child {
        padding-right: 0;
    }
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget .content-1-2 > div:first-child {
        float: left;
        left: 0%;
        left: auto;
        margin-left: 0% !important;
        padding-left: 0;
        padding-right: 0;
        padding-right: 0.55556rem;
        position: relative;
        right: auto;
        right: 0%;
        width: 33.33333%;
    }

    .sv-widget .content-1-2 > div:last-child {
        float: left;
        left: 0%;
        left: auto;
        margin-left: 0% !important;
        padding-left: 0;
        padding-left: 0.55556rem;
        padding-right: 0;
        position: relative;
        right: auto;
        right: 0%;
        width: 66.66667%;
    }
}

.sv-widget .content-1-5 {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    width: 100%;
}

.sv-widget .content-1-5::before,
.sv-widget .content-1-5::after {
    content: ' ';
    display: table;
}

.sv-widget .content-1-5::after {
    clear: both;
}

.sv-widget .content-1-5 > div {
    float: left;
    left: 0%;
    left: auto;
    margin-bottom: 1.11111rem;
    margin-left: 0% !important;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    right: auto;
    right: 0%;
    width: 100%;
}

.sv-widget .content-1-5 > div:last-child {
    margin-bottom: 0;
}

.sv-widget .content-1-5 > div p:last-child {
    margin-bottom: 0;
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget .content-1-5 > div {
        margin-bottom: 0;
    }

    .sv-widget .content-1-5 > div:first-child {
        padding-left: 0;
    }

    .sv-widget .content-1-5 > div:last-child {
        padding-right: 0;
    }
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget .content-1-5 > div:first-child {
        float: left;
        left: 0%;
        left: auto;
        margin-left: 0% !important;
        padding-left: 0;
        padding-right: 0;
        padding-right: 0.55556rem;
        position: relative;
        right: auto;
        right: 0%;
        width: 16.66667%;
    }

    .sv-widget .content-1-5 > div:last-child {
        float: left;
        left: 0%;
        left: auto;
        margin-left: 0% !important;
        padding-left: 0;
        padding-left: 0.55556rem;
        padding-right: 0;
        position: relative;
        right: auto;
        right: 0%;
        width: 83.33333%;
    }
}

.sv-widget .content-2-4 {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    width: 100%;
}

.sv-widget .content-2-4::before,
.sv-widget .content-2-4::after {
    content: ' ';
    display: table;
}

.sv-widget .content-2-4::after {
    clear: both;
}

.sv-widget .content-2-4 > div {
    float: left;
    left: 0%;
    left: auto;
    margin-bottom: 1.11111rem;
    margin-left: 0% !important;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    right: auto;
    right: 0%;
    width: 100%;
}

.sv-widget .content-2-4 > div:last-child {
    margin-bottom: 0;
}

.sv-widget .content-2-4 > div p:last-child {
    margin-bottom: 0;
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget .content-2-4 > div {
        margin-bottom: 0;
    }

    .sv-widget .content-2-4 > div:first-child {
        padding-left: 0;
    }

    .sv-widget .content-2-4 > div:last-child {
        padding-right: 0;
    }
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget .content-2-4 > div:first-child {
        float: left;
        left: 0%;
        left: auto;
        margin-left: 0% !important;
        padding-left: 0;
        padding-right: 0;
        padding-right: 0.55556rem;
        position: relative;
        right: auto;
        right: 0%;
        width: 33.33333%;
    }

    .sv-widget .content-2-4 > div:last-child {
        float: left;
        left: 0%;
        left: auto;
        margin-left: 0% !important;
        padding-left: 0;
        padding-left: 0.55556rem;
        padding-right: 0;
        position: relative;
        right: auto;
        right: 0%;
        width: 66.66667%;
    }
}

.sv-widget div[class*='content-'] ~ div[class*='content-'] {
    margin-top: 2.22222rem;
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget div[class*='content-'] ~ div[class*='content-'] {
        margin-top: 1.11111rem;
    }
}

body.sv-ckeditor-form-edit h1 {
    font-family: 'Open Sans Light', Arial, sans-serif;
}

body.sv-ckeditor-form-edit h2 {
    margin-bottom: 0;
}

.sv-view body.sv-ckeditor-form-edit h1 {
    text-transform: uppercase;
}

body.sv-ckeditor-form-edit .content-1-1 {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    width: 100%;
}

body.sv-ckeditor-form-edit .content-1-1::before,
body.sv-ckeditor-form-edit .content-1-1::after {
    content: ' ';
    display: table;
}

body.sv-ckeditor-form-edit .content-1-1::after {
    clear: both;
}

body.sv-ckeditor-form-edit .content-1-1 > div {
    float: left;
    left: 0%;
    left: auto;
    margin-bottom: 1.11111rem;
    margin-left: 0% !important;
    padding-left: 0.55556rem;
    padding-right: 0.55556rem;
    position: relative;
    right: auto;
    right: 0%;
    width: 100%;
}

body.sv-ckeditor-form-edit .content-1-1 > div:last-child {
    margin-bottom: 0;
}

body.sv-ckeditor-form-edit .content-1-1 > div p:last-child {
    margin-bottom: 0;
}

@media all {
    body.sv-ckeditor-form-edit .content-1-1 > div {
        margin-bottom: 0;
    }
}

@media all {
    body.sv-ckeditor-form-edit .content-1-1 > div {
        float: left;
        left: 0%;
        left: auto;
        margin-left: 0% !important;
        padding-left: 0.55556rem;
        padding-right: 0.55556rem;
        position: relative;
        right: auto;
        right: 0%;
        width: 50%;
    }
}

strong,
b {
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-weight: normal;
}

sup,
sub {
    position: relative;
    top: -0.4em;
    vertical-align: baseline;
}

sub {
    top: 0.4em;
}

.ym-skiplinks {
    display: none;
}

#topline {
    background: #ffce1f;
    box-shadow: #999 2px 2px 2px;
    box-shadow: #999 2px 2px 2px;
    box-shadow: #999 2px 2px 2px;
    height: 7px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

#location-nav ul {
    float: none !important;
}

#location-nav ul li {
    background: #ffce1f;
    font-family: 'Open Sans Light', Arial, sans-serif;
    text-align: center;
}

#location-nav ul li a {
    background-image: url('/design/images/icons/arrows/black-double-right.png');
    background-position: right;
    background-repeat: no-repeat;
    border-right: 20px solid transparent;
    color: #272727;
    display: block;
    line-height: 2.22222rem;
    text-transform: uppercase;
}

#location-nav ul li a,
#location-nav ul li a:visited {
    color: #272727;
}

#location-nav ul li a:hover {
    color: #272727;
}

#location-nav ul li a::before {
    content: 'Zum Standort';
}

@media only screen and (min-width: 52.063em) {
    #location-nav {
        position: absolute;
        top: 7px;
        width: 100%;
    }

    #location-nav > div {
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        max-width: 53.33333rem;
        width: 100%;
    }

    #location-nav > div::before,
    #location-nav > div::after {
        content: ' ';
        display: table;
    }

    #location-nav > div::after {
        clear: both;
    }

    #location-nav > div ul {
        float: right !important;
    }

    #location-nav > div ul li {
        box-shadow: #999 2px 2px 2px;
        box-shadow: #999 2px 2px 2px;
        box-shadow: #999 2px 2px 2px;
    }

    #location-nav > div ul li a {
        padding: 0 30px 0 20px;
    }
}

#location-nav li.active {
    display: none;
}

#topbar {
    background: #fff;
    box-shadow: #666 2px 2px 2px;
    box-shadow: #666 2px 2px 2px;
    box-shadow: #666 2px 2px 2px;
}

@media only screen and (min-width: 52.063em) {
    #topbar {
        height: 135px;
        padding-top: 15px;
    }
}

#topbar > div {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    width: 100%;
}

#topbar > div::before,
#topbar > div::after {
    content: ' ';
    display: table;
}

#topbar > div::after {
    clear: both;
}

.opel #topbar,
.sh #topbar {
    background: #000;
}

.suzuki #topbar {
    background: #003f64;
}

.opel #topbar nav.top-bar li.name,
.sh #topbar nav.top-bar li.name {
    background: #000;
}

.opel #topbar nav.top-bar .toggle-topbar.menu-icon a,
.sh #topbar nav.top-bar .toggle-topbar.menu-icon a {
    color: #fff;
}

.opel #topbar nav.top-bar .toggle-topbar.menu-icon a span::after,
.sh #topbar nav.top-bar .toggle-topbar.menu-icon a span::after {
    box-shadow: 0 0 0 1px #fff, 0 7px 0 1px #fff, 0 14px 0 1px #fff;
    content: '';
    display: block;
    height: 0;
    margin-top: -8px;
    position: absolute;
    right: 15px;
    top: 50%;
    width: 16px;
}

.opel #topbar nav.top-bar .toggle-topbar.menu-icon a span:hover::after,
.sh #topbar nav.top-bar .toggle-topbar.menu-icon a span:hover::after {
    box-shadow: 0 0 0 1px #fff, 0 7px 0 1px #fff, 0 14px 0 1px #fff;
}

@media only screen and (min-width: 52.063em) {
    .opel #topbar nav.top-bar,
    .sh #topbar nav.top-bar {
        background: #000;
    }

    .opel #topbar nav.top-bar ul.left li,
    .sh #topbar nav.top-bar ul.left li {
        border-color: #fff;
    }

    .opel #topbar nav.top-bar ul.left li a,
    .sh #topbar nav.top-bar ul.left li a {
        color: #fff;
    }

    .opel #topbar nav.top-bar ul.left li a,
    .opel #topbar nav.top-bar ul.left li a:visited,
    .sh #topbar nav.top-bar ul.left li a,
    .sh #topbar nav.top-bar ul.left li a:visited {
        color: #fff;
    }

    .opel #topbar nav.top-bar ul.left li a:hover,
    .sh #topbar nav.top-bar ul.left li a:hover {
        color: #fff;
    }
}

.suzuki #topbar nav.top-bar li.name {
    background: #003f64;
}

.suzuki #topbar nav.top-bar .toggle-topbar.menu-icon a {
    color: #fff;
}

.suzuki #topbar nav.top-bar .toggle-topbar.menu-icon a span::after {
    box-shadow: 0 0 0 1px #fff, 0 7px 0 1px #fff, 0 14px 0 1px #fff;
    content: '';
    display: block;
    height: 0;
    margin-top: -8px;
    position: absolute;
    right: 15px;
    top: 50%;
    width: 16px;
}

.suzuki #topbar nav.top-bar .toggle-topbar.menu-icon a span:hover::after {
    box-shadow: 0 0 0 1px #fff, 0 7px 0 1px #fff, 0 14px 0 1px #fff;
}

@media only screen and (min-width: 52.063em) {
    .suzuki #topbar nav.top-bar {
        background: #003f64;
    }

    .suzuki #topbar nav.top-bar ul.left li {
        border-color: #fff;
    }

    .suzuki #topbar nav.top-bar ul.left li a {
        color: #fff;
    }

    .suzuki #topbar nav.top-bar ul.left li a,
    .suzuki #topbar nav.top-bar ul.left li a:visited {
        color: #fff;
    }

    .suzuki #topbar nav.top-bar ul.left li a:hover {
        color: #fff;
    }
}

#topbar nav.top-bar .title-area {
    padding-top: 7px;
}

@media only screen and (max-width: 52em) {
    #topbar nav.top-bar .title-area {
        padding-top: 0;
    }

    #topbar nav.top-bar .title-area img {
        height: 30px;
    }
}

@media only screen and (min-width: 52.063em) {
    #topbar nav.top-bar {
        height: auto;
        line-height: auto;
        padding-top: 11px;
    }

    #topbar nav.top-bar ul.left {
        float: right !important;
        padding-top: 40px;
        width: 480px;
    }

    #topbar nav.top-bar ul.left li {
        border-bottom: 1px solid;
        padding: 0 0 7px;
    }

    #topbar nav.top-bar ul.left li a {
        color: #272727;
        font-size: 0.77778rem;
        line-height: 0.77778rem;
        padding: 0;
        text-transform: uppercase;
    }

    #topbar nav.top-bar ul.left li a,
    #topbar nav.top-bar ul.left li a:visited {
        color: #272727;
    }

    /*#topbar nav.top-bar ul.left li a:hover {*/
    /*    color: #272727;*/
    /*}*/

    #topbar nav.top-bar ul.left li.active {
        font-family: 'Open Sans Bold', Arial, sans-serif;
        font-weight: normal;
    }

    #topbar nav.top-bar ul.left li {
        margin: 0 0 12px 20px;
        width: 140px;
    }

    #topbar nav.top-bar ul.left ul.dropdown {
        display: none;
    }
}

#header {
    z-index: -1;
}

#header .label {
    background: #ffce1f;
    background: rgba(255, 206, 31, 0.85);
    font-family: 'Open Sans Light', Arial, sans-serif;
    font-size: 0.88889rem;
    line-height: 2.22222rem;
    text-align: center;
    text-transform: uppercase;
}

.suzuki #header .label {
    background: rgba(229, 0, 7, 0.85);
    color: white;
}

@media only screen and (min-width: 52.063em) {
    #header {
        position: relative;
    }

    #header.grid-view {
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        max-width: 53.33333rem;
        width: 100%;
    }

    #header.grid-view::before,
    #header.grid-view::after {
        content: ' ';
        display: table;
    }

    #header.grid-view::after {
        clear: both;
    }

    #header .image {
        background-image: url(/design/images/header.jpg);
        background-repeat: no-repeat;
        height: 299px;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        max-width: 53.33333rem;
        width: 100%;
    }

    #header .image::before,
    #header .image::after {
        content: ' ';
        display: table;
    }

    #header .image::after {
        clear: both;
    }

    .opel #header .image {
        background-image: url(/design/images/header-opel.jpg);
    }

    .suzuki #header .image {
        background-image: url(/design/images/header-suzuki.jpg);
    }

    .sh #header .image {
        background-image: url(/design/images/header-sh.jpg);
    }

    #header .label {
        bottom: 0;
        font-size: 1.88889rem;
        line-height: 1;
        padding: 15px;
        position: absolute;
        text-align: right;
        width: 50%;
    }

    #header .label span {
        display: block;
    }

    #header .label span.le {
        margin-left: 17px;
        text-align: left;
    }
}

#header-sub {
    background: #fff;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    padding-bottom: 10px;
    width: 100%;
}

#header-sub::before,
#header-sub::after {
    content: ' ';
    display: table;
}

#header-sub::after {
    clear: both;
}

#header-sub .address {
    display: none;
}

#header-sub .address p {
    font-size: 0.72222rem;
    line-height: 1rem;
    margin-bottom: 0;
}

#header-sub .address p span.spacer {
    display: inline-block;
    min-width: 50px;
}

#header-sub .address a {
    color: #000;
}

#header-sub .address a,
#header-sub .address a:visited {
    color: #000;
}

#header-sub .address a:hover {
    color: #000;
}

#header-sub .brands ul {
    font-size: 0;
    margin: 0;
}

#header-sub .brands li {
    background: #272727;
    color: white;
    display: inline-block;
    font-size: 0.88889rem;
    line-height: 2.22222rem;
    list-style-type: none;
    text-transform: uppercase;
    width: 50%;
}

#header-sub .brands li:first-child {
    background: #454545;
}

#header-sub .brands li span,
#header-sub .brands li a {
    padding-left: 10px;
}

#header-sub .brands li a {
    background-image: url(/design/images/icons/arrows/white-double-right.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: auto 20px;
    border-right: 10px solid transparent;
    color: #fff;
    display: block;
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-weight: normal;
}

#header-sub .brands li a,
#header-sub .brands li a:visited {
    color: #fff;
}

#header-sub .brands li a:hover {
    color: #fff;
}

@media only screen and (min-width: 52.063em) {
    #header-sub {
        padding-bottom: 50px;
    }

    #header-sub .address {
        display: block;
        float: left;
        margin-left: 8.33333% !important;
        margin-top: 13px;
        padding-left: 0.55556rem;
        padding-right: 0.55556rem;
        width: 33.33333%;
    }

    #header-sub .brands {
        float: left;
        padding: 0;
        padding-left: 0.55556rem;
        padding-right: 0.55556rem;
        width: 58.33333%;
    }

    #header-sub .brands li {
        font-size: 1.66667rem;
        line-height: 3.33333rem;
        margin-bottom: 1px;
        width: 100%;
    }

    #header-sub .brands li span,
    #header-sub .brands li a {
        padding-left: 10px;
    }

    #header-sub .brands li a {
        background-size: auto;
        display: inline;
        padding-right: 40px;
    }
}

#nav-sub {
    background: #fff;
    display: block;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    width: 100%;
}

#nav-sub::before,
#nav-sub::after {
    content: ' ';
    display: table;
}

#nav-sub::after {
    clear: both;
}

#nav-sub nav {
    float: left;
    padding-bottom: 10px;
    padding-left: 0.55556rem;
    padding-right: 0.55556rem;
    width: 100%;
}

#nav-sub nav ul {
    width: 100%;
}

#nav-sub nav ul li {
    border-bottom: 1px solid;
    padding: 0 0 7px;
}

#nav-sub nav ul li a {
    color: #272727;
    font-size: 0.77778rem;
    line-height: 0.77778rem;
    padding: 0;
    text-transform: uppercase;
}

#nav-sub nav ul li a,
#nav-sub nav ul li a:visited {
    color: #272727;
}

#nav-sub nav ul li a:hover {
    color: #272727;
}

#nav-sub nav ul li.active {
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-weight: normal;
}

#nav-sub nav ul li {
    margin: 0 0 0.66667rem;
}

@media only screen and (min-width: 52.063em) {
    #nav-sub nav {
        float: right;
        float: left;
        margin-left: 8.33333% !important;
        padding-bottom: 0;
        padding-left: 0.55556rem;
        padding-right: 0.55556rem;
        width: 83.33333%;
    }

    #nav-sub nav ul li {
        float: left;
        margin: 0 0.55556rem 0.66667rem 0;
        width: 184px;
    }
}

#content,
footer {
    background: #fff;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    width: 100%;
}

#content::before,
#content::after,
footer::before,
footer::after {
    content: ' ';
    display: table;
}

#content::after,
footer::after {
    clear: both;
}

#content .sv-widget,
#content .sv-widget-like,
footer .sv-widget,
footer .sv-widget-like {
    float: left;
    padding-left: 0.55556rem;
    padding-right: 0.55556rem;
    width: 100%;
}

@media only screen and (min-width: 52.063em) {
    #content .sv-widget:first-child,
    #content .sv-widget-like:first-child,
    footer .sv-widget:first-child,
    footer .sv-widget-like:first-child {
        margin-top: 40px;
    }
}

@media only screen and (min-width: 52.063em) {
    #content .sv-widget,
    #content .sv-widget-like,
    footer .sv-widget,
    footer .sv-widget-like {
        float: right;
        float: left;
        margin-left: 8.33333% !important;
        padding-left: 0.55556rem;
        padding-right: 0.55556rem;
        width: 83.33333%;
    }
}

body.layout-simple #topbar {
    height: 135px;
    padding-left: 0.55556rem;
    padding-top: 15px;
}

body.layout-simple #content {
    max-width: none;
}

body.layout-simple #content .sv-slot-content > .sv-widget {
    margin-left: 0 !important;
    width: auto;
}

body.layout-simple #content .sv-slot-content > .sv-widget:first-child {
    margin-top: 0;
}

footer p {
    border-top: 1px solid;
    padding-top: 0.55556rem;
    text-align: center;
}

.sv-widget input[type='file'],
.sv-widget input[type='checkbox'],
.sv-widget input[type='radio'] {
    margin: 0;
}

.sv-widget input[type='text'],
.sv-widget input[type='password'],
.sv-widget input[type='date'],
.sv-widget input[type='datetime'],
.sv-widget input[type='datetime-local'],
.sv-widget input[type='month'],
.sv-widget input[type='week'],
.sv-widget input[type='email'],
.sv-widget input[type='number'],
.sv-widget input[type='search'],
.sv-widget input[type='tel'],
.sv-widget input[type='time'],
.sv-widget input[type='url'] {
    height: auto;
    line-height: 1;
}

.sv-widget select {
    height: auto;
    padding: 0;
}

.sv-widget form input[type='submit'],
.sv-widget form input[type='reset'] {
    appearance: none;
    background-color: #ffce1f;
    border-color: #e5b300;
    border-radius: 0;
    border-style: solid;
    border-width: 0;
    color: #272727;
    cursor: pointer;
    display: inline-block;
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-weight: normal;
    line-height: normal;
    margin: 0 0 1.11111rem;
    padding-bottom: 0.5rem;
    padding-left: 0.88889rem;
    padding-right: 0.88889rem;
    padding-top: 0.44444rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background-color 300ms ease-out;
}

.sv-widget form input[type='submit']:hover,
.sv-widget form input[type='submit']:focus,
.sv-widget form input[type='reset']:hover,
.sv-widget form input[type='reset']:focus {
    background-color: #e5b300;
}

.sv-widget form input[type='submit']:disabled {
    background-color: #ffce1f;
    opacity: 0.8;
}

.sv-widget form input[type='submit']:hover,
.sv-widget form input[type='submit']:focus,
.sv-widget form input[type='reset']:hover,
.sv-widget form input[type='reset']:focus {
    color: #272727;
}

.sv-widget a.button {
    appearance: none;
    background-color: #ffce1f;
    border-color: #e5b300;
    border-radius: 0;
    border-style: solid;
    border-width: 0;
    color: #272727;
    cursor: pointer;
    display: inline-block;
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-weight: normal;
    line-height: normal;
    margin: 0 0 1.11111rem;
    padding-bottom: 0.5rem;
    padding-left: 0.88889rem;
    padding-right: 0.88889rem;
    padding-top: 0.44444rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background-color 300ms ease-out;
}

.sv-widget a.button:hover,
.sv-widget a.button:focus {
    background-color: #e5b300;
}

.sv-widget a.button:hover,
.sv-widget a.button:focus {
    color: #272727;
}

@media only screen and (min-width: 52.063em) and (max-width: 60em) {
    #topbar .sv-slot-search {
        position: absolute;
        right: 0;
        top: 50px;
    }
}

@media only screen and (min-width: 60.063em) {
    #topbar .sv-slot-search {
        float: right;
        margin-left: 20px;
    }
}

#topbar .sv-widget-search .ym-fbox-check,
#topbar .sv-widget-search .sv-widget-search-submit,
#topbar .sv-widget-search .sv-widget-search-result-content,
#topbar .sv-widget-search .sv-widget-paging {
    display: none;
}

#topbar .sv-widget-search form {
    margin: 0;
}

#topbar .sv-widget-search input[type='text'] {
    background-image: url('/design/images/pict_search.png');
    background-position: center right 10px;
    background-repeat: no-repeat;
    border: none;
    height: 1.55556rem;
    margin: 11px 0 0;
    padding-right: 35px;
    width: 11.11111rem;
}

@media only screen and (min-width: 52.063em) and (max-width: 60em) {
    #topbar .sv-widget-search input[type='text'] {
        background-color: white;
    }
}

.sv-widget-search .form {
    margin: 0;
}

section#content div.sv-widget-search select {
    background: none;
    width: auto;
}

section#content div.sv-widget-search h2.sv-widget-search-result-title {
    font-size: 0.88889rem;
}

section#content div.sv-widget-search .sv-widget-search-input {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    width: 100%;
}

section#content div.sv-widget-search .sv-widget-search-input::before,
section#content div.sv-widget-search .sv-widget-search-input::after {
    content: ' ';
    display: table;
}

section#content div.sv-widget-search .sv-widget-search-input::after {
    clear: both;
}

section#content div.sv-widget-search .sv-widget-search-input label {
    padding: 0;
}

section#content div.sv-widget-search .sv-widget-search-input .ym-fbox-text {
    float: left;
    left: 0%;
    left: auto;
    margin-left: 0% !important;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    right: auto;
    right: 0%;
    width: 33.33333%;
}

section#content div.sv-widget-search .ym-fbox-check label {
    float: left;
    left: 0%;
    left: auto;
    margin-left: 0% !important;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    right: auto;
    right: 0%;
    width: 16.66667%;
}

section#content div.sv-widget-search .ym-fbox-check input {
    float: left;
    left: 0%;
    left: auto;
    margin-left: 0% !important;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    right: auto;
    right: 0%;
    width: 8.33333%;
}

section#content div.sv-widget-search .cResultHeader {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    width: 100%;
}

section#content div.sv-widget-search .cResultHeader::before,
section#content div.sv-widget-search .cResultHeader::after {
    content: ' ';
    display: table;
}

section#content div.sv-widget-search .cResultHeader::after {
    clear: both;
}

section#content div.sv-widget-search .cResultHeader > div {
    float: left;
    left: 0%;
    left: auto;
    margin-left: 0% !important;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    right: auto;
    right: 0%;
    width: 50%;
}

section#content div.sv-widget-search .cResultHeader > div.itemsperpage {
    float: right;
    float: right;
    left: 0%;
    left: auto;
    margin-left: 0% !important;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    right: auto;
    right: 0%;
    width: 50%;
}

section#content div.sv-widget-search .cResultHeader > div.itemsperpage p {
    float: right;
}

section#content div.sv-widget-search .sv-widget-search-result-entry-breadcrumb {
    padding: 0.27778rem 0 0;
}

section#content div.sv-widget-search .sv-wid-search-itemsperpage-label {
    line-height: 30px;
}

section#content div.sv-widget-search .sv-widget-search-result-entry-wrapper {
    background: #ffce1f;
    margin-bottom: 20px;
    padding: 10px;
}

section#content div.sv-widget-search .sv-widget-search-result-entry-title {
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-size: 0.88889rem;
    line-height: 1.6;
}

section#content div.sv-widget-search .sv-widget-search-result-entry-text a {
    color: #272727;
    font-size: 0.88889rem;
    line-height: 1.6;
}

section#content div.sv-widget-search .sv-widget-search-result-entry-text a .sv-search-marked {
    font-family: 'Open Sans Bold', Arial, sans-serif;
}

.sv-widget-news-navigation ul.cListNavUl {
    display: table;
    margin: 0 0 0.83333rem;
    margin: 0 0 1.66667rem;
}

.sv-widget-news-navigation ul.cListNavUl li {
    display: table-cell;
    padding: 0 10px;
    text-align: center;
}

.sv-widget-news-navigation ul.cListNavUl li {
    border-left: 1px solid;
    line-height: 1rem;
    text-transform: uppercase;
}

.sv-widget-news-navigation ul.cListNavUl li:last-child {
    border-right: 1px solid;
}

.sv-widget-news-navigation ul.cListNavUl li.sv-widget-glossary-character-navigation-element:last-child {
    border-right: 1px solid;
    letter-spacing: 0;
}

.sv-widget-news-navigation ul.cListNavUl li.cListNavSel a {
    color: #000;
}

.sv-widget-news-navigation ul.cListNavUl li.cListNavSel a,
.sv-widget-news-navigation ul.cListNavUl li.cListNavSel a:visited {
    color: #000;
}

.sv-widget-news-navigation ul.cListNavUl li.cListNavSel a:hover {
    color: #000;
}

@media only screen and (min-width: 52.063em) {
    .sv-widget-news-navigation ul.cListNavUl li.sv-widget-glossary-character-navigation-element {
        letter-spacing: 0.22222rem;
    }
}

@media print {
    .sv-widget-news-navigation ul.cListNavUl a[href]::after {
        content: '';
    }
}

.sv-widget-news-navigation ul.cListNavUl a {
    font-family: 'Open Sans', Arial, sans-serif;
    text-decoration: none;
}

.sv-news-detail-date {
    float: right;
    font-size: 0.88889rem;
}

.sv-widget-back-link a {
    font-size: 0.88889rem;
}

.cNew {
    background: #ffce1f;
    font-size: 0.61111rem;
    padding: 0 2px;
    text-transform: uppercase;
    vertical-align: super;
}

.sv-widget table tbody tr th.nlglListHeader {
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-weight: normal;
}

.sv-widget table tbody tr th.nlglListHeader a {
    text-decoration: none;
}

@media only screen and (max-width: 52em) {
    .nlglListEmpty {
        height: 0.55556rem;
    }
}

.sv-widget.sv-widget-news-base table tbody tr td.nlglListText {
    padding-bottom: 1.11111rem;
}

.sv-widget.sv-widget-news-base table tbody tr td.nlglListText div.image {
    padding: 0 0 10px;
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget.sv-widget-news-base table tbody tr td.nlglListText div.image {
        float: left;
        margin-top: 5px;
        padding: 0 10px 0 0;
        width: 200px;
    }
}

body.cke_editable div.image {
    padding: 0 0 10px;
}

@media all {
    body.cke_editable div.image {
        float: left;
        margin-top: 5px;
        padding: 0 10px 0 0;
        width: 200px;
    }
}

.sv-widget-news-short ul {
    display: block;
    margin: 0;
    margin: 0;
    padding: 0;
}

.sv-widget-news-short ul::before,
.sv-widget-news-short ul::after {
    content: ' ';
    display: table;
}

.sv-widget-news-short ul::after {
    clear: both;
}

.sv-widget-news-short ul > li {
    display: block;
    float: left;
    height: auto;
    padding: 0;
}

.sv-widget-news-short ul > li {
    list-style: none;
    padding: 0;
    width: 100%;
}

.sv-widget-news-short ul > li:nth-of-type(1n) {
    clear: none;
}

.sv-widget-news-short ul > li:nth-of-type(1n+1) {
    clear: both;
}

.sv-widget-news-short ul li {
    margin-bottom: 1.33333rem;
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget-news-short ul > li {
        list-style: none;
        padding: 0 0.27778rem 0.55556rem;
        width: 33.33333%;
    }

    .sv-widget-news-short ul > li:nth-of-type(1n) {
        clear: none;
    }

    .sv-widget-news-short ul > li:nth-of-type(3n+1) {
        clear: both;
    }

    .sv-widget-news-short ul li {
        margin-bottom: 1.11111rem;
    }
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget-news-short ul li {
        padding-bottom: 0;
    }

    .sv-widget-news-short ul li > div h2 {
        font-size: 0.83333rem;
    }

    .sv-widget-news-short ul li p {
        font-size: 0.72222rem;
    }

    .sv-widget-news-short ul li p.bottom {
        background: #e6e6e6;
        text-align: right;
    }
}

.sv-widget-news-short ul li > div {
    background: #e6e6e6;
    float: left;
    width: 100%;
}

.sv-widget-news-short ul li > div div.image {
    padding-bottom: 15px;
    text-align: center;
}

.sv-widget-news-short ul li > div h2 {
    padding: 15px;
    text-transform: uppercase;
}

.sv-widget-news-short ul li > div h2 a {
    text-decoration: none;
}

.sv-widget-news-short ul p {
    padding: 0 15px;
}

.sv-widget-news-short ul p.bottom {
    background: #e6e6e6;
    display: block;
    float: left;
    margin: 0;
    padding-bottom: 15px;
    width: 100%;
}

.sv-widget table {
    background: transparent;
    border: solid 0 #bcbcbc;
    margin-bottom: 1.11111rem;
    table-layout: auto;
}

.sv-widget table caption {
    background: transparent;
    color: #272727;
    font-size: 0.88889rem;
    font-weight: bold;
}

.sv-widget table thead {
    background: #ffce1f;
}

.sv-widget table thead tr th,
.sv-widget table thead tr td {
    color: #272727;
    font-size: 0.88889rem;
    font-weight: normal;
    padding: 0.44444rem 0.55556rem 0.55556rem;
}

.sv-widget table tfoot {
    background: #ffce1f;
}

.sv-widget table tfoot tr th,
.sv-widget table tfoot tr td {
    color: #272727;
    font-size: 0.88889rem;
    font-weight: normal;
    padding: 0.44444rem 0.55556rem 0.55556rem;
}

.sv-widget table tr th,
.sv-widget table tr td {
    color: #272727;
    font-size: 0.88889rem;
    padding: 0.5rem 0.55556rem;
    text-align: left;
}

.sv-widget table tr.even,
.sv-widget table tr.alt,
.sv-widget table tr:nth-of-type(even) {
    background: transparent;
}

.sv-widget table thead tr th,
.sv-widget table tfoot tr th,
.sv-widget table tfoot tr td,
.sv-widget table tbody tr th,
.sv-widget table tbody tr td,
.sv-widget table tr td {
    display: table-cell;
    line-height: 1.6;
}

.sv-widget table {
    border-collapse: collapse;
    width: 100%;
}

@media only screen and (max-width: 52em) {
    .sv-widget table {
        width: 100% !important;
    }
}

.sv-widget table td,
.sv-widget table th {
    vertical-align: top;
}

.sv-widget table thead th {
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-weight: normal;
    text-align: left;
}

.sv-widget table tbody tr td,
.sv-widget table tbody tr th {
    border-top: 1px solid #bcbcbc;
    text-align: left;
}

.sv-widget table tbody tr td:first-child,
.sv-widget table tbody tr th:first-child {
    padding-left: 0;
}

.sv-widget table tbody tr td:last-child,
.sv-widget table tbody tr th:last-child {
    padding-right: 0;
}

.sv-widget table tbody tr:last-child td,
.sv-widget table tbody tr:last-child th {
    border-bottom: 1px solid #bcbcbc;
}

.sv-widget table tbody tr th {
    font-family: 'Open Sans', Arial, sans-serif;
    font-weight: normal;
    line-height: 1.6;
    white-space: nowrap;
}

.sv-widget table input[type='text'],
.sv-widget table input[type='password'],
.sv-widget table input[type='date'],
.sv-widget table input[type='datetime'],
.sv-widget table input[type='datetime-local'],
.sv-widget table input[type='month'],
.sv-widget table input[type='week'],
.sv-widget table input[type='email'],
.sv-widget table input[type='number'],
.sv-widget table input[type='search'],
.sv-widget table input[type='tel'],
.sv-widget table input[type='time'],
.sv-widget table input[type='url'],
.sv-widget table textarea {
    margin-bottom: 0;
}

.sv-widget table.no-border,
.sv-widget .sv-widget-news-contentBox-margin > table {
    border: none;
}

.sv-widget table.no-border thead th,
.sv-widget .sv-widget-news-contentBox-margin > table thead th,
.sv-widget table.no-border tr td,
.sv-widget .sv-widget-news-contentBox-margin > table tr td,
.sv-widget table.no-border tr th,
.sv-widget .sv-widget-news-contentBox-margin > table tr th {
    border: none;
}

.sv-widget table.no-border tr td,
.sv-widget .sv-widget-news-contentBox-margin > table tr td,
.sv-widget table.no-border tr th,
.sv-widget .sv-widget-news-contentBox-margin > table tr th {
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
}

.sv-widget table.no-border tr td:first-child,
.sv-widget .sv-widget-news-contentBox-margin > table tr td:first-child,
.sv-widget table.no-border tr th:first-child,
.sv-widget .sv-widget-news-contentBox-margin > table tr th:first-child {
    padding-left: 0;
}

.sv-widget table.no-border tr td:last-child,
.sv-widget .sv-widget-news-contentBox-margin > table tr td:last-child,
.sv-widget table.no-border tr th:last-child,
.sv-widget .sv-widget-news-contentBox-margin > table tr th:last-child {
    padding-right: 0;
}

.sv-widget table.no-border tr:last-child td,
.sv-widget .sv-widget-news-contentBox-margin > table tr:last-child td,
.sv-widget table.no-border tr:last-child th,
.sv-widget .sv-widget-news-contentBox-margin > table tr:last-child th {
    border-bottom: none;
}

.sv-widget table.data tr td,
.sv-widget table.data tr td:last-child,
.sv-widget table.data tr td:first-child,
.sv-widget table.data tr th,
.sv-widget table.data tr th:last-child,
.sv-widget table.data tr th:first-child {
    padding: 0.5rem 0.55556rem;
}

.sv-widget table.data tr:last-child td,
.sv-widget table.data tr:last-child th {
    border-bottom: 1px solid #bcbcbc;
}

.sv-widget table.data thead th {
    border-right: 1px solid #bcbcbc;
    border-top: 1px solid #bcbcbc;
    font-family: 'Open Sans Bold', Arial, sans-serif;
}

.sv-widget table.data thead th:first-child {
    border-left: 1px solid #bcbcbc;
}

.sv-widget table.data tbody tr td,
.sv-widget table.data tbody tr th {
    border-right: 1px solid #bcbcbc;
    border-top: 1px solid #bcbcbc;
}

.sv-widget table.data tbody tr td:first-child,
.sv-widget table.data tbody tr th:first-child {
    border-left: 1px solid #bcbcbc;
}

@media only screen and (max-width: 52em) {
    .sv-widget table.responsive tbody tr td,
    .sv-widget table.responsive tbody tr th {
        padding-left: 0;
        padding-right: 0;
        width: 100% !important;
    }

    .sv-widget table.responsive tbody tr th {
        display: inline;
        font-family: 'Open Sans Bold', Arial, sans-serif;
        font-weight: normal;
        padding-bottom: 0.5rem 0.55556rem/2;
    }

    .sv-widget table.responsive tbody tr th:nth-child(n+2)::before {
        content: ' - ';
    }

    .sv-widget table.responsive tbody tr td {
        border-top: none;
        display: block;
        padding-top: 0;
    }

    .sv-widget table.responsive tbody tr:last-child th {
        border-bottom: none;
    }
}

div.e-label {
    color: white;
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-size: 0.77778rem;
    font-weight: normal;
    line-height: 1;
    padding: 2px 5px 2px 20px;
}

div.e-label.a {
    background-color: #00a651;
}

div.e-label.a::after {
    border-left-color: #00a651;
}

div.e-label.b {
    background-color: #4cb847;
}

div.e-label.b::after {
    border-left-color: #4cb847;
}

div.e-label.c {
    background-color: #bfd630;
}

div.e-label.c::after {
    border-left-color: #bfd630;
}

div.e-label.d {
    background-color: #fff100;
}

div.e-label.d::after {
    border-left-color: #fff100;
}

div.e-label.e {
    background-color: #feb811;
}

div.e-label.e::after {
    border-left-color: #feb811;
}

div.e-label.f {
    background-color: #f36f23;
}

div.e-label.f::after {
    border-left-color: #f36f23;
}

div.e-label.g {
    background-color: #ee1c25;
}

div.e-label.g::after {
    border-left-color: #ee1c25;
}

div.e-label::after {
    border-bottom: 9px solid transparent;
    border-left: 9px solid;
    border-top: 9px solid transparent;
    content: '';
    height: 0;
    position: absolute;
    right: -9px;
    top: 0;
    width: 0;
}

.sv-widget-vehiclelisting-top h2.widget-title {
    margin-bottom: 1.5rem;
}

.sv-widget-vehiclelisting-top ul {
    display: block;
    margin: 0;
    margin: 0;
    padding: 0;
}

.sv-widget-vehiclelisting-top ul::before,
.sv-widget-vehiclelisting-top ul::after {
    content: ' ';
    display: table;
}

.sv-widget-vehiclelisting-top ul::after {
    clear: both;
}

.sv-widget-vehiclelisting-top ul > li {
    display: block;
    float: left;
    height: auto;
    padding: 0;
}

.sv-widget-vehiclelisting-top ul > li {
    list-style: none;
    padding: 0;
    width: 100%;
}

.sv-widget-vehiclelisting-top ul > li:nth-of-type(1n) {
    clear: none;
}

.sv-widget-vehiclelisting-top ul > li:nth-of-type(1n+1) {
    clear: both;
}

.sv-widget-vehiclelisting-top ul li {
    margin-bottom: 1.33333rem;
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget-vehiclelisting-top ul > li {
        list-style: none;
        padding: 0 0.27778rem 0.55556rem;
        width: 33.33333%;
    }

    .sv-widget-vehiclelisting-top ul > li:nth-of-type(1n) {
        clear: none;
    }

    .sv-widget-vehiclelisting-top ul > li:nth-of-type(3n+1) {
        clear: both;
    }

    .sv-widget-vehiclelisting-top ul li {
        margin-bottom: 1.11111rem;
    }
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget-vehiclelisting-top ul li {
        padding-bottom: 0;
    }

    .sv-widget-vehiclelisting-top ul li > div h2 {
        font-size: 0.83333rem;
    }

    .sv-widget-vehiclelisting-top ul li p {
        font-size: 0.72222rem;
    }

    .sv-widget-vehiclelisting-top ul li p.bottom {
        background: #e6e6e6;
        text-align: right;
    }
}

.sv-widget-vehiclelisting-top ul li > div {
    background: #e6e6e6;
    float: left;
    width: 100%;
}

.sv-widget-vehiclelisting-top ul li > div div.image {
    padding-bottom: 15px;
    text-align: center;
}

.sv-widget-vehiclelisting-top ul li > div h2 {
    padding: 15px;
    text-transform: uppercase;
}

.sv-widget-vehiclelisting-top ul li > div h2 a {
    text-decoration: none;
}

.sv-widget-vehiclelisting-top ul p {
    padding: 0 15px;
}

.sv-widget-vehiclelisting-top ul p.bottom {
    background: #e6e6e6;
    display: block;
    float: left;
    margin: 0;
    padding-bottom: 15px;
    width: 100%;
}

.sv-widget-vehiclelisting-top ul .bottom {
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-size: 0.88889rem;
    font-weight: normal;
}

.sv-widget-vehiclelisting .listing select.selected {
    background-color: rgba(255, 206, 31, 0.5);
}

.sv-widget-vehiclelisting .listing .filter div {
    margin-bottom: 0;
}

.sv-widget-vehiclelisting .listing .filter input[type='text'] {
    height: 1.61111rem;
}

.sv-widget-vehiclelisting .listing form .buttons {
    overflow: hidden;
}

.sv-widget-vehiclelisting .listing form .buttons span {
    padding: 0 0.55556rem 0 0;
}

@media only screen and (min-width: 52.063em) {
    .sv-widget-vehiclelisting .listing form .buttons span {
        float: right;
        padding: 0 0 0 0.55556rem;
    }
}

.sv-widget-vehiclelisting .listing form .buttons input {
    appearance: none;
    background-color: #ffce1f;
    border-color: #e5b300;
    border-radius: 0;
    border-style: solid;
    border-width: 0;
    color: #272727;
    cursor: pointer;
    display: inline-block;
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-weight: normal;
    line-height: normal;
    margin: 0 0 1.11111rem;
    min-width: 6.66667rem;
    padding-bottom: 0.27778rem;
    padding-left: 0.44444rem;
    padding-right: 0.44444rem;
    padding-top: 0.22222rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background-color 1s;
    transition: background-color 1s;
    transition: background-color 1s;
    transition: background-color 1s;
}

.sv-widget-vehiclelisting .listing form .buttons input:hover,
.sv-widget-vehiclelisting .listing form .buttons input:focus {
    background-color: #e5b300;
}

.sv-widget-vehiclelisting .listing form .buttons input:hover,
.sv-widget-vehiclelisting .listing form .buttons input:focus {
    color: #272727;
}

.sv-widget-vehiclelisting .listing form .buttons input[type='button'] {
    appearance: none;
    background-color: #ddd;
    border-color: #b1b1b1;
    border-radius: 0;
    border-style: solid;
    border-width: 0;
    color: #333;
    cursor: pointer;
    display: inline-block;
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-weight: normal;
    line-height: normal;
    margin: 0 0 1.11111rem;
    padding-bottom: 0.27778rem;
    padding-left: 0.44444rem;
    padding-right: 0.44444rem;
    padding-top: 0.22222rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background-color 1s;
    transition: background-color 1s;
    transition: background-color 1s;
    transition: background-color 1s;
}

.sv-widget-vehiclelisting .listing form .buttons input[type='button']:hover,
.sv-widget-vehiclelisting .listing form .buttons input[type='button']:focus {
    background-color: #b1b1b1;
}

.sv-widget-vehiclelisting .listing form .buttons input[type='button']:hover,
.sv-widget-vehiclelisting .listing form .buttons input[type='button']:focus {
    color: #272727;
}

@media only screen and (max-width: 52em) {
    .sv-widget-vehiclelisting .listing form .buttons {
        margin-bottom: 1.11111rem;
    }

    .sv-widget-vehiclelisting .listing form .buttons input[type='submit'],
    .sv-widget-vehiclelisting .listing form .buttons input[type='button'] {
        font-size: 1rem;
        min-width: 7.22222rem;
        padding: 0.16667rem 0.33333rem;
    }
}

.sv-widget-vehiclelisting .listing ul.car-overview {
    list-style-type: none;
    margin: 0;
}

.sv-widget-vehiclelisting .listing ul.car-overview li {
    display: block;
    margin-bottom: 2.22222rem;
}

.sv-widget-vehiclelisting .listing ul.car-overview li:first-child {
    display: none;
}

.sv-widget-vehiclelisting .listing ul.car-overview li div:first-child {
    position: relative;
}

.sv-widget-vehiclelisting .listing ul.car-overview li div:first-child div.e-label {
    left: -4px;
    position: absolute;
    top: 35px;
}

.sv-widget-vehiclelisting .listing ul.car-overview li div.desc {
    position: relative;
}

.sv-widget-vehiclelisting .listing ul.car-overview li div.buttons a {
    appearance: none;
    background-color: #ccc;
    border-color: #a3a3a3;
    border-radius: 0;
    border-style: solid;
    border-width: 0;
    color: #333;
    cursor: pointer;
    display: inline-block;
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-weight: normal;
    line-height: normal;
    margin: 0 0 1.11111rem;
    margin: 10px 20px 0 0;
    padding-bottom: 0.27778rem;
    padding-left: 0.44444rem;
    padding-right: 0.44444rem;
    padding-top: 0.22222rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background-color 1s;
    transition: background-color 1s;
    transition: background-color 1s;
    transition: background-color 1s;
}

.sv-widget-vehiclelisting .listing ul.car-overview li div.buttons a:hover,
.sv-widget-vehiclelisting .listing ul.car-overview li div.buttons a:focus {
    background-color: #a3a3a3;
}

.sv-widget-vehiclelisting .listing ul.car-overview li div.buttons a:hover,
.sv-widget-vehiclelisting .listing ul.car-overview li div.buttons a:focus {
    color: #272727;
}

.sv-widget-vehiclelisting .listing ul.car-overview li div.buttons span {
    display: none;
}

@media only screen and (max-width: 52em) {
    .sv-widget-vehiclelisting .listing ul.car-overview li {
        border-bottom: 1px solid #000;
        display: flex;
        flex-direction: column;
        padding-bottom: 2.22222rem;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li div:nth-child(2) {
        order: -1;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li div:nth-child(2) strong {
        font-size: 1.11111rem;
        line-height: 1;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li div.year::before {
        content: 'Inverkehrsetzung: ';
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li div.km::before {
        content: 'KM: ';
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li div.price {
        font-family: 'Open Sans Bold', Arial, sans-serif;
        font-weight: normal;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li div.price::before {
        content: 'Preis: ';
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li img {
        margin: 10px 0;
    }
}

.sv-widget-vehiclelisting .listing ul.car-overview li a {
    text-decoration: none;
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget-vehiclelisting .listing ul.car-overview {
        display: table;
        table-layout: fixed;
        width: 100%;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li {
        display: table-row;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li:first-child {
        background: #454545;
        color: white;
        display: table-row;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li:first-child div {
        padding: 2px 5px;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li:first-child div a {
        background: url(/design/images/icons/arrows/white-up-down.png) no-repeat right;
        color: #fff;
        padding-right: 20px;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li:first-child div a,
    .sv-widget-vehiclelisting .listing ul.car-overview li:first-child div a:visited {
        color: #fff;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li:first-child div a:hover {
        color: #fff;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li:first-child div a.down {
        background-image: url(/design/images/icons/arrows/white-up.png);
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li:first-child div a.up {
        background-image: url(/design/images/icons/arrows/white-down.png);
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li > div {
        border-bottom: 1px solid #454545;
        display: table-cell;
        font-size: 0.77778rem;
        padding: 20px 10px;
        vertical-align: top;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li > div:first-child {
        padding-left: 0;
        width: 220px;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li > div:nth-last-child(-n+3) {
        text-align: right;
        white-space: nowrap;
        width: 12%;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li > div.desc div.buttons {
        bottom: 20px;
        position: absolute;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li > div.desc div.buttons a,
    .sv-widget-vehiclelisting .listing ul.car-overview li > div.desc div.buttons label {
        font-size: 0.66667rem;
        margin: 0;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li > div.desc div.buttons span {
        background: #efefef;
        border: 1px solid #ccc;
        display: inline-block;
        padding: 0 0.66667rem 0.11111rem;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li > div.desc div.buttons input {
        margin: 0 0.44444rem 0 0;
        vertical-align: middle;
    }

    .sv-widget-vehiclelisting .listing ul.car-overview li > div img {
        max-width: none;
        width: 200px;
    }

    .sv-widget-vehiclelisting .listing .wrapper {
        position: relative;
    }

    .sv-widget-vehiclelisting .listing #spinner {
        background: rgba(255, 255, 255, 0.5);
        bottom: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

#vehicle-compare-link {
    display: none;
}

@media only screen and (min-width: 52.063em) {
    #vehicle-compare-link {
        bottom: 200px;
        position: fixed;
        right: -83px;
        transform: rotate(-90deg);
        transform: rotate(-90deg);
        transform: rotate(-90deg);
        transform: rotate(-90deg);
        transform: rotate(-90deg);
    }

    #vehicle-compare-link a {
        appearance: none;
        background-color: #ffce1f;
        border-color: #e5b300;
        border-radius: 0;
        border-style: solid;
        border-width: 0;
        color: #272727;
        cursor: pointer;
        display: inline-block;
        font-family: 'Open Sans Bold', Arial, sans-serif;
        font-weight: normal;
        line-height: normal;
        margin: 0 0 1.11111rem;
        margin: 0;
        padding-bottom: 0.27778rem;
        padding-left: 0.44444rem;
        padding-right: 0.44444rem;
        padding-top: 0.22222rem;
        position: relative;
        text-align: center;
        text-decoration: none;
        transition: background-color 1s;
        transition: background-color 1s;
        transition: background-color 1s;
        transition: background-color 1s;
    }

    #vehicle-compare-link a:hover,
    #vehicle-compare-link a:focus {
        background-color: #e5b300;
    }

    #vehicle-compare-link a:hover,
    #vehicle-compare-link a:focus {
        color: #272727;
    }
}

.sv-widget-vehiclelisting .detail #car-options {
    display: none;
}

.sv-widget-vehiclelisting .detail h2 {
    background: #454545;
    color: #fff;
    margin-bottom: 1.11111rem;
    padding: 5px;
}

.sv-widget-vehiclelisting .detail > div {
    margin-bottom: 2.22222rem;
}

.sv-widget-vehiclelisting .detail .content {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 53.33333rem;
    width: 100%;
}

.sv-widget-vehiclelisting .detail .content::before,
.sv-widget-vehiclelisting .detail .content::after {
    content: ' ';
    display: table;
}

.sv-widget-vehiclelisting .detail .content::after {
    clear: both;
}

.sv-widget-vehiclelisting .detail table.no-border tr td,
.sv-widget-vehiclelisting .detail .sv-widget-news-contentBox-margin > table tr td,
.sv-widget-vehiclelisting .detail table.no-border tr th,
.sv-widget-vehiclelisting .detail .sv-widget-news-contentBox-margin > table tr th {
    padding-bottom: 0;
    padding-top: 0;
}

@media only screen and (min-width: 52.063em) {
    .sv-widget-vehiclelisting .detail table.no-border tr td,
    .sv-widget-vehiclelisting .detail .sv-widget-news-contentBox-margin > table tr td,
    .sv-widget-vehiclelisting .detail table.no-border tr th,
    .sv-widget-vehiclelisting .detail .sv-widget-news-contentBox-margin > table tr th {
        font-size: 0.77778rem;
    }
}

.sv-widget-vehiclelisting .detail table.no-border tr th,
.sv-widget-vehiclelisting .detail .sv-widget-news-contentBox-margin > table tr th {
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-weight: normal;
}

@media only screen and (min-width: 52.063em) {
    .sv-widget-vehiclelisting .detail table.no-border tr th,
    .sv-widget-vehiclelisting .detail .sv-widget-news-contentBox-margin > table tr th {
        padding-right: 20px;
    }
}

.sv-widget-vehiclelisting .detail .gallery ul {
    margin-top: 0.55556rem;
}

@media only screen and (max-width: 52em) {
    .sv-widget-vehiclelisting .detail .gallery a.colorbox {
        pointer-events: none;
    }
}

@media only screen and (min-width: 52.063em) {
    .sv-widget-vehiclelisting .detail .gallery li:nth-child(n+5) {
        display: none;
    }
}

.sv-widget-vehiclelisting .detail .content-1-1.button-bar .buttons a {
    appearance: none;
    background-color: #ffce1f;
    border-color: #e5b300;
    border-radius: 0;
    border-style: solid;
    border-width: 0;
    color: #272727;
    cursor: pointer;
    display: inline-block;
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-size: 0.88889rem;
    font-weight: normal;
    line-height: normal;
    margin: 0 0 1.11111rem;
    margin-bottom: 0;
    padding-bottom: 0.27778rem;
    padding-left: 0.44444rem;
    padding-right: 0.44444rem;
    padding-top: 0.22222rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background-color 1s;
    transition: background-color 1s;
    transition: background-color 1s;
    transition: background-color 1s;
}

.sv-widget-vehiclelisting .detail .content-1-1.button-bar .buttons a:hover,
.sv-widget-vehiclelisting .detail .content-1-1.button-bar .buttons a:focus {
    background-color: #e5b300;
}

.sv-widget-vehiclelisting .detail .content-1-1.button-bar .buttons a:hover,
.sv-widget-vehiclelisting .detail .content-1-1.button-bar .buttons a:focus {
    color: #272727;
}

@media only screen and (min-width: 52.063em), print {
    .sv-widget-vehiclelisting .detail .content-1-1.button-bar {
        margin-bottom: 1.11111rem;
    }

    .sv-widget-vehiclelisting .detail .content-1-1.button-bar .buttons {
        float: right;
        text-align: right;
    }

    .sv-widget-vehiclelisting .detail .properties {
        float: left;
        left: 0%;
        left: auto;
        margin-left: 0% !important;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        right: auto;
        right: 0%;
        width: 41.66667%;
    }

    .sv-widget-vehiclelisting .detail .gallery {
        float: right;
        float: left;
        left: 0%;
        left: auto;
        margin-left: 0% !important;
        padding-left: 0;
        padding-left: 1.11111rem;
        padding-right: 0;
        position: relative;
        right: auto;
        right: 0%;
        width: 58.33333%;
    }
}

.sv-widget-vehiclelisting .detail .contact label.inline {
    display: inline;
    padding-right: 1.38889rem;
}

.sv-widget-vehiclelisting .detail .contact input.placeholder {
    padding-top: 0.22222rem;
}

.svvehiclelisting .detail .contact input:-moz-placeholder {
    padding-top: 0.22222rem;
}

.svvehiclelisting .detail .contact input::-moz-placeholder {
    padding-top: 0.22222rem;
}

.svvehiclelisting .detail .contact input:-ms-input-placeholder {
    padding-top: 0.22222rem;
}

.svvehiclelisting .detail .contact input::-webkit-input-placeholder {
    padding-top: 0.22222rem;
}

.sv-widget-vehiclelisting .detail .contact .send-state p {
    color: green;
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-weight: normal;
}

.sv-widget-vehiclelisting .detail .contact .send-state p.failure {
    color: red;
}

.sv-widget-comparevehicles h1 {
    font-size: 2rem;
}

.sv-widget-comparevehicles .compare-list {
    overflow: hidden;
}

.sv-widget-comparevehicles .compare-list.items-1 {
    min-width: 23.33333rem;
}

.sv-widget-comparevehicles .compare-list.items-2 {
    min-width: 38.33333rem;
}

.sv-widget-comparevehicles .compare-list.items-3 {
    min-width: 53.33333rem;
}

.sv-widget-comparevehicles .compare-list.items-4 {
    min-width: 68.33333rem;
}

.sv-widget-comparevehicles .compare-list.items-5 {
    min-width: 83.33333rem;
}

.sv-widget-comparevehicles .compare-list.items-6 {
    min-width: 98.33333rem;
}

.sv-widget-comparevehicles .compare-list.items-7 {
    min-width: 113.33333rem;
}

.sv-widget-comparevehicles .compare-list.items-8 {
    min-width: 128.33333rem;
}

.sv-widget-comparevehicles .compare-list.items-9 {
    min-width: 143.33333rem;
}

.sv-widget-comparevehicles .compare-list.items-10 {
    min-width: 158.33333rem;
}

.sv-widget-comparevehicles .compare-list > article {
    background: #f0f0f0;
    float: left;
    margin: 0 0.27778rem;
    padding: 0.55556rem;
    position: relative;
    width: 13.88889rem;
}

.sv-widget-comparevehicles .compare-list > article.label {
    width: 8.33333rem;
}

.sv-widget-comparevehicles .compare-list header {
    height: 15rem;
}

.sv-widget-comparevehicles .compare-list header a {
    appearance: none;
    background-color: #ffce1f;
    border-color: #e5b300;
    border-radius: 0;
    border-style: solid;
    border-width: 0;
    color: #272727;
    cursor: pointer;
    display: inline-block;
    font-family: 'Open Sans Bold', Arial, sans-serif;
    font-weight: normal;
    line-height: normal;
    margin: 0 0 1.11111rem;
    padding-bottom: 0.27778rem;
    padding-left: 0.44444rem;
    padding-right: 0.44444rem;
    padding-top: 0.22222rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background-color 1s;
    transition: background-color 1s;
    transition: background-color 1s;
    transition: background-color 1s;
}

.sv-widget-comparevehicles .compare-list header a:hover,
.sv-widget-comparevehicles .compare-list header a:focus {
    background-color: #e5b300;
}

.sv-widget-comparevehicles .compare-list header a:hover,
.sv-widget-comparevehicles .compare-list header a:focus {
    color: #272727;
}

.sv-widget-comparevehicles .compare-list .price {
    margin-bottom: 0.88889rem;
}

.sv-widget-comparevehicles .compare-list .image {
    background-repeat: no-repeat;
    background-size: contain;
    height: 9.44444rem;
    margin-bottom: 0.55556rem;
}

.sv-widget-comparevehicles .compare-list .title {
    height: 2.66667rem;
    padding-right: 0.55556rem;
}

.sv-widget-comparevehicles .compare-list .properties {
    padding-bottom: 0.88889rem;
}

.sv-widget-comparevehicles .compare-list .data .properties {
    padding-top: 0.88889rem;
}

.sv-widget-comparevehicles .compare-list a,
.sv-widget-comparevehicles .compare-list p,
.sv-widget-comparevehicles .compare-list h2,
.sv-widget-comparevehicles .compare-list h3 {
    color: #272727;
    font-size: 0.77778rem;
    margin: 0;
}

.sv-widget-comparevehicles .compare-list ul {
    font-size: 0.77778rem;
    list-style-type: none;
    margin: 0;
}

.sv-widget-comparevehicles .compare-list ul li {
    white-space: nowrap;
}

.sv-widget-comparevehicles .compare-list .remove-article {
    position: absolute;
    right: 0.27778rem;
    top: 0;
}

.sv-widget-comparevehicles .compare-list .remove-article a {
    color: #272727;
    text-decoration: none;
}

.sv-widget-comparevehicles .compare-list .remove-article a::before {
    content: '\2718';
}

.sv-widget-comparevehicles .compare-list .remove-article a:hover {
    color: #ffce1f;
}

@media print {
    .sv-widget-vehiclelisting form,
    .sv-widget-vehiclelisting .buttons,
    .sv-widget-vehiclelisting .e-label {
        display: none !important;
    }

    .sv-widget-vehiclelisting .desc a[href]::after,
    .sv-widget-vehiclelisting .desc abbr[title]::after {
        content: '';
    }

    .sv-widget-vehiclelisting .detail .gallery a[href]::after,
    .sv-widget-vehiclelisting .detail .gallery abbr[title]::after {
        content: '';
    }

    .sv-widget-vehiclelisting .detail .gallery ul {
        display: none;
    }

    .sv-widget-vehiclelisting .detail #car-options {
        display: block;
    }

    .sv-widget-vehiclelisting .detail .contact,
    .sv-widget-vehiclelisting .detail #contact {
        display: none;
    }
}

@media print {
    html,
    body {
        font-size: 16px;
    }

    h2 {
        font-size: 0.88889rem;
    }

    .title-area a[href]::after,
    .title-area abbr[title]::after {
        content: '';
    }

    footer.mobile,
    footer .icons,
    #location-nav,
    #header,
    #header-sub {
        display: none;
    }

    #topbar {
        height: 140px;
        padding-top: 10px;
    }

    #topbar .top-bar,
    #topbar .top-bar .name {
        height: auto;
    }

    #topbar .top-bar-section,
    #topbar .toggle-topbar {
        display: none;
    }

    footer {
        margin-top: 0;
    }

    footer a[href]::after,
    footer abbr[title]::after {
        content: '';
    }

    blockquote {
        border: none;
    }
}
